export const cet4 = [
    { "name": "cancel", "trans": ["取消， 撤销； 删去"] },
    { "name": "explosive", "trans": ["爆炸的； 极易引起争论的", "炸药"] },
    { "name": "numerous", "trans": ["众多的"] },
    { "name": "govern", "trans": ["居支配地位， 占优势", "统治，治理，支配"] },
    { "name": "analyse", "trans": ["分析； 分解； 解析"] },
    { "name": "discourage", "trans": ["使泄气， 使灰心； 阻止， 劝阻"] },
    { "name": "resemble", "trans": ["像， 类似于"] },
    { "name": "remote", "trans": ["遥远的； 偏僻的； 关系疏远的； 脱离的； 微乎其微的； 孤高的， 冷淡的； 遥控的"] },
    { "name": "salary", "trans": ["薪金， 薪水"] },
    { "name": "pollution", "trans": ["污染， 污染物"] },
    { "name": "pretend", "trans": ["装作， 假装"] },
    { "name": "kettle", "trans": ["水壶"] },
    { "name": "wreck", "trans": ["失事；残骸；精神或身体已垮的人", "破坏"] },
    { "name": "drunk", "trans": ["醉的； 陶醉的"] },
    { "name": "calculate", "trans": ["计算； 估计； 计划"] },
    { "name": "persistent", "trans": ["坚持的， 不屈不挠的； 持续不断的； 反复出现的"] },
    { "name": "sake", "trans": ["缘故， 理由"] },
    { "name": "conceal", "trans": ["把…隐藏起来， 掩盖， 隐瞒"] },
    { "name": "audience", "trans": ["听众， 观众， 读者"] },
    { "name": "meanwhile", "trans": ["与此同时"] },
    { "name": "possess", "trans": ["占用， 拥有"] },
    { "name": "competent", "trans": ["有能力的， 能胜任的"] },
    { "name": "investment", "trans": ["投资， 投资额； 投入"] },
    { "name": "neutral", "trans": ["中立的； 中性的"] },
    { "name": "scratch", "trans": ["抓，搔，扒；刮，擦；刻出", "抓痕， 划痕； 抓， 搔， 刮"] },
    { "name": "optional", "trans": ["可以任选的"] },
    { "name": "require", "trans": ["需要； 要求； 命令"] },
    { "name": "circular", "trans": ["圆的；循环的；兜圈子的", "通知， 通告"] },
    { "name": "analysis", "trans": ["分析， 分析报告； 分解； 解析"] },
    { "name": "click", "trans": ["发出咔嗒声；点击", "咔嗒声"] },
    { "name": "fashionable", "trans": ["流行的， 时髦的"] },
    { "name": "devise", "trans": ["设计， 发明"] },
    { "name": "apparent", "trans": ["表面上的， 明显的"] },
    { "name": "journalist", "trans": ["新闻工作者， 新闻记者"] },
    { "name": "exposure", "trans": ["暴露， 揭露； 曝光"] },
    { "name": "temper", "trans": ["韧度；心情，情绪", "调和， 使缓和； 【冶】使回火"] },
    { "name": "protective", "trans": ["保护的， 防护的"] },
    { "name": "sideways", "trans": ["从一边， 向一边； 在一边； 斜着， 侧身"] },
    { "name": "multicultural", "trans": ["融合多种文化的"] },
    { "name": "object", "trans": ["反对， 不赞成", "实物， 物体； 目的， 目标； 对象， 客体； 宾语"] },
    { "name": "humble", "trans": ["谦逊的； 地位低下的； 简陋的"] },
    { "name": "chapter", "trans": ["章， 回， 篇"] },
    { "name": "harbour", "trans": ["海港，港口", "庇护； 心怀"] },
    { "name": "independent", "trans": ["独立的， 自主的； 无偏见的； 不相关联的"] },
    { "name": "carriage", "trans": ["客车厢， 四轮马车"] },
    { "name": "cliff", "trans": ["悬崖， 峭壁"] },
    { "name": "infinite", "trans": ["无限的； 无穷的"] },
    { "name": "concede", "trans": ["承认， 承认…为真； 承认失败； 允许， 让予； 让步， 认输"] },
    { "name": "elect", "trans": ["选举， 推选； 选择"] },
    { "name": "weekly", "trans": ["每周", "每周的"] },
    { "name": "result", "trans": ["发生； 结果", "结果；成果；比分"] },
    { "name": "golf", "trans": ["高尔夫球运动"] },
    { "name": "selfish", "trans": ["自私的， 利己的"] },
    { "name": "sexism", "trans": ["性别偏见， 性别歧视"] },
    { "name": "commission", "trans": ["委任状；委员会；佣金，回扣；授权，委托", "委任， 委托"] },
    { "name": "headline", "trans": ["大字标题； 新闻提要"] },
    { "name": "connect", "trans": ["连接， 结合， 联系； 给…接通电话"] },
    { "name": "rational", "trans": ["理性的， 理智的； 合理的"] },
    { "name": "policy", "trans": ["政策， 方针； 保险单"] },
    { "name": "editorial", "trans": ["编辑的，主笔的，编者的；社论的，社论式的", "社论， 重要评论"] },
    { "name": "resume", "trans": ["重新开始， 继续， 恢复", "摘要， 概要； 简历"] },
    { "name": "rebuild", "trans": ["重建， 改造； 复原"] },
    { "name": "committee", "trans": ["委员会， 全体委员"] },
    { "name": "namely", "trans": ["即， 也就是"] },
    { "name": "artistic", "trans": ["艺术的， 艺术家的； 富有艺术性的， 精美的； 精彩的"] },
    { "name": "union", "trans": ["工会， 联盟； 联合， 团结； 一致"] },
    { "name": "plentiful", "trans": ["丰富的， 充足的； 大量的"] },
    { "name": "halt", "trans": ["停止，立定", "停住"] },
    { "name": "component", "trans": ["组成的， 构成的", "组成部分，部件，组件"] },
    { "name": "consultant", "trans": ["顾问； 会诊医师， 专科医生"] },
    { "name": "sunset", "trans": ["日落； 晚霞"] },
    { "name": "obvious", "trans": ["明显的"] },
    { "name": "illustration", "trans": ["说明， 图解， 例证"] },
    { "name": "disguise", "trans": ["假扮，化装，伪装；掩盖，掩饰", "用来伪装的东西； 伪装， 掩饰"] },
    { "name": "wrap", "trans": ["裹，包，捆", "披肩"] },
    { "name": "surgery", "trans": ["外科， 外科手术； 手术室"] },
    { "name": "liberal", "trans": ["心胸宽大的， 慷慨的； 自由的， 自由主义的"] },
    { "name": "violent", "trans": ["暴力引起的， 暴力的； 猛烈的， 剧烈的"] },
    { "name": "harmony", "trans": ["调和， 协调， 和谐"] },
    { "name": "resolve", "trans": ["解决， 解答； 决定， 决意"] },
    { "name": "chill", "trans": ["变冷，感到冷", "寒冷， 寒气； 风寒， 寒战"] },
    { "name": "confront", "trans": ["迎面遇到， 遭遇； 勇敢地面对， 正视； 使对质， 使当面对证"] },
    { "name": "dominate", "trans": ["拥有优势", "在…中占首要地位；支配，统治，控制；耸立于，俯视"] },
    { "name": "imitate", "trans": ["模仿， 仿效， 仿制"] },
    { "name": "faithful", "trans": ["忠诚的； 如实的； 尽职的"] },
    { "name": "reproduce", "trans": ["复制， 再现； 繁殖， 生殖"] },
    { "name": "institution", "trans": ["协会； 制度， 习俗"] },
    { "name": "pad", "trans": ["垫，衬垫；便笺本；发射台，直升机起落场", "填塞"] },
    { "name": "provocation", "trans": ["激怒， 刺激； 挑衅， 挑拨"] },
    { "name": "ambition", "trans": ["雄心， 抱负； 野心； 期望得到的东西"] },
    { "name": "scholarship", "trans": ["奖学金； 学问， 学识"] },
    { "name": "exceed", "trans": ["超过， 胜过， 超出"] },
    { "name": "besides", "trans": ["而且", "除…之外"] },
    { "name": "preposition", "trans": ["介词"] },
    { "name": "enlarge", "trans": ["扩大， 扩展， 放大"] },
    { "name": "implement", "trans": ["工具， 器具， 用具", "使生效， 履行， 实施"] },
    { "name": "export", "trans": ["输出， 出口", "输出， 出口； 输出品， 出口额"] },
    { "name": "Christ", "trans": ["基督， 救世主"] },
    { "name": "murder", "trans": ["谋杀，凶杀；谋杀罪", "谋杀；杀害"] },
    { "name": "pat", "trans": ["非常恰当的， 适时的", "轻拍"] },
    { "name": "fantasy", "trans": ["想象， 幻想； 想象的产物"] },
    { "name": "horsepower", "trans": ["马力"] },
    { "name": "visibility", "trans": ["可见度， 能见度； 可见性， 明显性"] },
    { "name": "invitation", "trans": ["邀请， 招待； 请柬； 吸引， 诱惑"] },
    { "name": "paw", "trans": ["用爪子抓， 用蹄扒； 翻找", "爪子"] },
    { "name": "enormous", "trans": ["巨大的， 庞大的"] },
    { "name": "moisture", "trans": ["潮湿， 湿气"] },
    { "name": "toast", "trans": ["烤面包；祝酒，祝酒词", "烘， 烤； 向…祝酒， 为…干杯"] },
    { "name": "frustrate", "trans": ["使沮丧， 使灰心； 挫败， 使受挫折"] },
    { "name": "external", "trans": ["外部的， 外面的"] },
    { "name": "aside", "trans": ["在旁边， 到旁边"] },
    { "name": "circumstance", "trans": ["情况， 条件； 境遇， 境况； 经济情况"] },
    { "name": "revise", "trans": ["修订， 修改； 复习"] },
    { "name": "authority", "trans": ["官方； 权力； 当权者， 行政管理机构； 权威， 专家"] },
    { "name": "creature", "trans": ["创造物， 产物； 生物， 动物， 家畜"] },
    { "name": "harm", "trans": ["伤害， 损害"] },
    { "name": "semiconductor", "trans": ["半导体"] },
    { "name": "rope", "trans": ["绳，索", "用绳捆"] },
    { "name": "provided", "trans": ["假如， 若是"] },
    { "name": "instrument", "trans": ["仪器， 工具； 乐器"] },
    { "name": "gasoline", "trans": ["汽油"] },
    { "name": "indispensable", "trans": ["必不可少的， 必需的"] },
    { "name": "medium", "trans": ["中等的", "媒体；媒介物，传导体"] },
    { "name": "lens", "trans": ["透镜， 镜片， 镜头"] },
    { "name": "wisdom", "trans": ["智慧， 才智； 名言"] },
    { "name": "fatal", "trans": ["致命的， 命运的； 重大的， 决定性的"] },
    { "name": "nowhere", "trans": ["任何地方都不"] },
    { "name": "motive", "trans": ["动机， 目的"] },
    { "name": "romantic", "trans": ["浪漫的； 多情的； 有浪漫色彩的， 传奇性的； 不切实际的， 空想的"] },
    { "name": "motivate", "trans": ["作为…的动机； 激励， 激发"] },
    { "name": "spoil", "trans": ["损坏，糟蹋；宠坏，溺爱；变质", "战利品， 掠夺物"] },
    { "name": "airline", "trans": ["航空公司； 航线"] },
    { "name": "multiply", "trans": ["增加， 繁殖； 乘"] },
    { "name": "ridge", "trans": ["脊， 山脊； 垄， 埂， 脊状突起"] },
    { "name": "pilot", "trans": ["试验性的， 试点的", "引航员；飞行员", "驾驶；为引航；试验，试用"] },
    { "name": "umbrella", "trans": ["伞， 雨伞"] },
    { "name": "approval", "trans": ["赞成， 同意， 批准"] },
    { "name": "mobile", "trans": ["运动的； 流动的； 多变的"] },
    { "name": "perform", "trans": ["做， 履行， 完成； 表演， 演出； 工作情况， 表现"] },
    { "name": "multiple", "trans": ["复合的，多重的，多样的", "倍数"] },
    { "name": "peak", "trans": ["达到高峰， 达到最大值", "最大值的，高峰的", "山顶，顶点"] },
    { "name": "evolution", "trans": ["进化， 演化， 发展"] },
    { "name": "portrait", "trans": ["肖像， 画像"] },
    { "name": "halfway", "trans": ["半路地； 在中途， 在半途", "中途的；部分的；不彻底的"] },
    { "name": "expose", "trans": ["使接触； 揭露"] },
    { "name": "concentrate", "trans": ["全神贯注，全力以赴；集中，聚集；浓缩", "浓缩物， 浓缩液"] },
    { "name": "magnet", "trans": ["磁铁， 磁体； 有吸引力的人或事物"] },
    { "name": "explosion", "trans": ["爆炸， 爆发， 炸裂； 激增， 扩大"] },
    { "name": "weld", "trans": ["焊接， 熔接"] },
    { "name": "up-to-date", "trans": ["直到最近的， 现代的； 跟上时代的"] },
    { "name": "complaint", "trans": ["抱怨， 怨言； 控告"] },
    { "name": "translation", "trans": ["翻译； 译文， 译本"] },
    { "name": "cancer", "trans": ["癌， 癌症； 肿瘤"] },
    { "name": "personnel", "trans": ["人员， 员工"] },
    { "name": "hopeless", "trans": ["没有希望的， 绝望的"] },
    { "name": "outlook", "trans": ["观点， 看法； 展望， 前景"] },
    { "name": "fountain", "trans": ["泉水； 喷泉； 源泉"] },
    { "name": "offense", "trans": ["犯规， 违法行为； 冒犯， 得罪"] },
    { "name": "breadth", "trans": ["宽度； 幅度， 幅面"] },
    { "name": "catalog", "trans": ["目录，目录册；一系列", "将…编入目录， 将编目"] },
    { "name": "channel", "trans": ["海峡， 水道， 航道； 渠道， 途径； 频道"] },
    { "name": "focus", "trans": ["聚焦；集中", "焦点； 中心"] },
    { "name": "invisible", "trans": ["看不见的， 无形的"] },
    { "name": "entire", "trans": ["全部的， 整个的"] },
    { "name": "pea", "trans": ["豌豆"] },
    { "name": "pill", "trans": ["药丸"] },
    { "name": "wrist", "trans": ["腕， 腕关节"] },
    { "name": "approach", "trans": ["向…靠近", "靠近"] },
    { "name": "myth", "trans": ["杜撰出来的人； 神话"] },
    { "name": "flour", "trans": ["面粉； 粉， 粉状物质"] },
    { "name": "implication", "trans": ["含意， 暗示， 暗指； 卷入， 牵连"] },
    { "name": "camel", "trans": ["骆驼"] },
    { "name": "fierce", "trans": ["凶猛的； 狂热的"] },
    { "name": "bump", "trans": ["碰，撞；颠簸着前进", "碰撞， 猛撞； 肿块； 隆起物"] },
    { "name": "reservation", "trans": ["预订； 保留； 犹豫； 保留地"] },
    { "name": "per", "trans": ["每， 每一"] },
    { "name": "proceed", "trans": ["继续进行； 行进， 前进"] },
    { "name": "considering", "trans": ["鉴于， 考虑到， 顾及"] },
    { "name": "corporation", "trans": ["公司， 企业， 社团"] },
    { "name": "loyalty", "trans": ["忠诚， 忠心"] },
    { "name": "bulb", "trans": ["电灯泡， 球状物"] },
    { "name": "dismiss", "trans": ["不再考虑； 解雇， 解散； 驳回"] },
    { "name": "propose", "trans": ["提议， 建议， 提出； 提名， 推荐； 打算， 计划； 求婚"] },
    { "name": "intelligence", "trans": ["智力； 理解力； 情报"] },
    { "name": "reform", "trans": ["改革， 改良， 改造； 改正， 改过自新"] },
    { "name": "draught", "trans": ["穿堂风，冷风", "〔啤酒〕桶装的，散装的"] },
    { "name": "daylight", "trans": ["白昼， 日光， 黎明"] },
    { "name": "install", "trans": ["安装， 设置； 使就职， 任命"] },
    { "name": "integration", "trans": ["综合"] },
    { "name": "indication", "trans": ["指示， 表示， 表明； 象征， 迹象"] },
    { "name": "bulk", "trans": ["变得越来越大； 使更大", "物体；体积；大批"] },
    { "name": "coach", "trans": ["长途公共汽车；教练，指导；旅客车厢", "训练， 指导， 辅导"] },
    { "name": "despair", "trans": ["绝望", "绝望，感到无望"] },
    { "name": "champion", "trans": ["冠军， 得胜者； 捍卫者， 拥护者"] },
    { "name": "gum", "trans": ["口香糖； 树胶"] },
    { "name": "circuit", "trans": ["电路， 线路； 环行， 巡行"] },
    { "name": "pine", "trans": ["消瘦， 衰弱； 痛苦， 悲哀； 渴望", "松树，松木"] },
    { "name": "magnificent", "trans": ["宏伟的， 壮丽的； 华丽的； 极好的"] },
    { "name": "guy", "trans": ["家伙， 伙计"] },
    { "name": "respectively", "trans": ["各自地， 各个地， 分别地"] },
    { "name": "protection", "trans": ["保护， 防护"] },
    { "name": "pint", "trans": ["品脱"] },
    { "name": "restrict", "trans": ["限制， 约束， 限定"] },
    { "name": "instant", "trans": ["立即的； 紧急的； 可以速食的", "瞬间"] },
    { "name": "conjunction", "trans": ["接合， 连接， 联合； 连词"] },
    { "name": "peer", "trans": ["仔细看， 费力地看", "同龄人，同等地位的人；贵族"] },
    { "name": "orderly", "trans": ["整洁的； 有秩序的"] },
    { "name": "costly", "trans": ["昂贵的， 价值高的"] },
    { "name": "roast", "trans": ["烤，炙，烘", "烤过的， 烘过的", "烤肉"] },
    { "name": "violence", "trans": ["猛烈， 激烈； 暴力"] },
    { "name": "succession", "trans": ["连续； 一连串； 接替， 继任， 继承"] },
    { "name": "accordingly", "trans": ["因此， 所以； 照着"] },
    { "name": "consequence", "trans": ["结果， 后果； 重要， 重大"] },
    { "name": "product", "trans": ["产品， 产物； 乘积"] },
    { "name": "particularly", "trans": ["特别， 尤其"] },
    { "name": "dominant", "trans": ["占优势的， 支配的， 统治的； 居高临下的， 高耸的"] },
    { "name": "heading", "trans": ["标题， 题词， 题名； 新闻提要"] },
    { "name": "lover", "trans": ["爱好者； 情人"] },
    { "name": "vinegar", "trans": ["醋"] },
    { "name": "intellectual", "trans": ["智力的", "知识分子"] },
    { "name": "cheat", "trans": ["欺骗；作弊；哄，行骗", "欺骗， 欺骗行为； 骗子"] },
    { "name": "framework", "trans": ["框架， 结构； 准则； 体系"] },
    { "name": "undoubtedly", "trans": ["毋庸置疑地， 肯定地"] },
    { "name": "infect", "trans": ["传染， 感染； 影响"] },
    { "name": "tide", "trans": ["潮， 潮汐； 潮流， 趋势"] },
    { "name": "prohibit", "trans": ["禁止， 不准"] },
    { "name": "bacteria", "trans": ["细菌"] },
    { "name": "minor", "trans": ["辅修", "较小的，较少的；次要的", "未成年人；辅修科目"] },
    { "name": "arrow", "trans": ["箭， 箭状物； 箭头符号"] },
    { "name": "cop", "trans": ["警察"] },
    { "name": "fuel", "trans": ["加燃料； 加油", "燃料，燃料剂", "给…加燃料；刺激"] },
    { "name": "hostile", "trans": ["敌方的； 不友善的"] },
    { "name": "aeroplane", "trans": ["飞机"] },
    { "name": "manner", "trans": ["方式； 态度； 风度； 礼貌， 规矩"] },
    { "name": "employee", "trans": ["受雇者， 雇员， 雇工"] },
    { "name": "associate", "trans": ["结合； 交往", "副的", "把…联系在一起；使联合", "伙伴，同事"] },
    { "name": "pray", "trans": ["祈祷， 祈求； 请求， 恳求"] },
    { "name": "lad", "trans": ["男孩， 小伙子"] },
    { "name": "crawl", "trans": ["爬， 爬行； 缓慢地行进"] },
    { "name": "identical", "trans": ["完全相同的， 同一的"] },
    { "name": "lag", "trans": ["走得慢", "落后"] },
    { "name": "towel", "trans": ["毛巾， 手巾"] },
    { "name": "charity", "trans": ["救济金， 施舍物； 慈善团体， 慈善事业； 宽大， 宽容"] },
    { "name": "academic", "trans": ["学院的； 学术的； 纯理论的， 不切实际的"] },
    { "name": "function", "trans": ["运作， 运行", "功能；职务，职责"] },
    { "name": "employer", "trans": ["雇佣者， 雇主"] },
    { "name": "pit", "trans": ["地洞，坑；煤矿，矿井", "使有坑"] },
    { "name": "lap", "trans": ["舔； 拍打", "膝部；一圈，一段"] },
    { "name": "mineral", "trans": ["矿物， 矿石， 矿物质"] },
    { "name": "stock", "trans": ["常用的， 常备的", "原料，库存品；股本，公债；世系，血统；汤汁；家畜，牲畜", "储备"] },
    { "name": "representative", "trans": ["有代表性的， 典型的", "代表，代理人"] },
    { "name": "suspend", "trans": ["暂停， 终止； 吊， 悬"] },
    { "name": "pigeon", "trans": ["鸽子"] },
    { "name": "well-known", "trans": ["众所周知的， 著名的"] },
    { "name": "beneath", "trans": ["在下方， 在底下", "在…下方；低于，次于；在…掩盖下；连…也不值得，有失…的身份"] },
    { "name": "terror", "trans": ["恐怖， 惊骇； 引起恐怖的人或事"] },
    { "name": "comparison", "trans": ["比较， 对照； 比拟， 比喻"] },
    { "name": "surge", "trans": ["蜂拥而出；洋溢，奔放；汹涌，奔腾", "大浪， 波涛； 高涨； 汹涌"] },
    { "name": "lest", "trans": ["唯恐， 以免"] },
    { "name": "heroic", "trans": ["英雄的； 英勇的"] },
    { "name": "sway", "trans": ["摇，摇动；动摇", "摇摆， 摇动； 起支配作用的影响"] },
    { "name": "basis", "trans": ["基础， 根据； 原则"] },
    { "name": "tutor", "trans": ["当…导师， 当…家庭教师", "导师；家庭教师，私人教师"] },
    { "name": "senator", "trans": ["参议员"] },
    { "name": "adventure", "trans": ["奇遇； 冒险， 冒险活动"] },
    { "name": "successive", "trans": ["连续的， 接连的"] },
    { "name": "condition", "trans": ["状况， 状态； 环境"] },
    { "name": "thirsty", "trans": ["渴的； 渴望的"] },
    { "name": "ceremony", "trans": ["典礼， 仪式， 礼节"] },
    { "name": "obligation", "trans": ["义务， 责任"] },
    { "name": "improve", "trans": ["变得更好； 改善； 提高"] },
    { "name": "gym", "trans": ["体育馆； 健身房"] },
    { "name": "rarely", "trans": ["很少， 难得"] },
    { "name": "evil", "trans": ["坏的", "邪恶，祸害"] },
    { "name": "governor", "trans": ["州长； 主管人员； 理事， 董事"] },
    {
      "name": "twist",
      "trans": ["缠绕，盘绕；转动，旋动；捻，搓；歪曲，曲解；扭歪，扭伤；曲折前进；转身", "扭弯； 转折， 转变； 弯曲， 曲折处"]
    },
    { "name": "locate", "trans": ["探明， 找出； 把…设置在， 使…坐落于"] },
    { "name": "unite", "trans": ["联合， 统一"] },
    { "name": "lavatory", "trans": ["盥洗室， 厕所"] },
    { "name": "exterior", "trans": ["外部的；对外的", "外部， 外表"] },
    { "name": "technique", "trans": ["技术， 技巧， 技能"] },
    { "name": "combat", "trans": ["战争，斗争，格斗", "与…斗争， 与…战斗"] },
    { "name": "troop", "trans": ["成群结队而行", "军队，部队；一群，大量"] },
    { "name": "scenery", "trans": ["风景， 景色； 舞台布景"] },
    { "name": "unity", "trans": ["单一， 统一， 团结； 和睦， 协调"] },
    { "name": "effective", "trans": ["有效的； 有影响的"] },
    { "name": "pursue", "trans": ["继续； 从事； 追赶， 追踪； 追求"] },
    { "name": "similarly", "trans": ["类似地， 相似地"] },
    { "name": "convey", "trans": ["表达， 传递； 运送， 输送"] },
    { "name": "muscle", "trans": ["肌肉， 体力； 力量， 实力"] },
    { "name": "design", "trans": ["设计；预定，指定", "设计， 构想； 图样； 企图"] },
    { "name": "extra", "trans": ["特别地", "额外的；特别的", "额外的事物， 额外费用"] },
    { "name": "generous", "trans": ["慷慨的； 宽厚的； 大量的"] },
    { "name": "victim", "trans": ["牺牲者， 受害者"] },
    { "name": "possibility", "trans": ["可能； 可能的事"] },
    { "name": "lane", "trans": ["小路； 跑道； 航道， 航线"] },
    { "name": "garlic", "trans": ["大蒜"] },
    { "name": "chief", "trans": ["主要的，为首的；总的", "首领， 长官； 酋长， 族长"] },
    { "name": "accord", "trans": ["相符合； 相一致", "一致，符合；谅解，协议", "使符合；使一致；调节；授予，赠与，给予"] },
    { "name": "aircraft", "trans": ["飞机， 飞行器"] },
    { "name": "automatic", "trans": ["自动的，机械的；不假思索的，无意识的；必然的，自然的", "自动手枪； 有自动装置的汽车"] },
    { "name": "doubtful", "trans": ["难以预测的； 怀疑的"] },
    { "name": "despite", "trans": ["不管， 不顾"] },
    { "name": "opening", "trans": ["开首的，开始的", "口子， 洞， 孔； 开始； 空缺"] },
    { "name": "industrial", "trans": ["工业的， 产业的"] },
    { "name": "obey", "trans": ["顺从， 服从"] },
    { "name": "conduct", "trans": ["举止， 行为； 指导； 管理； 实施", "进行； 管理， 指挥， 引导； 传输， 传导"] },
    { "name": "stable", "trans": ["稳定的，不变的；沉稳的，持重的", "厩， 马厩， 牛棚"] },
    { "name": "lamb", "trans": ["羔羊， 小羊； 羔羊肉"] },
    { "name": "pillow", "trans": ["枕头"] },
    { "name": "harness", "trans": ["治理；给上挽具", "马具， 挽具"] },
    { "name": "fantastic", "trans": ["极好的， 极出色的， 了不起的； 极大的； 难以相信的； 异想天开的， 不实际的； 奇异的， 古怪的"] },
    { "name": "sketch", "trans": ["绘…的略图， 画…的素描； 概述", "略图；梗概；素描，速写"] },
    { "name": "tidy", "trans": ["整洁的，整齐的", "整洁， 整齐"] },
    { "name": "respond", "trans": ["回答， 答复； 作出反应， 响应"] },
    { "name": "incident", "trans": ["发生的事， 事件"] },
    { "name": "maintenance", "trans": ["维持； 保养； 抚养费"] },
    { "name": "marry", "trans": ["娶， 嫁； 为…证婚； 结婚， 结合"] },
    { "name": "decay", "trans": ["腐烂；衰败", "腐烂"] },
    { "name": "absorb", "trans": ["吸收； 吸引…的注意， 使全神贯注； 把…并入， 同化"] },
    { "name": "supply", "trans": ["供给， 供应"] },
    { "name": "concern", "trans": ["关切的事，有关的事；关心，挂念；关系，关联；公司，企业", "涉及， 有关； 使关心， 使担心"] },
    { "name": "circulate", "trans": ["循环， 流通； 流传， 散布， 传播"] },
    { "name": "liberty", "trans": ["自由； 许可， 准许； 过于随便， 放肆"] },
    { "name": "liable", "trans": ["易于…的； 可能的"] },
    { "name": "cargo", "trans": ["船货， 货物"] },
    { "name": "confidential", "trans": ["秘密的， 机密的； 亲信的"] },
    { "name": "drift", "trans": ["漂流，漂泊", "漂流； 大意， 主旨； 趋势"] },
    { "name": "element", "trans": ["成分， 要素， 元素； 基础， 纲要； 自然力"] },
    { "name": "chaos", "trans": ["混乱， 紊乱"] },
    { "name": "mankind", "trans": ["人类"] },
    { "name": "abundant", "trans": ["丰富的， 富裕的； 大量的， 充足的"] },
    { "name": "pace", "trans": ["踱步", "步，步速；速度；节奏"] },
    { "name": "exclaim", "trans": ["呼喊， 惊叫"] },
    { "name": "probable", "trans": ["很可能的， 大概的"] },
    { "name": "forecast", "trans": ["预测， 预报", "预测，预报"] },
    { "name": "uncover", "trans": ["揭露， 暴露； 揭开…的盖子"] },
    { "name": "recognize", "trans": ["认出， 识别； 承认； 赏识， 表彰， 报偿"] },
    { "name": "pack", "trans": ["捆扎，把…打包；使挤在一起，塞满", "包， 小盒"] },
    { "name": "restraint", "trans": ["抑制， 限制， 克制； 约束措施， 约束条件"] },
    { "name": "input", "trans": ["输入；投入的资金；输入的数据", "把…输入计算机"] },
    { "name": "cue", "trans": ["暗示，信号；提示", "提示； 暗示"] },
    { "name": "volt", "trans": ["伏特， 伏"] },
    { "name": "reality", "trans": ["现实， 实际； 真实"] },
    { "name": "stereotype", "trans": ["陈规，老套，固定的模式；铅版", "把…浇铸成铅版； 对…形成固定看法"] },
    { "name": "enroll", "trans": ["入学，加入", "招收， 吸收"] },
    { "name": "offend", "trans": ["冒犯， 伤害…的感情； 使厌恶； 违反"] },
    { "name": "molecule", "trans": ["分子"] },
    { "name": "resign", "trans": ["辞职", "辞去， 放弃； 使听从， 使顺从"] },
    { "name": "bathe", "trans": ["洗澡； 游泳"] },
    { "name": "workman", "trans": ["工人， 劳动者， 工匠"] },
    { "name": "sophisticated", "trans": ["老于世故的； 高级的； 精密的， 复杂的； 高雅的"] },
    { "name": "sunrise", "trans": ["日出； 朝霞"] },
    { "name": "entertainment", "trans": ["娱乐， 文娱节目， 表演会； 招待， 款待， 请客"] },
    { "name": "document", "trans": ["公文，文件；证件", "用文件等证明"] },
    { "name": "starve", "trans": ["挨饿， 饿死"] },
    { "name": "battery", "trans": ["电池， 蓄电池； 排炮， 炮组； 一系列， 一套"] },
    { "name": "chase", "trans": ["追逐， 追赶； 追求"] },
    { "name": "accident", "trans": ["意外， 事故"] },
    { "name": "marine", "trans": ["海的， 海生的； 海事的， 海军的， 海运的", "海军陆战队士兵"] },
    { "name": "clay", "trans": ["黏土， 泥土； 肉体"] },
    { "name": "anyway", "trans": ["无论如何， 至少； 不论以何种方式， 无论从什么角度"] },
    { "name": "promote", "trans": ["促进， 增进， 发扬； 提升； 宣传， 推销"] },
    { "name": "routine", "trans": ["例行的， 常规的", "例行公事，惯例，惯常的程序"] },
    { "name": "attack", "trans": ["攻击，打击；突然发作", "攻击， 进攻"] },
    { "name": "humour", "trans": ["幽默， 诙谐， 幽默感"] },
    { "name": "divorce", "trans": ["离婚； 分离， 脱离", "离婚，离异"] },
    { "name": "currency", "trans": ["通货， 货币； 通行， 流行"] },
    { "name": "canal", "trans": ["运河， 沟渠； 管"] },
    { "name": "attach", "trans": ["缚， 系， 贴； 附加； 使依恋， 使喜爱； 使附属； 认为有"] },
    { "name": "distribute", "trans": ["分发， 分送； 分布"] },
    { "name": "ankle", "trans": ["踝， 踝关节"] },
    { "name": "convict", "trans": ["证明…有罪， 宣判…有罪", "囚犯"] },
    { "name": "legislation", "trans": ["法律， 法规； 立法， 法律的制定"] },
    { "name": "clause", "trans": ["条款； 从句， 分句"] },
    { "name": "quiz", "trans": ["智力竞赛，问答比赛；小测验", "考查， 盘问"] },
    { "name": "participate", "trans": ["参与， 参加； 分享"] },
    { "name": "ingredient", "trans": ["组成部分， 成分； 原料； 要素， 因素"] },
    { "name": "surplus", "trans": ["过剩的， 多余的", "过剩，剩余，盈余"] },
    { "name": "correspondence", "trans": ["信件， 函件； 通信， 通信联系； 符合， 一致， 相似"] },
    { "name": "refresh", "trans": ["振作精神， 恢复活力"] },
    { "name": "librarian", "trans": ["图书馆管理员"] },
    { "name": "intimate", "trans": ["亲密的；个人的", "至交， 密友", "暗示， 提示"] },
    { "name": "reporter", "trans": ["记者"] },
    { "name": "declaration", "trans": ["宣布， 宣告； 宣言， 声明； 申报"] },
    { "name": "disclose", "trans": ["揭示； 透露"] },
    { "name": "religion", "trans": ["宗教， 宗教信仰"] },
    { "name": "diagram", "trans": ["图解， 图表， 简图"] },
    { "name": "claw", "trans": ["抓， 撕", "爪，脚爪，螯"] },
    { "name": "torture", "trans": ["拷问；折磨", "拷打"] },
    { "name": "prayer", "trans": ["祷告， 祈祷； 祷文； 祈求， 祈望"] },
    { "name": "tragedy", "trans": ["悲剧， 惨事， 惨案； 悲剧艺术"] },
    { "name": "charm", "trans": ["迷人的特性，魅力；符咒，咒文", "吸引， 迷住"] },
    { "name": "clap", "trans": ["拍手，轻拍", "拍手， 鼓掌"] },
    { "name": "purple", "trans": ["紫的", "紫色"] },
    { "name": "quit", "trans": ["停止， 放弃； 离开， 辞"] },
    { "name": "economical", "trans": ["节约的； 经济学的"] },
    { "name": "prosperous", "trans": ["繁荣的， 兴旺的"] },
    { "name": "polish", "trans": ["磨光；修改，润色，使优美", "擦光剂"] },
    { "name": "cabinet", "trans": ["橱， 柜； 内阁"] },
    { "name": "chart", "trans": ["图表；航海图", "用图表表示， 在图上表示"] },
    { "name": "optical", "trans": ["眼的， 光学的， 视觉的"] },
    { "name": "fashion", "trans": ["样子， 方式； 时尚； 流行款式"] },
    { "name": "insurance", "trans": ["保险， 保险费"] },
    { "name": "preface", "trans": ["序言， 前言， 引言"] },
    { "name": "principle", "trans": ["原则， 原理， 道义； 基本信念， 信条"] },
    { "name": "lid", "trans": ["盖子， 盖"] },
    { "name": "tedious", "trans": ["冗长乏味的， 沉闷的"] },
    { "name": "source", "trans": ["河的源头， 根源； 来源， 出处"] },
    { "name": "secure", "trans": ["安全的，可靠的；牢固的，稳固的；无忧虑的，安心的", "得到， 获得； 使安全， 保卫； 缚牢"] },
    { "name": "domestic", "trans": ["本国的； 家庭的； 驯养的"] },
    { "name": "deception", "trans": ["欺骗； 诡计， 骗局"] },
    { "name": "basically", "trans": ["基本上， 从根本上说"] },
    { "name": "donation", "trans": ["捐款， 捐赠物； 捐赠， 赠送"] },
    { "name": "avenue", "trans": ["林荫道， 道路， 大街"] },
    { "name": "immense", "trans": ["巨大的； 极好的"] },
    { "name": "budget", "trans": ["规划，安排；编预算", "低廉的， 收费公道的", "预算，预算拨款"] },
    { "name": "given", "trans": ["规定的，特定的；假设的；已知的；有癖好的，有倾向的", "考虑到"] },
    { "name": "hay", "trans": ["干草"] },
    { "name": "chest", "trans": ["胸腔， 胸膛； 箱子"] },
    { "name": "tension", "trans": ["紧张， 不安； 拉紧； 张力， 拉力"] },
    { "name": "definite", "trans": ["明确的， 肯定的"] },
    { "name": "reflection", "trans": ["映像， 倒影； 反射； 反映， 表达； 非议； 沉思， 反省"] },
    { "name": "ultimate", "trans": ["最后的，最终的", "终极， 顶点"] },
    { "name": "campus", "trans": ["校园， 学校场地"] },
    { "name": "adapt", "trans": ["使适应； 改编"] },
    { "name": "solicitor", "trans": ["律师， 法律顾问"] },
    { "name": "cheerful", "trans": ["快乐的， 愉快的； 使人感到愉快的"] },
    { "name": "merit", "trans": ["长处，优点，价值；功劳，成绩", "值得， 应受"] },
    { "name": "misunderstand", "trans": ["误解， 误会"] },
    { "name": "guilty", "trans": ["内疚的； 有罪的"] },
    { "name": "measure", "trans": ["测量，度量；衡量", "分量； 尺寸； 量具； 计量单位； 量度， 测量； 措施， 办法"] },
    { "name": "overall", "trans": ["全面的， 总体的， 全部的"] },
    { "name": "receipt", "trans": ["发票， 收据； 收入， 进款； 收到， 接到"] },
    { "name": "assume", "trans": ["假定， 假设， 臆断； 承担， 担任； 呈现"] },
    { "name": "pepper", "trans": ["胡椒，胡椒粉", "在…上撒； 使布满"] },
    { "name": "plural", "trans": ["复数的", "复数"] },
    { "name": "doctoral", "trans": ["博士的"] },
    { "name": "concept", "trans": ["概念， 观念， 设想"] },
    { "name": "resistant", "trans": ["抵抗的， 抗…的， 耐…的"] },
    { "name": "effort", "trans": ["努力， 努力的成果"] },
    { "name": "video", "trans": ["录像的；电视的", "录像"] },
    { "name": "disaster", "trans": ["灾难， 灾祸， 天灾； 彻底的失败"] },
    { "name": "agriculture", "trans": ["农业， 农学"] },
    { "name": "weapon", "trans": ["武器， 兵器"] },
    { "name": "grocer", "trans": ["食品商， 杂货商"] },
    { "name": "stare", "trans": ["盯， 凝视"] },
    { "name": "painful", "trans": ["疼痛的， 引起疼痛的； 困难的； 令人不快的"] },
    { "name": "vote", "trans": ["投票； 表决", "票，选票；选举，投票，表决；表决结果，投票总数"] },
    { "name": "steady", "trans": ["稳定", "稳的，平稳的；稳定的，持续的；稳固的"] },
    { "name": "enhance", "trans": ["提高， 增加， 加强"] },
    { "name": "enclose", "trans": ["围住， 圈起； 附上； 把…装入信封"] },
    { "name": "convention", "trans": ["习俗， 惯例； 公约； 会议"] },
    { "name": "disease", "trans": ["病， 疾病； 不健全； 弊端"] },
    { "name": "center", "trans": ["圆心， 正中； 中心"] },
    { "name": "suspicion", "trans": ["怀疑， 疑心， 猜疑； 一点儿， 少量"] },
    { "name": "purchase", "trans": ["买，购买", "购买； 购买的物品"] },
    { "name": "refusal", "trans": ["拒绝"] },
    { "name": "technician", "trans": ["技术员， 技师"] },
    { "name": "generic", "trans": ["【生物】 属的， 类的； 一般的， 普通的"] },
    { "name": "genius", "trans": ["天才， 天赋， 天资； 天才人物"] },
    { "name": "alike", "trans": ["一样地， 相似地； 同样程度地", "同样的，相同的"] },
    { "name": "fund", "trans": ["资金，基金；存款", "为…提供资金"] },
    { "name": "gravity", "trans": ["重力， 引力； 严重性； 严肃， 庄重"] },
    { "name": "typical", "trans": ["典型的， 代表性的"] },
    { "name": "quotation", "trans": ["引文， 引语， 语录； 报价， 牌价， 行情"] },
    { "name": "finance", "trans": ["筹措资金", "财政，金融；财源，财务情况"] },
    { "name": "studio", "trans": ["画室， 摄影室； 播音室， 录音室， 摄影棚"] },
    { "name": "tray", "trans": ["盘， 托盘， 碟"] },
    { "name": "downward", "trans": ["向下地", "向下的"] },
    { "name": "cabbage", "trans": ["洋白菜， 卷心菜"] },
    { "name": "regulate", "trans": ["管理， 控制； 调整， 调节"] },
    { "name": "palm", "trans": ["手掌， 掌状物； 棕榈树"] },
    { "name": "hydrogen", "trans": ["氢"] },
    { "name": "infant", "trans": ["婴儿的； 幼稚的", "婴儿"] },
    { "name": "hen", "trans": ["母鸡， 雌禽"] },
    { "name": "whoever", "trans": ["谁； 无论谁； 究竟是谁"] },
    { "name": "carbon", "trans": ["碳"] },
    { "name": "unload", "trans": ["卸， 卸货； 下客； 退出子弹； 卸下胶卷"] },
    { "name": "payment", "trans": ["支付， 支付的款项"] },
    { "name": "specifically", "trans": ["特别地， 特定地； 明确地， 具体地"] },
    { "name": "controversial", "trans": ["引起争论的， 有争议的"] },
    { "name": "attain", "trans": ["达到， 获得， 完成"] },
    { "name": "trap", "trans": ["陷阱，捕捉器；圈套，诡计；困境", "设陷阱捕捉； 诱骗"] },
    { "name": "gallery", "trans": ["画廊， 美术馆； 楼座； 旁听席； 回廊， 走廊"] },
    { "name": "hardware", "trans": ["五金器具； 硬件"] },
    { "name": "deputy", "trans": ["副职， 副手； 代表， 代理人"] },
    { "name": "warmth", "trans": ["暖和， 温暖； 热烈， 热情， 热心"] },
    { "name": "supreme", "trans": ["最高的， 最大的； 极度的， 最重要的"] },
    { "name": "ambulance", "trans": ["救护车； 野战医院"] },
    { "name": "priority", "trans": ["优先， 重点； 优先考虑的事"] },
    { "name": "slope", "trans": ["倾斜", "倾斜；斜面"] },
    { "name": "philosophy", "trans": ["哲学， 哲理， 人生哲学"] },
    { "name": "preventive", "trans": ["预防性的"] },
    { "name": "creative", "trans": ["创造性的， 创作的"] },
    { "name": "junior", "trans": ["年少的；资历较浅的", "晚辈； 三年级学生"] },
    { "name": "allocate", "trans": ["分配， 分派， 把…拨给"] },
    { "name": "provide", "trans": ["提供， 供给"] },
    { "name": "standpoint", "trans": ["立场， 观点"] },
    { "name": "worship", "trans": ["礼拜；崇拜；信奉", "崇拜"] },
    { "name": "lawn", "trans": ["草地， 草坪， 草场"] },
    { "name": "primary", "trans": ["首要的， 主要的； 基本的； 最初的， 初级的"] },
    { "name": "log", "trans": ["原木，木料；航海日志", "正式记录"] },
    { "name": "solar", "trans": ["太阳的， 日光的； 太阳能的"] },
    { "name": "consume", "trans": ["消费， 吃完， 喝光； 毁灭； 使着迷， 充满"] },
    { "name": "formation", "trans": ["形成， 构成， 形成物； 队形， 排列"] },
    { "name": "layer", "trans": ["层， 层次"] },
    { "name": "triangle", "trans": ["三角"] },
    { "name": "accidental", "trans": ["偶然的， 意外的"] },
    { "name": "assure", "trans": ["使确信； 确保， 向…保证"] },
    { "name": "consult", "trans": ["请教； 查阅； 商议"] },
    { "name": "theme", "trans": ["主题， 题目"] },
    { "name": "smash", "trans": ["粉碎，打烂；狠打，猛击；破灭，失败；猛撞，猛冲", "破碎 ； 猛击， 猛撞； 轰动的演出， 巨大的成功"] },
    { "name": "merely", "trans": ["仅仅， 只不过"] },
    { "name": "contradiction", "trans": ["矛盾， 不一致； 否认， 反驳"] },
    { "name": "fuss", "trans": ["忙乱； 大惊小怪， 小题大做； 烦恼， 过于忧虑"] },
    { "name": "wealth", "trans": ["财富， 财产； 丰富"] },
    { "name": "editor", "trans": ["编辑， 编者， 校订者"] },
    { "name": "barrel", "trans": ["桶； 圆筒； 枪管"] },
    { "name": "means", "trans": ["方法， 手段， 工具； 金钱， 财产"] },
    { "name": "desire", "trans": ["渴望；要求", "愿望； 欲望"] },
    { "name": "survive", "trans": ["活下来， 幸存", "从…逃出，幸免于；从中挺过来；比…活得长"] },
    { "name": "efficient", "trans": ["效率高的； 有能力的"] },
    { "name": "initial", "trans": ["最初的，开始的", "首字母"] },
    { "name": "unfortunately", "trans": ["不幸地"] },
    { "name": "consistent", "trans": ["坚持的， 一贯的； 一致的， 符合的"] },
    {
      "name": "reverse",
      "trans": ["撤销，推翻；反向，倒转；彻底改变", "反向的， 相反的， 倒转的", "相反，对立面；反面，背面，后面；挫折，逆境"]
    },
    { "name": "heterogeneity", "trans": ["异种， 异质， 不同成分"] },
    { "name": "sword", "trans": ["剑， 刀"] },
    { "name": "permanent", "trans": ["永久的， 固定的"] },
    { "name": "barely", "trans": ["仅仅， 只不过， 几乎不"] },
    { "name": "summarize", "trans": ["概括， 概述， 总结"] },
    { "name": "chap", "trans": ["小伙子， 男人， 家伙"] },
    { "name": "facility", "trans": ["设备； 容易； 场所"] },
    { "name": "beam", "trans": ["梁，横梁；束，柱；笑容，喜色", "面露喜色； 定向发出， 播送"] },
    { "name": "alliance", "trans": ["结盟， 联盟"] },
    { "name": "civilization", "trans": ["文明， 文化； 开化"] },
    { "name": "fiction", "trans": ["小说； 虚构， 杜撰"] },
    { "name": "possession", "trans": ["有， 所有； 所有物"] },
    { "name": "replace", "trans": ["代替， 取代； 更换， 调换； 把…放回原处"] },
    { "name": "appointment", "trans": ["任命； 约定； 约会； 委任的职位"] },
    { "name": "hip", "trans": ["臀部， 髋部"] },
    { "name": "merry", "trans": ["欢乐的， 愉快的"] },
    { "name": "dam", "trans": ["水坝，水堤；障碍物", "筑堤挡住"] },
    { "name": "pessimistic", "trans": ["悲观的"] },
    { "name": "commander", "trans": ["司令官， 指挥员"] },
    { "name": "bloom", "trans": ["开花", "花，开花，开花期；青春焕发"] },
    { "name": "major", "trans": ["较大的；主要的；重大的", "少校； 专业"] },
    { "name": "zone", "trans": ["地区， 区域， 范围"] },
    { "name": "emphasis", "trans": ["强调； 重点， 重要性"] },
    { "name": "interrupt", "trans": ["打断， 打扰， 中断"] },
    { "name": "feasible", "trans": ["可行的， 可能的"] },
    { "name": "prescribe", "trans": ["开； 吩咐采用； 规定， 指定"] },
    { "name": "potential", "trans": ["潜在的，可能的", "潜力， 潜能"] },
    { "name": "bean", "trans": ["豆， 蚕豆"] },
    { "name": "accustomed", "trans": ["惯常的， 习惯的"] },
    { "name": "combination", "trans": ["结合， 联合； 化合"] },
    { "name": "obtain", "trans": ["获得； 通用， 流行； 存在"] },
    { "name": "turbine", "trans": ["叶轮机， 汽轮机"] },
    { "name": "repeatedly", "trans": ["一再， 再三， 多次地"] },
    { "name": "curiosity", "trans": ["好奇， 好奇心； 奇物， 奇事， 珍品"] },
    { "name": "gross", "trans": ["总的； 严重的； 粗俗的； 臃肿的"] },
    { "name": "workshop", "trans": ["车间， 作坊， 创作室； 研讨会， 讲习班"] },
    { "name": "resist", "trans": ["抵抗， 反抗， 抵制； 抗， 耐； 忍住， 拒受…的影响"] },
    { "name": "liter", "trans": ["升"] },
    { "name": "format", "trans": ["设计，安排；格式，样式，版式", "使格式化"] },
    { "name": "sacrifice", "trans": ["牺牲，舍身；献祭，供奉", "牺牲； 供奉； 祭品"] },
    { "name": "illegal", "trans": ["不合法的， 非法的"] },
    { "name": "stimulate", "trans": ["刺激， 激励， 激发"] },
    { "name": "formal", "trans": ["正式的， 礼仪上的"] },
    { "name": "congratulate", "trans": ["祝贺， 向…道喜"] },
    { "name": "rainbow", "trans": ["虹， 彩虹"] },
    { "name": "soak", "trans": ["浸， 泡"] },
    { "name": "soar", "trans": ["猛增， 剧增； 高飞， 升腾； 高涨； 高耸， 屹立"] },
    { "name": "salesman", "trans": ["售货员， 推销员"] },
    { "name": "interference", "trans": ["干涉， 干预， 阻碍"] },
    { "name": "fibre", "trans": ["纤维， 纤维质"] },
    { "name": "sustain", "trans": ["保持， 使持续下去； 供养， 维持； 支持， 支撑； 经受， 遭受"] },
    { "name": "entertain", "trans": ["使欢乐； 招待， 请客"] },
    { "name": "altitude", "trans": ["海拔， 高度； 高处"] },
    { "name": "classify", "trans": ["把…分类， 把…分级"] },
    { "name": "ease", "trans": ["容易；舒适", "缓和"] },
    { "name": "critical", "trans": ["决定性的； 批评的"] },
    { "name": "colony", "trans": ["殖民地； 侨居地， 聚居地； 群体， 集群"] },
    { "name": "mathematics", "trans": ["数学"] },
    { "name": "wagon", "trans": ["四轮马车， 大篷车； 铁路货车， 客货两用车"] },
    { "name": "elsewhere", "trans": ["在别处， 向别处"] },
    { "name": "penetrate", "trans": ["透入， 渗入， 进入； 刺穿； 洞察， 了解"] },
    { "name": "principal", "trans": ["最重要的，主要的", "负责人， 校长； 资本， 本金； 主要演员， 主角"] },
    { "name": "tend", "trans": ["照管， 照料， 护理", "易于，往往会；趋向，倾向"] },
    { "name": "exceedingly", "trans": ["极端地， 非常"] },
    { "name": "specimen", "trans": ["样本， 标本， 样品"] },
    { "name": "relief", "trans": ["轻松， 宽慰； 缓解； 调剂， 消遣； 接替"] },
    { "name": "dimension", "trans": ["尺寸， 尺度； 方面， 特点； 面积， 规模"] },
    { "name": "introduction", "trans": ["介绍， 引进； 引言"] },
    { "name": "deserve", "trans": ["应受， 值得"] },
    { "name": "process", "trans": ["过程；制作法；程序", "加工； 办理"] },
    { "name": "philosopher", "trans": ["哲学家， 哲人"] },
    { "name": "restore", "trans": ["恢复， 使回复； 修复， 整修； 归还， 交还"] },
    { "name": "therapy", "trans": ["治疗， 理疗"] },
    { "name": "alternative", "trans": ["两者选一的， 供选择的； 另类的", "替换物，选择；选择的自由"] },
    { "name": "banner", "trans": ["横幅； 旗， 旗帜"] },
    { "name": "concentration", "trans": ["集中； 专注， 专心； 浓缩， 浓度"] },
    { "name": "encounter", "trans": ["遭遇，遇到", "遭遇"] },
    { "name": "soda", "trans": ["碳酸钠， 纯碱； 汽水， 苏打水"] },
    { "name": "colleague", "trans": ["同事， 同僚"] },
    { "name": "gratitude", "trans": ["感激， 感谢， 感恩"] },
    { "name": "approve", "trans": ["赞成， 称许； 批准"] },
    { "name": "interaction", "trans": ["相互作用， 干扰"] },
    { "name": "chew", "trans": ["咀嚼， 嚼碎"] },
    { "name": "interfere", "trans": ["干涉， 干预， 妨碍"] },
    { "name": "apartment", "trans": ["一套公寓房间"] },
    { "name": "account", "trans": ["说明原因； 占", "记述；解释；账目"] },
    { "name": "uneasy", "trans": ["心神不安的， 忧虑的"] },
    { "name": "tolerate", "trans": ["忍受， 容忍； 容许， 承认"] },
    { "name": "developmental", "trans": ["发展的"] },
    { "name": "stack", "trans": ["堆，垛", "堆积， 堆放于"] },
    { "name": "underline", "trans": ["在…下面画线； 强调， 使…突出"] },
    { "name": "innocent", "trans": ["清白的； 幼稚的； 没有恶意的"] },
    { "name": "compassion", "trans": ["同情， 怜悯"] },
    { "name": "alphabet", "trans": ["字母表， 字母系统"] },
    { "name": "parliament", "trans": ["议会， 国会"] },
    { "name": "evaluation", "trans": ["评价； 估计"] },
    { "name": "crack", "trans": ["爆裂； 噼啪地响； 打， 击", "裂缝，裂纹；破裂声"] },
    { "name": "bind", "trans": ["捆绑， 包扎； 装订； 约束； 使结合， 使黏合"] },
    { "name": "trim", "trans": ["整齐的", "使整齐"] },
    { "name": "appreciate", "trans": ["重视， 赏识； 领会， 体会； 感谢； 增值"] },
    { "name": "dental", "trans": ["牙齿的， 牙科的"] },
    { "name": "predictable", "trans": ["可预料的"] },
    { "name": "diverse", "trans": ["不一样的， 相异的； 多种多样的"] },
    { "name": "railway", "trans": ["铁路"] },
    { "name": "cube", "trans": ["清澈透明的", "立方形，立方"] },
    { "name": "rally", "trans": ["集合， 团结； 恢复， 重新振作", "集会；公路汽车赛"] },
    { "name": "prime", "trans": ["首要的；最好的", "青春；全盛时期", "使准备好"] },
    { "name": "happen", "trans": ["发生； 碰巧， 恰好"] },
    { "name": "festival", "trans": ["节日； 音乐节"] },
    { "name": "mild", "trans": ["温柔的； 温暖的； 轻微的"] },
    { "name": "profile", "trans": ["侧面；人物简介；轮廓，形象；姿态，引人注目的状态", "为…描绘； 写…的传略"] },
    { "name": "loyal", "trans": ["忠诚的， 忠心的"] },
    { "name": "preference", "trans": ["喜爱， 偏爱； 偏爱的事物； 优先"] },
    { "name": "impact", "trans": ["影响， 作用， 冲击"] },
    { "name": "opportunity", "trans": ["机会， 良机"] },
    { "name": "active", "trans": ["活跃的， 积极的； 主动的； 起作用的"] },
    { "name": "dorm", "trans": ["宿舍"] },
    { "name": "whichever", "trans": ["无论哪个， 无论哪些"] },
    { "name": "court", "trans": ["法院， 法庭； 庭院； 宫廷； 球场"] },
    { "name": "venture", "trans": ["冒险， 敢于", "风险投资，风险项目"] },
    { "name": "mill", "trans": ["磨， 碾", "磨坊；碾磨机；制造厂，工厂"] },
    { "name": "bucket", "trans": ["水桶， 吊桶； 铲斗"] },
    { "name": "senior", "trans": ["资格较老的，地位较高的；年长的", "较年长者； 毕业班学生"] },
    { "name": "dose", "trans": ["剂量， 用量， 一剂"] },
    { "name": "route", "trans": ["路线， 路程"] },
    { "name": "enthusiasm", "trans": ["热情， 热心， 热忱； 巨大兴趣"] },
    { "name": "flexible", "trans": ["易弯曲的， 灵活的"] },
    { "name": "adult", "trans": ["成年的； 成年人的， 适宜于成年人的", "成年人"] },
    { "name": "attitude", "trans": ["态度， 看法； 姿势"] },
    { "name": "relative", "trans": ["有关系的，相对的", "亲属， 亲戚"] },
    { "name": "organ", "trans": ["器官； 机构； 管风琴； 新闻媒介"] },
    { "name": "average", "trans": ["平均的；平常的", "平均", "平均数，平均"] },
    { "name": "treatment", "trans": ["待遇； 治疗， 疗法； 对待， 处理"] },
    { "name": "compare", "trans": ["比较， 对照； 把…比作"] },
    { "name": "pluralism", "trans": ["多重性； 兼职， 兼任"] },
    { "name": "influential", "trans": ["有影响的； 有权势的"] },
    { "name": "shiver", "trans": ["战栗， 颤抖"] },
    { "name": "Bible", "trans": ["圣经"] },
    { "name": "liquor", "trans": ["酒， 烈性酒"] },
    { "name": "honourable", "trans": ["光荣的； 可敬的； 正直的"] },
    { "name": "characteristic", "trans": ["特有的，典型的", "特性"] },
    { "name": "transparent", "trans": ["透明的； 易识破的； 明显的， 清楚的"] },
    { "name": "interior", "trans": ["内部的；内地的", "内部"] },
    { "name": "rouse", "trans": ["惊起； 唤起； 唤醒"] },
    { "name": "journal", "trans": ["日报， 杂志， 日志"] },
    { "name": "grasp", "trans": ["抓紧，握紧；理解", "抓； 理解"] },
    { "name": "involvement", "trans": ["连累； 包含"] },
    { "name": "spark", "trans": ["发出火花", "火花，火星"] },
    { "name": "unlike", "trans": ["不同的", "不像…"] },
    { "name": "assembly", "trans": ["立法机构； 议会； 集合； 集会的人们； 装配， 组装"] },
    { "name": "hearing", "trans": ["听力， 听觉； 听力所及之距离； 意见听取会； 申辩的机会"] },
    { "name": "acquisition", "trans": ["取得， 获得， 习得； 获得物， 增添的人"] },
    { "name": "volcano", "trans": ["火山"] },
    { "name": "rigid", "trans": ["严格的； 死板的； 刚硬的， 僵硬的"] },
    { "name": "deadline", "trans": ["最后期限"] },
    { "name": "so-called", "trans": ["所谓的， 号称的"] },
    { "name": "grateful", "trans": ["感激的； 令人愉快的"] },
    { "name": "chip", "trans": ["屑片， 碎片； 炸土豆条； 集成电路片， 集成块； 缺口， 瑕疵"] },
    { "name": "chin", "trans": ["颏， 下巴"] },
    { "name": "emotional", "trans": ["感情的， 情绪的"] },
    { "name": "operational", "trans": ["运转的； 可使用的； 操作上的"] },
    { "name": "staff", "trans": ["工作人员；参谋", "为…配备人员"] },
    { "name": "involve", "trans": ["使卷入， 牵涉； 包含， 含有"] },
    { "name": "noticeable", "trans": ["显而易见的"] },
    { "name": "assemble", "trans": ["集合， 召集； 装配"] },
    { "name": "blast", "trans": ["爆炸，冲击波；一阵", "炸"] },
    { "name": "series", "trans": ["一系列， 连续； 丛书， 连续剧"] },
    { "name": "complicated", "trans": ["复杂的， 难懂的"] },
    { "name": "cultivate", "trans": ["耕作， 种植； 培养， 陶冶"] },
    { "name": "maximum", "trans": ["最大的， 最高的， 顶点的", "最大量，顶点"] },
    { "name": "thrive", "trans": ["兴旺， 繁荣， 旺盛"] },
    { "name": "purse", "trans": ["钱包， 女用小提包"] },
    { "name": "represent", "trans": ["作为…的代表； 表示， 象征； 描绘， 表现"] },
    { "name": "compete", "trans": ["比赛， 竞争， 对抗"] },
    { "name": "dialect", "trans": ["方言， 土语， 地方话"] },
    { "name": "accountant", "trans": ["会计人员， 会计师"] },
    { "name": "stocking", "trans": ["长袜"] },
    { "name": "dim", "trans": ["昏暗的；朦胧的；模糊不清的", "变暗淡"] },
    { "name": "emergency", "trans": ["紧急情况， 突然事件"] },
    { "name": "dip", "trans": ["蘸，浸", "游一会儿泳"] },
    { "name": "recreational", "trans": ["休养的； 娱乐的"] },
    { "name": "faculty", "trans": ["才能， 能力； 系， 科； 全体教员"] },
    { "name": "trail", "trans": ["痕迹，小径", "跟踪， 追踪"] },
    { "name": "passport", "trans": ["护照"] },
    { "name": "prior", "trans": ["在前的； 优先的"] },
    { "name": "considerate", "trans": ["考虑周到的， 体贴的， 体谅的"] },
    { "name": "clue", "trans": ["线索， 暗示， 提示"] },
    { "name": "legal", "trans": ["法律的； 合法的"] },
    { "name": "bracket", "trans": ["括号", "把…归为同一类"] },
    { "name": "highway", "trans": ["公路， 大路"] },
    { "name": "vanish", "trans": ["突然不见， 消失； 不复存在， 绝迹"] },
    { "name": "signal", "trans": ["显著的， 重大的", "信号，暗号；标志，表示", "发信号；标志着"] },
    { "name": "sightseeing", "trans": ["观光， 游览"] },
    { "name": "parcel", "trans": ["包裹，小包，邮包", "分， 分配； 把…包起来， 捆扎"] },
    { "name": "static", "trans": ["静的，静态的；静止的，停滞的", "静电"] },
    { "name": "journey", "trans": ["旅行", "旅行，旅程"] },
    { "name": "planet", "trans": ["行星"] },
    { "name": "finally", "trans": ["最后； 决定性地"] },
    { "name": "exhaust", "trans": ["使筋疲力尽；用尽；详尽论述", "排气装置； 废气"] },
    { "name": "creep", "trans": ["爬行， 缓慢地行进"] },
    { "name": "strap", "trans": ["带子", "捆扎； 用绷带包扎"] },
    { "name": "grape", "trans": ["葡萄， 葡萄藤"] },
    { "name": "conviction", "trans": ["确信； 坚定的信仰； 说服， 信服； 定罪， 判罪"] },
    { "name": "scholar", "trans": ["学者； 奖学金获得者"] },
    { "name": "straw", "trans": ["稻草； 麦秆吸管， 吸管"] },
    { "name": "regarding", "trans": ["关于"] },
    { "name": "graph", "trans": ["图， 图表"] },
    { "name": "vision", "trans": ["想象力； 梦幻； 视力， 视觉"] },
    { "name": "accompany", "trans": ["陪伴， 陪同， 伴随； 为…伴奏"] },
    { "name": "petrol", "trans": ["汽油"] },
    { "name": "acceptance", "trans": ["接受； 承认； 容忍"] },
    { "name": "relativity", "trans": ["相对论； 相关性"] },
    { "name": "beneficial", "trans": ["有利的， 有益的"] },
    { "name": "tackle", "trans": ["对付，处理；与…交涉；阻截，擒抱", "阻截， 擒抱； 用具， 钓具； 辘轳， 滑车"] },
    { "name": "reckon", "trans": ["认为， 估计； 指望， 盼望； 测算， 测量"] },
    { "name": "confusion", "trans": ["困惑， 糊涂； 混淆； 骚乱"] },
    { "name": "occasion", "trans": ["场合，时刻；重大活动；时机；起因", "引起"] },
    { "name": "loan", "trans": ["贷款， 借"] },
    { "name": "virtual", "trans": ["实质上的， 事实上的， 实际上的"] },
    { "name": "squeeze", "trans": ["挤；压榨，榨取；捏，握", "挤； 握手； 拮据， 紧缺"] },
    { "name": "blank", "trans": ["空白的；茫然的，无表情的", "空白； 空白表格"] },
    { "name": "landlord", "trans": ["地主， 房东； 店主"] },
    { "name": "session", "trans": ["会议， 一届会期； 集会"] },
    { "name": "additional", "trans": ["附加的， 追加的"] },
    { "name": "worthy", "trans": ["有价值的， 值得的"] },
    { "name": "endless", "trans": ["无止境的"] },
    { "name": "expectation", "trans": ["期待， 期望， 预期； 前程， 成功的前景"] },
    { "name": "title", "trans": ["标题， 题目； 称号， 头衔； 权利， 权益"] },
    { "name": "mist", "trans": ["蒙上薄雾； 模糊", "薄雾"] },
    { "name": "primarily", "trans": ["首先； 主要地"] },
    { "name": "duration", "trans": ["持续， 持久"] },
    { "name": "plantation", "trans": ["种植园， 人工林"] },
    { "name": "ignore", "trans": ["不顾， 不理， 忽视"] },
    { "name": "loaf", "trans": ["游荡， 闲逛", "面包"] },
    { "name": "nuclear", "trans": ["核子的， 核能的， 核武器的； 核心的"] },
    { "name": "reception", "trans": ["招待会， 欢迎会； 接受； 接待； 接收效果"] },
    { "name": "outward", "trans": ["外面的； 外表的； 向外的"] },
    { "name": "bloody", "trans": ["[用于加强语气]非常，很", "[用于加强语气]非常的；该死的；流血的；嗜杀的", "血染"] },
    { "name": "poetry", "trans": ["诗， 诗歌， 诗集"] },
    { "name": "owner", "trans": ["物主， 所有人"] },
    { "name": "vapour", "trans": ["蒸汽"] },
    { "name": "sole", "trans": ["单独的， 唯一的", "脚底，鞋底，袜底"] },
    { "name": "jury", "trans": ["陪审团； 评奖团"] },
    { "name": "holy", "trans": ["神圣的， 圣洁的， 虔诚的"] },
    { "name": "spelling", "trans": ["拼字， 拼法， 拼写法"] },
    { "name": "relax", "trans": ["放松， 松弛； 放宽， 缓和"] },
    { "name": "priest", "trans": ["神父， 牧师"] },
    { "name": "monitor", "trans": ["班长；监视器，检测器；显示器", "监听； 检测"] },
    { "name": "permission", "trans": ["允许， 许可， 准许"] },
    { "name": "grand", "trans": ["宏伟的； 重大的； 傲慢的； 派头大的； 绝佳的； 全部的"] },
    { "name": "schedule", "trans": ["时刻表，日程安排表；清单，明细表", "安排， 排定"] },
    { "name": "postage", "trans": ["邮费， 邮资"] },
    { "name": "tame", "trans": ["驯服的，顺从的；沉闷的，乏味的", "制服， 控制并利用； 驯化， 驯服"] },
    { "name": "material", "trans": ["物质的， 实体的； 肉体的； 重要的， 实质性的", "材料，素材"] },
    { "name": "universe", "trans": ["宇宙， 世界； 领域， 范围"] },
    { "name": "interpret", "trans": ["解释， 说明； 口译"] },
    { "name": "spectacular", "trans": ["壮观的，引人注目的", "壮观的演出； 惊人之举"] },
    { "name": "inherit", "trans": ["成为继承人", "继承"] },
    { "name": "peculiar", "trans": ["奇怪的， 古怪的； 特有的"] },
    { "name": "blanket", "trans": ["毛毯， 毯子， 羊毛毯"] },
    { "name": "grant", "trans": ["授予物；补助金", "授予， 给予； 同意"] },
    { "name": "injection", "trans": ["注射， 注入； 充满"] },
    { "name": "envy", "trans": ["妒忌，羡慕；妒忌的对象", "妒忌， 羡慕"] },
    { "name": "hut", "trans": ["小屋， 棚屋"] },
    { "name": "invincible", "trans": ["不能征服的， 无敌的"] },
    { "name": "discount", "trans": ["折扣", "将…打折扣； 不信； 漠视， 低估"] },
    { "name": "convert", "trans": ["转变， 转化； 改变"] },
    { "name": "construct", "trans": ["建造； 构思", "建筑物； 构想， 观念"] },
    { "name": "attempt", "trans": ["尝试， 试图， 努力"] },
    { "name": "thick", "trans": ["厚厚地", "厚的，密的，浓的；不清楚的，口音重的"] },
    { "name": "superficial", "trans": ["表面的， 肤浅的"] },
    { "name": "division", "trans": ["分， 分配； 除法； 分歧； 分裂"] },
    { "name": "executive", "trans": ["执行的", "执行者"] },
    { "name": "navigation", "trans": ["航行， 航海， 航空； 导航， 领航"] },
    { "name": "melt", "trans": ["融化； 熔化； 消散"] },
    { "name": "hook", "trans": ["钩住", "钩，挂钩"] },
    { "name": "stale", "trans": ["不新鲜的； 陈腐的， 过时的"] },
    { "name": "balance", "trans": ["使平衡；称；权衡，比较", "天平； 平衡； 结存， 结欠"] },
    { "name": "cigar", "trans": ["雪茄烟"] },
    { "name": "action", "trans": ["行动； 已做的事； 作用， 功能； 情节"] },
    { "name": "stadium", "trans": ["运动场， 体育场"] },
    { "name": "whereas", "trans": ["然而， 但是， 尽管"] },
    {
      "name": "pitch",
      "trans": ["投， 掷； 架设， 搭， 营； 颠簸", "球场；程度，强度；高度；音高；沥青", "投掷；使猛然倒下；表达；把…定于特定程度；定调"]
    },
    { "name": "kindness", "trans": ["仁慈， 好意"] },
    { "name": "adoptive", "trans": ["收养关系的； 采用的"] },
    { "name": "chop", "trans": ["砍，劈；切细", "排骨"] },
    { "name": "electrical", "trans": ["电的， 电气科学的"] },
    { "name": "statue", "trans": ["塑像， 雕像， 铸像"] },
    { "name": "being", "trans": ["存在； 生物， 生命"] },
    { "name": "sensitive", "trans": ["敏感的， 灵敏的， 神经过敏的； 容易生气的， 易受伤害的"] },
    { "name": "protest", "trans": ["抗议， 反对", "抗议， 反对"] },
    { "name": "classification", "trans": ["分类， 分级， 分类法； 类别， 级别"] },
    { "name": "vacant", "trans": ["空的， 未被占用的； 空缺的； 茫然的， 空虚的"] },
    { "name": "physicist", "trans": ["物理学家"] },
    { "name": "afford", "trans": ["担负得起； 提供"] },
    { "name": "underground", "trans": ["在地下，往地下；秘密地，不公开地", "地下的；秘密的，不公开的", "地铁； 地下组织"] },
    { "name": "curse", "trans": ["诅咒，咒骂", "天谴； 祸害， 祸根"] },
    { "name": "impression", "trans": ["印象； 印记"] },
    { "name": "baseball", "trans": ["棒球"] },
    { "name": "interval", "trans": ["间隔， 间距； 幕间休息"] },
    { "name": "evaluate", "trans": ["评价； 估…的价"] },
    { "name": "machinery", "trans": ["机器， 机械"] },
    { "name": "status", "trans": ["地位， 身份； 情形， 状况"] },
    { "name": "shield", "trans": ["防护物，护罩；盾", "保护， 防护"] },
    { "name": "upset", "trans": ["心烦的， 苦恼的； 不适的", "使心烦意乱，使苦恼，使不适；搅乱；弄翻，倾覆", "翻倒；搅乱；不安；不适"] },
    { "name": "inference", "trans": ["推断结果， 结论； 推论， 推理， 推断"] },
    { "name": "curve", "trans": ["弯曲", "曲线，弯，弯曲处"] },
    { "name": "dot", "trans": ["点，圆点", "打点于"] },
    { "name": "skim", "trans": ["撇去， 掠过， 擦过； 浏览， 略读"] },
    { "name": "phenomenon", "trans": ["现象"] },
    { "name": "notify", "trans": ["通知， 告知， 报告"] },
    { "name": "mention", "trans": ["提及， 说起"] },
    { "name": "stream", "trans": ["流出， 涌出", "河，溪流；一股，一串"] },
    { "name": "laundry", "trans": ["洗衣房， 洗衣店； 待洗的衣服， 洗好的衣服"] },
    { "name": "accumulate", "trans": ["积累， 堆积"] },
    { "name": "crime", "trans": ["罪， 罪行， 犯罪"] },
    { "name": "heave", "trans": ["起伏，隆起；呕吐，恶心", "举起，提起，拉起，拖；扔；发出", "举起； 起伏， 升降"] },
    { "name": "leisure", "trans": ["空闲时间； 悠闲"] },
    { "name": "nourish", "trans": ["提供养分， 养育"] },
    { "name": "surround", "trans": ["围， 围绕； 圈住"] },
    { "name": "mat", "trans": ["席子， 垫子"] },
    { "name": "cigarette", "trans": ["香烟， 纸烟， 卷烟"] },
    { "name": "torch", "trans": ["火炬， 火把； 手电筒"] },
    { "name": "positive", "trans": ["确实的， 明确的； 积极的， 肯定的"] },
    { "name": "favourite", "trans": ["特别受喜爱的", "特别受喜爱的人"] },
    { "name": "menu", "trans": ["菜单"] },
    { "name": "prospect", "trans": ["勘探， 勘察", "前景， 前途， 可能性； 景象， 景色； 可能成为主顾的人； 有希望的候选人"] },
    { "name": "radar", "trans": ["雷达"] },
    { "name": "refugee", "trans": ["难民"] },
    { "name": "aluminium", "trans": ["铝"] },
    { "name": "comparable", "trans": ["可比较的； 类似的； 比得上的"] },
    { "name": "specialize", "trans": ["成为…专家； 专攻"] },
    { "name": "rotate", "trans": ["旋转， 转动； 轮流"] },
    { "name": "instance", "trans": ["例子， 实例， 事例"] },
    { "name": "opera", "trans": ["歌剧"] },
    { "name": "presumably", "trans": ["大概， 可能， 据推测"] },
    { "name": "conscience", "trans": ["良心， 道德心"] },
    { "name": "subject", "trans": ["题目； 学科； 主语", "使隶属"] },
    { "name": "practically", "trans": ["几乎， 简直； 实际上"] },
    { "name": "recognition", "trans": ["认出， 识别； 承认； 赏识， 表彰， 报偿"] },
    { "name": "cassette", "trans": ["盒式录音带"] },
    { "name": "blade", "trans": ["刀刃， 刀片， 叶片"] },
    { "name": "arrangement", "trans": ["整理， 排列， 安排； 准备工作"] },
    { "name": "revenue", "trans": ["收入， 收益； 税收， 岁入"] },
    { "name": "strip", "trans": ["脱光衣服；剥夺，夺去", "条带"] },
    { "name": "solution", "trans": ["解决； 解决办法； 溶液"] },
    { "name": "continuous", "trans": ["连续不断的； 延伸的"] },
    { "name": "opponent", "trans": ["对手， 敌手， 对抗者"] },
    { "name": "accelerate", "trans": ["使加快， 使增速"] },
    { "name": "backward", "trans": ["[-]倒； 向后， 往回", "向后的，倒的；落后的，进步慢的"] },
    { "name": "grain", "trans": ["谷物， 谷粒； 颗粒； 少量， 微量"] },
    { "name": "maid", "trans": ["女仆， 侍女"] },
    { "name": "residence", "trans": ["住处， 住宅； 居住； 居住资格"] },
    { "name": "credit", "trans": ["信贷，赊欠；赞扬，荣誉；学分；信任", "相信； 把…记入贷方； 把…归于"] },
    { "name": "regardless", "trans": ["不顾后果地； 不管怎样， 无论如何"] },
    { "name": "oxygen", "trans": ["氧， 氧气"] },
    { "name": "intensive", "trans": ["加强的； 精深的， 透彻的； 精耕细作的"] },
    { "name": "alter", "trans": ["改变， 变更， 变动"] },
    { "name": "combine", "trans": ["结合， 联合； 化合", "联合企业； 联合收割机"] },
    { "name": "ideal", "trans": ["理想的；观念的；空想的", "理想； 理想的东西"] },
    { "name": "bearing", "trans": ["举止， 风度； 方位， 方向感； 影响"] },
    { "name": "illustrate", "trans": ["说明"] },
    { "name": "occur", "trans": ["发生， 出现， 存在； 被想起， 被想到"] },
    { "name": "comedy", "trans": ["喜剧， 喜剧性"] },
    { "name": "broom", "trans": ["扫帚"] },
    { "name": "establishment", "trans": ["建立， 设立， 确立； 企业； 当权人物"] },
    { "name": "pressure", "trans": ["压，压强；压迫", "对…施加压力， 迫使"] },
    { "name": "breeze", "trans": ["飘然而行", "微风，和风"] },
    { "name": "trash", "trans": ["垃圾，废物；拙劣的文学作品；没用的人，社会渣滓，败类", "捣毁， 破坏"] },
    { "name": "painter", "trans": ["漆工； 画家"] },
    { "name": "millimetre", "trans": ["毫米"] },
    { "name": "horn", "trans": ["角； 号角； 警报器"] },
    { "name": "specialist", "trans": ["专家"] },
    { "name": "infer", "trans": ["推论， 推断； 猜想"] },
    { "name": "compass", "trans": ["罗盘， 指南针； 圆规； 界限， 范围"] },
    { "name": "background", "trans": ["出身背景， 经历； 背景资料； 背景， 底子"] },
    { "name": "sore", "trans": ["痛的；恼火的；急剧的，剧烈的", "疮， 痛处"] },
    { "name": "dispose", "trans": ["处理， 解决； 使倾向于"] },
    { "name": "glow", "trans": ["发白热光", "白热光；脸红；激情"] },
    { "name": "curtain", "trans": ["门帘， 窗帘； 幕"] },
    { "name": "laughter", "trans": ["笑， 笑声"] },
    { "name": "constitution", "trans": ["宪法， 章程； 组成； 设立"] },
    { "name": "blend", "trans": ["混合"] },
    { "name": "certificate", "trans": ["证书， 证件， 执照"] },
    { "name": "mess", "trans": ["凌乱状态，混乱的局面；困境", "弄糟， 弄脏， 搞乱"] },
    { "name": "sour", "trans": ["酸的；馊的，酸味的；脾气坏的", "变酸， 变馊； 变得乖戾"] },
    { "name": "delete", "trans": ["删除， 擦掉"] },
    { "name": "nonsense", "trans": ["胡说， 废话； 冒失的行动"] },
    { "name": "stain", "trans": ["玷污；给…着色", "污点， 污迹"] },
    { "name": "transform", "trans": ["使改观， 改革； 变换， 把…转换成"] },
    { "name": "leadership", "trans": ["领导"] },
    { "name": "genuine", "trans": ["真的， 真正的； 真诚的"] },
    { "name": "X-ray", "trans": ["X射线， X光"] },
    { "name": "physical", "trans": ["身体的； 物理的； 物质的， 有形的"] },
    { "name": "fulfil", "trans": ["履行， 实现， 完成； 满足， 使满意"] },
    { "name": "wicked", "trans": ["坏的， 令人厌恶的； 淘气的， 顽皮的"] },
    { "name": "spokesman", "trans": ["发言人"] },
    { "name": "equation", "trans": ["方程， 等式"] },
    { "name": "impressive", "trans": ["给人深刻印象的"] },
    { "name": "compound", "trans": ["复合的， 化合的", "化合物，复合物；复合词", "使恶化， 加重； 使化合， 使合成"] },
    { "name": "structure", "trans": ["结构，构造", "建造"] },
    { "name": "intensity", "trans": ["强烈， 剧烈； 强度"] },
    { "name": "waken", "trans": ["醒来， 弄醒"] },
    { "name": "stake", "trans": ["桩；利害关系；股份；赌金，赌注", "以…打赌， 拿…冒险"] },
    { "name": "extraordinary", "trans": ["非同寻常的， 特别的"] },
    { "name": "witness", "trans": ["证据；目击者，证人", "目击； 为…作证， 证明"] },
    { "name": "concerning", "trans": ["关于"] },
    { "name": "attraction", "trans": ["吸引， 吸引力； 引力； 具有吸引力的事物"] },
    { "name": "conventional", "trans": ["普通的； 习惯的， 常规的； 因循守旧的"] },
    { "name": "regulation", "trans": ["规章， 规则； 管理， 控制； 调节"] },
    { "name": "puzzle", "trans": ["迷惑，为难；苦思", "智力测验； 谜， 难题， 令人费解的事； 困惑， 迷惑"] },
    { "name": "circulation", "trans": ["循环； 流传； 发行， 流通"] },
    { "name": "bake", "trans": ["烤， 烘， 焙"] },
    { "name": "mere", "trans": ["仅仅的； 纯粹的"] },
    { "name": "accommodation", "trans": ["住处； 膳宿"] },
    { "name": "evident", "trans": ["明显的， 明白的"] },
    { "name": "suburb", "trans": ["郊区， 郊外， 近郊"] },
    { "name": "lodge", "trans": ["暂住，借宿", "乡间小屋； 旅舍"] },
    { "name": "threat", "trans": ["威胁， 恐吓； 凶兆， 征兆"] },
    { "name": "writer", "trans": ["作者， 作家"] },
    { "name": "simplify", "trans": ["简化， 使简明"] },
    { "name": "breast", "trans": ["乳房； 胸脯， 胸膛"] },
    { "name": "invention", "trans": ["发明， 创造； 捏造"] },
    { "name": "mercy", "trans": ["仁慈， 宽容； 恩惠； 幸运"] },
    { "name": "annoy", "trans": ["使恼怒； 打搅"] },
    { "name": "curl", "trans": ["鬈发； 卷曲， 卷曲物"] },
    { "name": "inform", "trans": ["告发， 告密", "通知；报告"] },
    { "name": "psychological", "trans": ["心理的"] },
    { "name": "glue", "trans": ["胶，胶水", "胶合"] },
    { "name": "commit", "trans": ["犯， 干； 使承诺； 把…托付给； 调拨…供使用， 拨出"] },
    { "name": "scream", "trans": ["尖叫，呼啸", "尖叫声"] },
    { "name": "sorrow", "trans": ["悲痛， 悲哀， 悲伤； 伤心事， 不幸的事"] },
    { "name": "crash", "trans": ["发出撞击声；垮台，破产；冲，闯", "碰撞；坠落", "碰撞； 破裂声"] },
    { "name": "patch", "trans": ["补丁；斑，与周围不同的部分；一小块地", "补， 修补"] },
    { "name": "observer", "trans": ["观察员， 观察者"] },
    { "name": "character", "trans": ["性格， 特性； 性质； 人物， 角色； 符号； 字"] },
    { "name": "reflect", "trans": ["反映， 显示； 反射， 映现； 深思， 考虑， 反省"] },
    { "name": "procession", "trans": ["队伍， 行列"] },
    { "name": "contribution", "trans": ["贡献， 促成作用； 捐款， 捐献物； 稿件"] },
    { "name": "Marxist", "trans": ["马克思主义的", "马克思主义者"] },
    { "name": "bang", "trans": ["猛击， 猛撞； 发出砰的一声， 砰砰地响", "巨响，枪声；猛击", "猛击，猛撞；砰地敲"] },
    { "name": "brow", "trans": ["额， 眉， 眉毛"] },
    { "name": "meaning", "trans": ["意义， 意思； 目的； 重要性"] },
    { "name": "ignorant", "trans": ["不知道的， 无知的"] },
    { "name": "magnetic", "trans": ["磁的； 有吸引力的"] },
    { "name": "band", "trans": ["乐队；群，伙；带；条纹；波段", "用带绑扎"] },
    { "name": "orbit", "trans": ["轨道", "做轨道运行"] },
    { "name": "comprise", "trans": ["包含， 包括， 构成"] },
    { "name": "moderate", "trans": ["中等的； 温和的， 稳健的； 有节制的", "和缓， 减轻； 节制"] },
    { "name": "resident", "trans": ["居住的， 定居的； 住校的， 住院的", "居民，定居者；住院医生"] },
    { "name": "spray", "trans": ["喷， 溅散", "浪花，水花；喷雾"] },
    { "name": "distinguish", "trans": ["区别， 辨别， 识别； 使显出特色， 使杰出"] },
    { "name": "receiver", "trans": ["听筒； 接收器"] },
    { "name": "outer", "trans": ["外面的， 外层的"] },
    { "name": "invent", "trans": ["发明， 创造； 捏造"] },
    { "name": "fluent", "trans": ["流利的， 流畅的"] },
    { "name": "shortcoming", "trans": ["短处， 缺点"] },
    { "name": "substantial", "trans": ["可观的， 大量的； 结实的， 坚固的； 实质的， 真实的"] },
    { "name": "execute", "trans": ["将…处死； 实施， 执行"] },
    { "name": "benefit", "trans": ["有益于； 得益", "利益，恩惠；救济金，保险金，津贴"] },
    { "name": "flood", "trans": ["淹没； 涌入", "洪水；大量"] },
    { "name": "vitamin", "trans": ["维生素"] },
    { "name": "intention", "trans": ["意图， 意向， 目的"] },
    { "name": "rude", "trans": ["粗鲁的， 不礼貌的； 粗糙的， 粗陋的"] },
    { "name": "symptom", "trans": ["症状； 征候， 征兆"] },
    { "name": "pillar", "trans": ["柱， 柱子； 栋梁"] },
    { "name": "thereby", "trans": ["因此， 从而， 由此"] },
    { "name": "unique", "trans": ["唯一的， 独一无二的； 极不寻常的， 极好的"] },
    { "name": "funeral", "trans": ["葬礼， 丧礼， 丧葬"] },
    { "name": "boost", "trans": ["提高，使增长；推动，激励；替…做广告，宣扬", "提高， 增长； 推动， 激励"] },
    { "name": "fireman", "trans": ["消防队员； 司炉工"] },
    { "name": "sincere", "trans": ["真诚的， 诚挚的"] },
    { "name": "male", "trans": ["男的，雄的", "男子， 雄性动物"] },
    { "name": "ministry", "trans": ["部"] },
    { "name": "religious", "trans": ["宗教的； 笃信宗教的， 虔诚的"] },
    { "name": "nevertheless", "trans": ["仍然， 然而"] },
    { "name": "prominent", "trans": ["突出的， 杰出的； 突起的， 凸出的"] },
    { "name": "shelter", "trans": ["掩蔽处，躲避处；住所；掩蔽，保护", "掩蔽， 庇护； 躲避"] },
    { "name": "outset", "trans": ["开始， 开端"] },
    { "name": "jewel", "trans": ["宝石； 宝石饰物"] },
    { "name": "scan", "trans": ["细看，审视；扫描，浏览", "扫描"] },
    { "name": "association", "trans": ["协会， 团体； 联合， 交往"] },
    { "name": "fundamental", "trans": ["基础的，基本的", "基本原则"] },
    { "name": "eliminate", "trans": ["消灭， 消除， 排除； 淘汰"] },
    { "name": "global", "trans": ["全球的， 全世界的； 总的， 完整的"] },
    { "name": "entitle", "trans": ["给…权利； 给题名"] },
    { "name": "outline", "trans": ["提纲，要点；外形，略图", "概述； 描…外形"] },
    { "name": "fade", "trans": ["褪色；逐渐消失；凋谢", "使褪色"] },
    { "name": "unless", "trans": ["除非， 如果不"] },
    { "name": "butterfly", "trans": ["蝴蝶"] },
    { "name": "excess", "trans": ["过量的， 额外的", "超越； 过量， 过度"] },
    { "name": "glance", "trans": ["看一下", "一瞥", "用扫视； 反射"] },
    { "name": "perspective", "trans": ["视角， 观点； 远景， 景观； 透视画法， 透视图"] },
    { "name": "relationship", "trans": ["关系， 关联"] },
    { "name": "equality", "trans": ["等同， 平等， 相等"] },
    { "name": "destruction", "trans": ["破坏， 毁灭， 消灭"] },
    { "name": "dictation", "trans": ["口授； 听写"] },
    { "name": "stiff", "trans": ["极其， 非常； 僵硬地", "硬的，僵直的；不灵活的；拘谨的，生硬的；费劲的；强烈的"] },
    { "name": "expression", "trans": ["词语； 表达； 表情"] },
    { "name": "invade", "trans": ["入侵， 侵略， 侵袭"] },
    { "name": "reaction", "trans": ["反应； 反作用"] },
    { "name": "childhood", "trans": ["童年， 幼年， 早期"] },
    { "name": "wolf", "trans": ["狼"] },
    { "name": "accordance", "trans": ["一致； 和谐"] },
    { "name": "thorough", "trans": ["彻底的， 详尽的"] },
    { "name": "appeal", "trans": ["呼吁，恳求；申诉，上诉", "上诉", "感染力， 吸引力； 呼吁， 上诉"] },
    { "name": "democracy", "trans": ["民主， 民主制； 民主国家"] },
    { "name": "transportation", "trans": ["运输， 运送， 客运"] },
    { "name": "dye", "trans": ["染", "染料； 染色"] },
    { "name": "evolve", "trans": ["使进化， 使发展"] },
    { "name": "extensive", "trans": ["广阔的， 广泛的"] },
    { "name": "congratulation", "trans": ["贺词； 祝贺， 恭喜"] },
    { "name": "abandon", "trans": ["离弃，丢弃；遗弃，抛弃；放弃", "放任； 纵情"] },
    { "name": "definitely", "trans": ["一定地， 明确地"] },
    { "name": "inspire", "trans": ["吸气； 赋予灵感", "鼓舞，给…以灵感"] },
    { "name": "marvelous", "trans": ["奇迹般的， 了不起的"] },
    { "name": "household", "trans": ["家庭的； 家喻户晓的", "家庭，户；家务"] },
    { "name": "geometry", "trans": ["几何， 几何学"] },
    { "name": "ratio", "trans": ["比， 比率"] },
    { "name": "devote", "trans": ["将…奉献给； 把…专用"] },
    { "name": "agent", "trans": ["代理人， 代理商； 政府代表； 动因， 原因； 剂"] },
    { "name": "wool", "trans": ["羊毛； 毛线， 绒线"] },
    { "name": "wholly", "trans": ["完全地， 全部地"] },
    { "name": "instinct", "trans": ["本能， 天性； 直觉"] },
    { "name": "approximate", "trans": ["近似的", "近似； 估计"] },
    { "name": "invest", "trans": ["投资， 投入； 授予"] },
    { "name": "afterward", "trans": ["后来， 以后"] },
    { "name": "reputation", "trans": ["名誉， 名气， 名声"] },
    { "name": "project", "trans": ["伸出； 投射； 预计", "方案， 计划； 课题， 项目； 工程"] },
    { "name": "shrug", "trans": ["耸肩"] },
    { "name": "independence", "trans": ["独立， 自主， 自立"] },
    { "name": "coordination", "trans": ["同等； 调和"] },
    { "name": "presentation", "trans": ["提供， 显示； 外观， 图像； 授予， 赠送； 报告， 介绍； 表演"] },
    { "name": "diameter", "trans": ["直径"] },
    { "name": "barn", "trans": ["谷仓； 牲口棚"] },
    { "name": "bark", "trans": ["吠，叫", "吠声， 叫声； 树皮"] },
    { "name": "loop", "trans": ["成环， 成圈", "圈，环，回路；循环"] },
    { "name": "restrain", "trans": ["阻止， 控制； 抑制， 遏制"] },
    { "name": "greedy", "trans": ["贪吃的； 贪婪的； 渴望的"] },
    { "name": "awkward", "trans": ["笨拙的， 尴尬的； 难操纵的， 使用不便的； 不灵巧的"] },
    { "name": "bare", "trans": ["赤裸的；光秃的，无遮盖的；刚刚够的，勉强的", "露出， 显露"] },
    { "name": "ruin", "trans": ["毁坏", "毁灭；废墟"] },
    { "name": "crane", "trans": ["起重机， 摄影升降机； 鹤"] },
    { "name": "beard", "trans": ["胡须， 络腮胡子"] },
    { "name": "impatient", "trans": ["不耐烦的， 急躁的"] },
    { "name": "spill", "trans": ["溢出，洒落", "溢出"] },
    { "name": "owing", "trans": ["应付的， 未付的"] },
    { "name": "collection", "trans": ["搜集， 聚集， 积聚； 收集， 收取； 收藏品， 收集的东西"] },
    { "name": "gardener", "trans": ["园丁， 花匠"] },
    { "name": "conquest", "trans": ["攻取， 征服， 克服"] },
    { "name": "finding", "trans": ["发现； 调查的结果； 裁决"] },
    { "name": "dense", "trans": ["密集的， 浓厚的； 密度大的"] },
    { "name": "fortnight", "trans": ["两星期， 十四天"] },
    { "name": "laboratory", "trans": ["实验室， 研究室"] },
    { "name": "inspect", "trans": ["进行检查； 进行视察", "检查，审查；检阅"] },
    { "name": "campaign", "trans": ["参加运动； 参加竞选", "战役；运动"] },
    { "name": "systematical", "trans": ["有系统的， 有计划的"] },
    { "name": "vital", "trans": ["致命的， 生死攸关的； 极其重要的； 生命的， 有生命力的"] },
    { "name": "conflict", "trans": ["冲突， 抵触", "冲突， 抵触， 争论； 战斗， 战争"] },
    { "name": "farewell", "trans": ["告别， 欢送会"] },
    { "name": "allow", "trans": ["允许， 准许； 同意给； 承认； 允许…进入"] },
    { "name": "appliance", "trans": ["用具， 器具， 器械； 家电"] },
    { "name": "mass", "trans": ["大量的，大规模的", "聚集， 集中", "众多，大量；团，块，堆；群众；质量"] },
    { "name": "lord", "trans": ["领主， 君主， 贵族"] },
    { "name": "detect", "trans": ["察觉， 发觉； 侦查出"] },
    { "name": "amateur", "trans": ["业余爱好的， 业余的； 外行的", "业余爱好者；外行"] },
    { "name": "poisonous", "trans": ["有毒的； 恶毒的"] },
    { "name": "condemn", "trans": ["谴责， 指责； 判…刑， 宣告…有罪"] },
    { "name": "beast", "trans": ["兽， 野兽， 牲畜； 凶残的人， 令人厌憎的人"] },
    { "name": "assistance", "trans": ["协助， 援助"] },
    { "name": "commerce", "trans": ["商业， 贸易； 社交"] },
    { "name": "gesture", "trans": ["做手势", "姿势，手势，姿态"] },
    { "name": "admit", "trans": ["承认， 供认； 准许…进入"] },
    { "name": "poem", "trans": ["诗"] },
    { "name": "interest", "trans": ["兴趣；利益，利害关系；利息", "使感兴趣"] },
    { "name": "gulf", "trans": ["海湾； 巨大的分歧"] },
    { "name": "poet", "trans": ["诗人"] },
    { "name": "definition", "trans": ["定义， 释义， 定界； 清晰， 鲜明"] },
    { "name": "shave", "trans": ["剃，刮，刨，削；修面，刮脸", "修面， 刮脸"] },
    { "name": "organic", "trans": ["有机体的， 有机物的"] },
    { "name": "mask", "trans": ["面具，面罩，口罩；伪装", "遮盖， 掩饰"] },
    { "name": "summary", "trans": ["概括的， 概要的； 实时的， 即决的", "摘要，概要，一览"] },
    { "name": "apply", "trans": ["适用； 申请， 请求； 涂， 敷， 施", "应用，实施；使用；涂敷；把…施于"] },
    { "name": "inferior", "trans": ["下等的，劣等的；次于…的", "下级， 下属"] },
    { "name": "steamer", "trans": ["轮船， 汽船"] },
    { "name": "recently", "trans": ["最近， 新近"] },
    { "name": "overlook", "trans": ["忽视； 宽恕； 俯瞰"] },
    { "name": "politician", "trans": ["政治家， 政客"] },
    { "name": "incredible", "trans": ["不能相信的， 不可信的； 难以置信的， 不可思议的； 惊人的"] },
    { "name": "dramatic", "trans": ["引人注目的， 激动人心的； 突然的"] },
    { "name": "mosquito", "trans": ["蚊子"] },
    { "name": "guitar", "trans": ["吉他， 六弦琴"] },
    { "name": "layout", "trans": ["布局， 安排， 设计"] },
    { "name": "artificial", "trans": ["人工的， 人为的； 矫揉造作的； 模拟的"] },
    { "name": "imaginary", "trans": ["想象中的， 假想的"] },
    { "name": "removal", "trans": ["除去， 消除； 移动， 搬迁"] },
    { "name": "formula", "trans": ["公式， 方程式； 原则， 方案； 配方"] },
    { "name": "comment", "trans": ["评论", "评论，意见，注释；闲话，议论"] },
    { "name": "revolt", "trans": ["反叛，起义；反抗，违抗；厌恶，生反感", "反叛， 起义"] },
    { "name": "disgust", "trans": ["厌恶， 憎恶"] },
    { "name": "stem", "trans": ["起源", "茎，干；词干", "堵住， 止住， 停住"] },
    { "name": "sunshine", "trans": ["日光， 阳光"] },
    { "name": "trend", "trans": ["伸向；倾向", "倾向"] },
    { "name": "achievement", "trans": ["完成； 成就， 成绩"] },
    { "name": "mechanic", "trans": ["技工， 机修工； 力学， 机械学； 过程， 方法， 技术性细节"] },
    { "name": "desirable", "trans": ["值得向往的， 令人满意的"] },
    { "name": "balcony", "trans": ["阳台； 楼厅， 楼座"] },
    { "name": "consist", "trans": ["由…组成； 在于； 一致"] },
    { "name": "preparation", "trans": ["准备， 预备； 制剂"] },
    { "name": "reliable", "trans": ["可靠的， 可信赖的"] },
    { "name": "relate", "trans": ["有关， 涉及； 符合； 相处", "使有关联；讲述，叙述"] },
    { "name": "critic", "trans": ["批评家， 爱挑剔的人"] },
    { "name": "elevator", "trans": ["电梯， 升降机"] },
    { "name": "grammar", "trans": ["语法； 语法书"] },
    { "name": "enable", "trans": ["使能够， 使可能"] },
    { "name": "preliminary", "trans": ["预备的，初步的", "初步做法， 起始行为"] },
    { "name": "dependent", "trans": ["依靠的， 依赖的； 取决于…的"] },
    { "name": "obstacle", "trans": ["障碍， 障碍物， 妨碍"] },
    { "name": "profession", "trans": ["职业； 声明， 表白"] },
    { "name": "calculator", "trans": ["计算器， 计算者"] },
    { "name": "van", "trans": ["大篷车， 运货车"] },
    { "name": "biology", "trans": ["生物学， 生态学"] },
    { "name": "worm", "trans": ["虫， 蠕虫"] },
    { "name": "compress", "trans": ["压紧， 压缩"] },
    { "name": "arise", "trans": ["出现； 由…引起； 起身， 起床"] },
    { "name": "collaborative", "trans": ["合作的， 协作的， 协力完成的"] },
    { "name": "cherish", "trans": ["珍爱； 怀有"] },
    { "name": "constitute", "trans": ["组成， 形成； 设立； 任命"] },
    { "name": "cattle", "trans": ["牛； 牲口， 家畜"] },
    { "name": "imagination", "trans": ["想象， 想象力， 空想； 想象出"] },
    { "name": "resistance", "trans": ["反抗， 抵制； 抵抗力， 抵抗性； 阻力； 电阻"] },
    { "name": "underneath", "trans": ["在下面，在底下", "在…下面，在…底下", "下部， 底部"] },
    { "name": "nursery", "trans": ["托儿所， 保育室； 苗圃"] },
    { "name": "mate", "trans": ["成为配偶， 交配", "伙伴，同事；配偶，配对物；大副"] },
    { "name": "convince", "trans": ["使确信， 使信服， 说服"] },
    { "name": "emperor", "trans": ["皇帝"] },
    { "name": "prolong", "trans": ["拉长， 延长"] },
    { "name": "theory", "trans": ["理论， 原理； 学说； 意见， 看法"] },
    { "name": "distress", "trans": ["忧虑， 痛苦， 悲伤； 不幸"] },
    { "name": "flock", "trans": ["羊群， 群； 大量"] },
    { "name": "internal", "trans": ["内的； 国内的； 内心的"] },
    { "name": "extension", "trans": ["延长部分， 伸展； 电话分机， 分机号码"] },
    { "name": "signature", "trans": ["署名， 签字， 签名"] },
    { "name": "steep", "trans": ["险峻的， 陡峭的； 过高的； 急剧的"] },
    { "name": "thumb", "trans": ["示意要求搭车", "拇指"] },
    { "name": "republican", "trans": ["共和政体的；共和党的", "共和党人"] },
    { "name": "literary", "trans": ["文学的； 文人的， 书卷气的"] },
    { "name": "architect", "trans": ["建筑师， 设计师； 缔造者"] },
    { "name": "forehead", "trans": ["额头； 前部"] },
    { "name": "classical", "trans": ["古典的， 经典的"] },
    { "name": "privilege", "trans": ["特权； 优惠"] },
    { "name": "foundation", "trans": ["基础；"] },
    { "name": "substitute", "trans": ["代替人；代用品", "用…代替"] },
    { "name": "punch", "trans": ["猛击；穿孔", "猛击； 冲床； 穿孔机； 力量， 效力"] },
    { "name": "naturally", "trans": ["当然； 天然地； 天生地"] },
    { "name": "flexibility", "trans": ["灵活性， 机动性， 柔韧性"] },
    { "name": "mysterious", "trans": ["神秘的， 诡秘的"] },
    { "name": "moreover", "trans": ["而且， 再者， 此外"] },
    { "name": "identity", "trans": ["身份； 个性， 特性； 同一性， 一致性"] },
    { "name": "scatter", "trans": ["撒， 散播； 散开， 驱散"] },
    { "name": "installation", "trans": ["安装； 装置， 设施； 就任， 就职"] },
    { "name": "writing", "trans": ["书写， 写； 著作"] },
    { "name": "federal", "trans": ["联邦的， 联盟的"] },
    { "name": "violate", "trans": ["违反， 违背； 亵渎； 侵犯； 妨碍"] },
    { "name": "verify", "trans": ["证实， 查证， 证明"] },
    { "name": "collective", "trans": ["集体的，集合性的", "团体， 集体"] },
    { "name": "excursion", "trans": ["远足， 短途旅行"] },
    { "name": "steer", "trans": ["掌舵， 驾驶； 操纵"] },
    { "name": "event", "trans": ["事件， 大事， 事变"] },
    { "name": "academy", "trans": ["研究会， 学会； 专门学校"] },
    { "name": "undergo", "trans": ["经历， 经受， 忍受； 接受"] },
    { "name": "reward", "trans": ["报答，奖赏；报酬，酬金", "报答， 酬谢， 奖励"] },
    { "name": "include", "trans": ["包括， 包含"] },
    { "name": "sympathize", "trans": ["同情， 怜悯； 体谅； 赞同"] },
    { "name": "alongside", "trans": ["在旁边，沿着边，并排地", "在…旁边， 沿着…的边； 和…在一起"] },
    { "name": "swallow", "trans": ["燕子；吞，咽", "吞， 咽； 轻信， 轻易接受； 承受， 使不流露； 咽下口水"] },
    { "name": "agency", "trans": ["经办， 代理； 代理处； 专门行政部门"] },
    { "name": "coupon", "trans": ["礼券， 优惠券， 配给券； 联票； 票证"] },
    { "name": "railroad", "trans": ["铁路", "由铁路运输"] },
    { "name": "nucleus", "trans": ["核心； 核"] },
    { "name": "fascinating", "trans": ["迷人的， 有极大吸引力的"] },
    { "name": "abstract", "trans": ["抽象的，抽象派的", "摘要， 梗概； 抽象派艺术作品", "做…的摘要； 提取"] },
    { "name": "sting", "trans": ["刺，蜇，叮", "刺伤处，蜇伤处"] },
    { "name": "haste", "trans": ["急速， 急忙； 草率"] },
    { "name": "agenda", "trans": ["议事日程"] },
    { "name": "appearance", "trans": ["出现， 来到； 外观"] },
    { "name": "liquid", "trans": ["液体的； 清澈的； 流畅的", "液体"] },
    { "name": "culture", "trans": ["文化， 文明； 教养； 培养； 培养菌"] },
    { "name": "sleeve", "trans": ["袖子， 袖套"] },
    { "name": "devotion", "trans": ["献身， 奉献； 忠实； 热爱； 虔诚"] },
    { "name": "anonymous", "trans": ["匿名的； 无名的； 无特色的"] },
    { "name": "stir", "trans": ["搅动；拨动；激动；唤起", "搅拌， 搅动； 激动， 骚乱"] },
    { "name": "fisherman", "trans": ["渔民， 渔夫"] },
    { "name": "span", "trans": ["跨距，一段时间", "持续， 贯穿； 包括； 横跨， 跨越"] },
    { "name": "insult", "trans": ["侮辱， 凌辱", "侮辱， 凌辱"] },
    { "name": "existence", "trans": ["存在， 实在， 生存"] },
    { "name": "craft", "trans": ["工艺， 手艺； 船， 航空器"] },
    { "name": "butcher", "trans": ["肉商，肉贩，屠夫", "屠宰， 残杀"] },
    { "name": "poll", "trans": ["民意测验；政治选举，大选", "对…进行民意测验； 获得"] },
    { "name": "minority", "trans": ["少数； 少数民族"] },
    { "name": "pole", "trans": ["杆； 极， 磁极， 电极"] },
    { "name": "float", "trans": ["浮动， 漂浮； 飘动"] },
    { "name": "graceful", "trans": ["优美的， 优雅的； 得体的"] },
    { "name": "fourfold", "trans": ["四倍， 四重", "四倍的，四重的"] },
    { "name": "preferable", "trans": ["更可取的， 更好的， 更合意的"] },
    { "name": "reference", "trans": ["提到， 论及； 参考； 引文； 参考书目； 证明书， 证明人"] },
    { "name": "frog", "trans": ["蛙"] },
    { "name": "spite", "trans": ["恶意，怨恨", "刁难， 欺侮"] },
    { "name": "chamber", "trans": ["会议室， 会所； 房间； 腔， 室"] },
    { "name": "arrange", "trans": ["安排， 准备， 整理"] },
    { "name": "coarse", "trans": ["粗的， 粗糙的； 粗劣的； 粗俗的"] },
    { "name": "nephew", "trans": ["侄子， 外甥"] },
    { "name": "publicity", "trans": ["公众的注意， 名声； 宣传， 宣扬"] },
    { "name": "depart", "trans": ["离开， 起程， 出发"] },
    { "name": "exaggerate", "trans": ["夸大， 夸张"] },
    { "name": "imply", "trans": ["暗示， 意指"] },
    { "name": "capture", "trans": ["捕获， 俘获； 夺得"] },
    { "name": "commitment", "trans": ["承诺， 许诺， 保证； 信奉， 献身； 承担的义务"] },
    { "name": "spade", "trans": ["铲， 铁锹"] },
    { "name": "manufacture", "trans": ["制造，加工", "制造， 制造业"] },
    { "name": "sequence", "trans": ["连续， 接续， 一连串； 次序， 顺序"] },
    { "name": "collision", "trans": ["碰撞； 冲突， 抵触"] },
    { "name": "qualification", "trans": ["资格， 资格证明， 合格证书； 限制， 限定"] },
    { "name": "provision", "trans": ["供应； 准备； 条款， 规定； 给养， 口粮"] },
    { "name": "recruit", "trans": ["招募，吸收", "新兵； 新成员"] },
    { "name": "thrust", "trans": ["插；戳，刺", "戳， 刺； 要点， 要旨； 推力"] },
    { "name": "fame", "trans": ["名声， 名望"] },
    { "name": "replacement", "trans": ["代替， 取代； 更换， 调换； 替代的人"] },
    { "name": "mud", "trans": ["泥， 泥浆"] },
    { "name": "mug", "trans": ["大杯", "对…行凶抢劫"] },
    { "name": "pond", "trans": ["池塘"] },
    { "name": "arrival", "trans": ["到达， 到来； 到达者， 到达物"] },
    { "name": "scientific", "trans": ["科学的"] },
    { "name": "frequent", "trans": ["时常发生的，常见的", "常到， 常去"] },
    { "name": "according", "trans": ["相等的， 一致的， 依…而定的"] },
    { "name": "insure", "trans": ["为…投保； 确保"] },
    { "name": "powder", "trans": ["粉， 粉末"] },
    { "name": "membership", "trans": ["会员身份， 会籍； 全体会员， 会员数"] },
    { "name": "error", "trans": ["错误， 谬误， 差错"] },
    { "name": "platform", "trans": ["平台， 站台， 讲台； 纲领， 宣言"] },
    { "name": "network", "trans": ["网状物； 广播网， 电视网； 网络"] },
    { "name": "jealous", "trans": ["妒忌的， 猜疑的； 精心守护的"] },
    { "name": "rust", "trans": ["生锈， 氧化", "铁锈"] },
    { "name": "trace", "trans": ["痕迹；丝毫，少许", "跟踪； 追溯； 描摹"] },
    { "name": "Negro", "trans": ["黑人"] },
    { "name": "modernization", "trans": ["现代化"] },
    { "name": "grave", "trans": ["严重的； 严肃的", "坟墓"] },
    { "name": "criminology", "trans": ["犯罪学， 刑事学"] },
    { "name": "advantage", "trans": ["优点， 优势， 好处"] },
    { "name": "assistant", "trans": ["助理的， 辅助的", "助手，助理，助教"] },
    { "name": "inn", "trans": ["小旅店， 小酒店"] },
    { "name": "instead", "trans": ["作为替代； 却， 反而"] },
    { "name": "controversy", "trans": ["争论， 辩论"] },
    { "name": "command", "trans": ["命令， 指挥， 控制"] },
    { "name": "newsstand", "trans": ["报摊， 杂志摊"] },
    { "name": "worthless", "trans": ["无价值的， 无用的"] },
    { "name": "performance", "trans": ["演出； 执行， 完成； 工作情况， 表现； 工作性能"] },
    { "name": "dessert", "trans": ["甜食， 点心"] },
    { "name": "ambassador", "trans": ["大使， 使节， 派驻国际组织的代表"] },
    { "name": "anticipate", "trans": ["预料， 预期， 期望； 先于…行动； 提前使用"] },
    { "name": "variable", "trans": ["易变的", "变量"] },
    { "name": "temple", "trans": ["圣堂， 神殿， 庙宇"] },
    { "name": "growth", "trans": ["增长， 增长量； 生长， 生长物"] },
    { "name": "landscape", "trans": ["风景，景色；风景画；全景", "美化…的景观"] },
    { "name": "weave", "trans": ["织， 编"] },
    { "name": "carrot", "trans": ["胡萝卜"] },
    { "name": "normally", "trans": ["通常； 正常地"] },
    { "name": "exception", "trans": ["例外， 除外"] },
    { "name": "vague", "trans": ["模糊的， 含糊的"] },
    { "name": "temporary", "trans": ["暂时的， 临时的"] },
    { "name": "fare", "trans": ["进展", "车费，船费，票价"] },
    { "name": "desperate", "trans": ["拼死的， 不顾一切的； 绝望的； 极需要的"] },
    { "name": "leading", "trans": ["指导的， 带头的； 最主要的； 饰演主角的"] },
    { "name": "awful", "trans": ["令人不愉快的， 难过的； 非常的， 极大的"] },
    { "name": "accuracy", "trans": ["准确， 精确"] },
    { "name": "handy", "trans": ["手边的， 便于使用的"] },
    { "name": "urge", "trans": ["鼓励；推进，催促；竭力主张，强烈要求", "冲动， 强烈的欲望"] },
    { "name": "resolution", "trans": ["正式决定， 决议； 决心， 决意； 解决， 解答； 分辨率， 清晰度"] },
    { "name": "via", "trans": ["经过， 通过"] },
    { "name": "setting", "trans": ["环境， 背景， 布景； 调节， 设定的位置"] },
    { "name": "score", "trans": ["得， 给…打分； 画线于， 做记号于； 赢得", "得分，比数，成绩；二十"] },
    { "name": "quote", "trans": ["引用，援引", "引文， 引语； 报价， 牌价； 引号"] },
    { "name": "graduate", "trans": ["研究生的", "毕业", "毕业生；研究生"] },
    { "name": "famine", "trans": ["饥荒； 严重的缺乏"] },
    { "name": "gramme", "trans": ["gram的英式拼法"] },
    { "name": "absent", "trans": ["缺席的， 不在场的； 缺乏的， 不存在的； 心不在焉的"] },
    { "name": "rag", "trans": ["破布， 碎布； 破旧衣服"] },
    { "name": "visual", "trans": ["看得见的", "视觉资料"] },
    { "name": "understanding", "trans": ["体谅的， 宽容的， 通情达理的", "理解，理解力；谅解；协议；相互理解，融洽"] },
    { "name": "empire", "trans": ["帝国"] },
    { "name": "educate", "trans": ["教育， 培养， 训练"] },
    { "name": "fairy", "trans": ["小精灵， 小仙子"] },
    { "name": "ashamed", "trans": ["惭愧的， 羞耻的， 害臊的"] },
    { "name": "instruct", "trans": ["指示， 命令； 通知； 教授， 训练"] },
    { "name": "rat", "trans": ["鼠"] },
    { "name": "mayor", "trans": ["市长"] },
    { "name": "virtually", "trans": ["实际上， 事实上"] },
    { "name": "fairly", "trans": ["相当， 尚可， 还； 公平地"] },
    { "name": "handwriting", "trans": ["笔迹， 手迹； 书法"] },
    { "name": "merchant", "trans": ["商人"] },
    { "name": "raw", "trans": ["自然状态的， 未加工过的； 生的； 未经分析的， 原始的； 生疏无知的， 未经训练的； 露肉而刺痛的"] },
    { "name": "proportional", "trans": ["比例的， 成比例的"] },
    { "name": "economy", "trans": ["经济； 节约， 节省"] },
    { "name": "community", "trans": ["社区， 社会， 公社； 团体， 界； 群落"] },
    { "name": "packet", "trans": ["小包， 小盒"] },
    { "name": "version", "trans": ["译本； 说法； 改写本"] },
    { "name": "airport", "trans": ["机场， 航空港"] },
    { "name": "courtyard", "trans": ["庭院， 院子"] },
    { "name": "nationality", "trans": ["国籍； 民族"] },
    { "name": "selection", "trans": ["选择， 挑选； 被挑选出来的人， 精选品； 可供选择的东西"] },
    { "name": "institute", "trans": ["研究所，学院", "建立， 设立"] },
    { "name": "aboard", "trans": ["在船上， 上船"] },
    { "name": "endure", "trans": ["忍受， 容忍； 持久， 持续"] },
    { "name": "detail", "trans": ["细节，枝节；零件", "详细说明"] },
    { "name": "shortly", "trans": ["立刻， 不久； 不耐烦地； 简短地"] },
    { "name": "hobby", "trans": ["业余爱好， 癖好"] },
    { "name": "appropriate", "trans": ["适当的， 恰当的"] },
    { "name": "providing", "trans": ["倘若"] },
    { "name": "fate", "trans": ["命运， 天数"] },
    { "name": "throat", "trans": ["咽喉， 喉咙； 嗓音"] },
    { "name": "utility", "trans": ["效用， 有用， 实用； 公用事业"] },
    { "name": "immediately", "trans": ["立即， 马上； 直接地， 紧接地"] },
    { "name": "manual", "trans": ["用手的；手工做的", "手册， 指南"] },
    { "name": "measurement", "trans": ["衡量， 测量； 尺寸， 大小"] },
    { "name": "optimistic", "trans": ["乐观的， 乐观主义的"] },
    { "name": "vocabulary", "trans": ["词汇表， 词汇， 语汇"] },
    { "name": "retire", "trans": ["退休， 退役； 退下， 退出， 撤退； 就寝"] },
    { "name": "aspect", "trans": ["方面； 朝向， 方向； 样子， 外表"] },
    { "name": "particle", "trans": ["粒子， 微粒； 小品词， 语助词"] },
    { "name": "aggressive", "trans": ["侵略的， 好斗的； 敢做敢为的； 有进取心的"] },
    { "name": "behavior", "trans": ["行为， 举止， 表现"] },
    { "name": "musician", "trans": ["音乐家， 乐师"] },
    { "name": "minimize", "trans": ["将…减少； 最小化"] },
    { "name": "descend", "trans": ["下来， 下降； 下倾"] },
    { "name": "saint", "trans": ["圣徒； 圣人， 道德高尚的人； [用于人名或地名等前]圣"] },
    { "name": "density", "trans": ["密集， 稠密； 密度"] },
    { "name": "logical", "trans": ["逻辑的， 合乎常理的"] },
    { "name": "oblige", "trans": ["迫使； 施恩于， 帮…的忙； 使感激"] },
    { "name": "slam", "trans": ["砰地关上；猛力拉，砰地放下；猛烈抨击", "砰的一声"] },
    { "name": "characterize", "trans": ["成为…的特征， 以…为特征； 描绘的特性， 描述"] },
    { "name": "swing", "trans": ["摇摆，回转；突然转向", "摇摆； 秋千"] },
    { "name": "ridiculous", "trans": ["荒谬的， 可笑的"] },
    { "name": "handbag", "trans": ["手提包"] },
    { "name": "invasion", "trans": ["入侵， 侵略， 侵犯"] },
    { "name": "spin", "trans": ["旋转；晕眩；纺，织；绞干，甩干", "旋转， 自转"] },
    { "name": "severe", "trans": ["严重的； 严厉的， 严格的； 严峻的， 艰难的； 朴素的， 不加装饰的"] },
    { "name": "emerge", "trans": ["出现， 涌现， 冒出"] },
    { "name": "shallow", "trans": ["浅的；浅薄的", "浅滩， 浅水处"] },
    { "name": "lease", "trans": ["租约，租契", "出租； 租得， 租有"] },
    { "name": "exploit", "trans": ["剥削； 利用； 开拓", "业绩， 功绩"] },
    { "name": "slap", "trans": ["掴，掌击，拍；啪的一声放", "掴， 掌击， 拍"] },
    { "name": "spit", "trans": ["吐唾沫，吐出", "唾沫， 唾液"] },
    { "name": "scout", "trans": ["侦察， 寻找", "侦察员，侦察机，侦察舰；童子军"] },
    { "name": "anniversary", "trans": ["周年纪念日"] },
    { "name": "register", "trans": ["登记， 注册； 指示， 自动记下； 流露； 表示， 表达； 把挂号", "登记；登记表"] },
    { "name": "restless", "trans": ["焦躁不安的； 静不下来的， 运动不止的"] },
    { "name": "persuasive", "trans": ["劝诱的； 有说服力的"] },
    { "name": "fleet", "trans": ["舰队， 船队"] },
    { "name": "communicate", "trans": ["通讯； 交际， 交流； 连接， 相通； 传达， 传播； 传染"] },
    { "name": "evidence", "trans": ["根据， 证据； 证人"] },
    { "name": "saddle", "trans": ["鞍，鞍状物；车座", "给…装鞍； 使承担任务"] },
    { "name": "rural", "trans": ["农村的， 乡村的"] },
    { "name": "official", "trans": ["官员的；官方的，正式的", "官员， 行政人员， 高级人员"] },
    { "name": "historical", "trans": ["历史的， 有关历史的"] },
    { "name": "repetition", "trans": ["重复， 反复"] },
    { "name": "fortunately", "trans": ["幸运地； 幸亏"] },
    { "name": "acid", "trans": ["酸的， 酸性的", "酸"] },
    { "name": "transmission", "trans": ["传播， 发射； 传送， 传递； 传染"] },
    { "name": "shortage", "trans": ["不足， 缺少"] },
    { "name": "discrimination", "trans": ["区别， 辨别； 识别力； 差别对待， 歧视"] },
    { "name": "female", "trans": ["雌的； 女的", "雌性动物；女子"] },
    { "name": "microphone", "trans": ["扩音器， 话筒， 麦克风"] },
    { "name": "swift", "trans": ["快的； 反应快的； 敏捷的"] },
    { "name": "surrender", "trans": ["交出， 放弃； 投降"] },
    { "name": "faint", "trans": ["不清楚的，模糊的", "晕倒，昏厥", "昏厥"] },
    { "name": "world-wide", "trans": ["遍及全球的"] },
    { "name": "minister", "trans": ["部长， 大臣； 公使， 外交使节； 牧师"] },
    { "name": "pose", "trans": ["摆姿势；假装，装腔作势", "使摆好姿势；造成，引起；提出，陈述", "样子， 姿势"] },
    { "name": "remedy", "trans": ["补救办法，纠正办法；药品；治疗法", "补救， 纠正； 医治， 治疗"] },
    { "name": "jail", "trans": ["监狱，看守所", "监禁， 拘留； 塞满； 卡住； 干扰"] },
    { "name": "consent", "trans": ["同意", "同意，赞成"] },
    { "name": "insist", "trans": ["坚持， 坚决认为； 坚持要求"] },
    { "name": "typewriter", "trans": ["打字机"] },
    { "name": "helpless", "trans": ["无助的； 无能的； 无法抗拒的"] },
    { "name": "respect", "trans": ["尊敬；尊重；问候；方面", "尊敬； 尊重"] },
    { "name": "volume", "trans": ["卷， 册， 书卷； 容积， 体积； 音量， 响度"] },
    { "name": "retreat", "trans": ["退却， 撤退； 退避， 退缩"] },
    { "name": "ache", "trans": ["疼痛；渴望", "疼痛"] },
    { "name": "mutual", "trans": ["相互的； 共同的"] },
    { "name": "shell", "trans": ["壳，贝壳；外壳，框架；炮弹", "剥…的壳； 炮击"] },
    { "name": "guideline", "trans": ["指导方针， 准则"] },
    { "name": "loose", "trans": ["松的； 不精确的， 不严密的； 散漫的"] },
    { "name": "therefore", "trans": ["因此， 所以"] },
    { "name": "exchange", "trans": ["交换； 交流； 兑换"] },
    { "name": "hesitate", "trans": ["犹豫， 踌躇； 含糊； 不情愿"] },
    { "name": "rifle", "trans": ["来复枪， 步枪"] },
    { "name": "private", "trans": ["私人的；秘密的；私下的；私营的", "士兵， 列兵"] },
    { "name": "baggage", "trans": ["行李"] },
    { "name": "companion", "trans": ["同伴， 共事者； 伴侣"] },
    { "name": "breakdown", "trans": ["垮， 衰竭； 损坏， 故障； 倒塌"] },
    { "name": "paste", "trans": ["糊，糨糊", "粘， 贴"] },
    { "name": "overtake", "trans": ["追上， 超过； 突然降临于， 意外地碰上"] },
    { "name": "jeans", "trans": ["工装裤， 牛仔裤"] },
    { "name": "overnight", "trans": ["整夜， 通宵； 一夜间， 突然", "一整夜的； 一夜间的， 突然的"] },
    { "name": "behave", "trans": ["表现， 举止； 运转， 作出反应"] },
    { "name": "stretch", "trans": ["伸展，延续；伸长，拉长；倾注全力；紧张", "一段时间， 一段路程， 连绵的一片； 伸展， 延伸"] },
    { "name": "undo", "trans": ["解开， 打开； 取消， 撤销"] },
    { "name": "accomplish", "trans": ["达到； 完成"] },
    { "name": "laser", "trans": ["激光"] },
    { "name": "mixture", "trans": ["混合， 混合物"] },
    { "name": "engine", "trans": ["引擎， 发动机； 机车"] },
    { "name": "responsible", "trans": ["需负责任的， 承担责任的； 有责任感的， 负责可靠的； 责任重大的， 重要的"] },
    { "name": "scope", "trans": ["范围； 余地， 机会"] },
    { "name": "democratic", "trans": ["民主的， 民主政体的"] },
    { "name": "medal", "trans": ["奖牌， 奖章， 勋章"] },
    { "name": "satellite", "trans": ["卫星， 人造卫星"] },
    { "name": "bullet", "trans": ["枪弹， 子弹， 弹丸"] },
    { "name": "recorder", "trans": ["录音机， 录像机； 记录装置， 记录仪"] },
    { "name": "identify", "trans": ["一致", "认出，鉴定；把…等同于"] },
    { "name": "embrace", "trans": ["拥抱，怀抱", "拥抱； 包括； 包围"] },
    { "name": "nitrogen", "trans": ["氮"] },
    { "name": "pour", "trans": ["灌， 倒； 倾泻"] },
    { "name": "label", "trans": ["标签；标记，符号", "贴标签于； 把…称为"] },
    { "name": "improvement", "trans": ["改进， 改善； 改进处"] },
    { "name": "slippery", "trans": ["滑的； 狡猾的"] },
    { "name": "scissors", "trans": ["剪刀"] },
    { "name": "significance", "trans": ["意义， 含义； 重要性， 重大"] },
    { "name": "modify", "trans": ["更改， 修改； 修饰"] },
    { "name": "horizon", "trans": ["地平线； 眼界， 见识"] },
    { "name": "environment", "trans": ["环境， 外界； 围绕"] },
    { "name": "airplane", "trans": ["飞机"] },
    { "name": "pledge", "trans": ["保证，誓言", "保证， 许诺"] },
    { "name": "progressive", "trans": ["前进的； 渐进的； 进行式的"] },
    { "name": "reservoir", "trans": ["水库， 蓄水池； 储藏， 汇集"] },
    { "name": "deliberate", "trans": ["故意的， 蓄意的； 慎重的， 深思熟虑的", "仔细考虑， 思考"] },
    { "name": "swear", "trans": ["宣誓； 咒骂"] },
    { "name": "atmosphere", "trans": ["大气， 空气； 气氛， 环境"] },
    { "name": "decorate", "trans": ["装饰， 装潢， 修饰"] },
    { "name": "copyright", "trans": ["版权"] },
    { "name": "biotechnology", "trans": ["生物工艺学"] },
    { "name": "career", "trans": ["生涯， 职业， 经历"] },
    { "name": "occupation", "trans": ["占领， 占据； 职业； 从事的活动， 消遣"] },
    { "name": "tendency", "trans": ["趋向， 趋势， 倾向"] },
    { "name": "release", "trans": ["释放， 排放； 解除， 解脱； 放开， 松开； 发布； 发行"] },
    { "name": "charter", "trans": ["包租的， 租用的", "宪章，共同纲领；特许状，许可证；包租", "包，租"] },
    { "name": "slim", "trans": ["苗条的；薄的；少的", "减轻体重， 变苗条"] },
    { "name": "arithmetic", "trans": ["算术， 四则运算"] },
    { "name": "rib", "trans": ["肋骨"] },
    { "name": "faulty", "trans": ["有错误的， 有缺点的"] },
    { "name": "liberate", "trans": ["解放， 释放"] },
    { "name": "rid", "trans": ["使摆脱， 解除…的负担， 从…中清除"] },
    { "name": "variation", "trans": ["变化， 变动； 变异； 变奏"] },
    { "name": "moral", "trans": ["道德的，有道德的", "品行； 道德规范； 寓意"] },
    { "name": "defect", "trans": ["变节， 叛变", "缺点， 缺陷， 欠缺"] },
    { "name": "reinforce", "trans": ["增强， 加强， 增援"] },
    { "name": "explode", "trans": ["爆炸； 激增； 发怒", "使爆炸"] },
    { "name": "casual", "trans": ["偶然的； 非正式的； 临时的， 不定期的； 漠不关心的， 冷淡的"] },
    { "name": "decline", "trans": ["下降， 减少； 衰退， 衰落； 谢绝， 拒绝", "下降，减少；衰退"] },
    { "name": "property", "trans": ["财产， 所有物； 房产； 物业； 性质， 性能"] },
    { "name": "succeeding", "trans": ["以后的， 随后的"] },
    { "name": "brave", "trans": ["勇敢的； 华丽的"] },
    { "name": "similar", "trans": ["相似的， 类似的"] },
    { "name": "viewpoint", "trans": ["观点， 看法， 见解"] },
    { "name": "anyhow", "trans": ["无论如何， 不管怎么说； 随随便便地， 杂乱无章地"] },
    { "name": "specify", "trans": ["指定； 详细说明"] },
    { "name": "whistle", "trans": ["吹口哨， 鸣笛声； 呼啸而过", "口哨；呼啸而过"] },
    { "name": "handle", "trans": ["柄，把手", "拿， 触； 操作， 处理"] },
    { "name": "forth", "trans": ["向前； 向外， 往外"] },
    {
      "name": "tough",
      "trans": ["困难的， 难对付的； 坚强的， 能吃苦耐劳的； 健壮的； 坚硬的， 严格的； 老的， 硬的； 粗暴的， 充满暴力的"]
    },
    { "name": "crossing", "trans": ["人行横道， 交叉口； 横渡"] },
    { "name": "script", "trans": ["剧本， 广播稿； 书写用的字母； 笔迹， 手迹"] },
    { "name": "submerge", "trans": ["淹没， 潜入水中"] },
    { "name": "system", "trans": ["系统； 制度， 体制； 方法， 做法； 身体"] },
    { "name": "compose", "trans": ["组成， 构成； 创作， 为…谱曲； 使平静， 使镇静"] },
    { "name": "spot", "trans": ["点，斑点；地点，处所；少量", "认出， 发现； 玷污"] },
    { "name": "roller", "trans": ["滚筒， 滚轴"] },
    { "name": "partial", "trans": ["部分的； 不公平的； 偏爱的， 偏袒的"] },
    { "name": "aid", "trans": ["帮助； 救护； 助手； 辅助手段"] },
    { "name": "tone", "trans": ["腔调， 语气； 音调； 风格， 气度； 色调， 明暗"] },
    { "name": "confident", "trans": ["确信的， 肯定的； 自信的"] },
    { "name": "retain", "trans": ["保留， 保持"] },
    { "name": "swell", "trans": ["膨胀，隆起；增长，增强，增多，扩大", "波浪起伏； 鼓起， 隆起； 增强"] },
    { "name": "skilled", "trans": ["有技能的； 熟练的； 需要技能的"] },
    { "name": "local", "trans": ["地方性的； 本地的； 局部的； 狭隘的"] },
    { "name": "remind", "trans": ["提醒， 使想起"] },
    { "name": "crude", "trans": ["简陋的， 天然的； 粗鲁的， 粗俗的"] },
    { "name": "manufacturer", "trans": ["制造商； 制造厂"] },
    { "name": "crew", "trans": ["全体船员； 一队工作人员"] },
    { "name": "valid", "trans": ["有效的； 正当的； 有根据的， 有理的"] },
    { "name": "withstand", "trans": ["抵挡， 反抗"] },
    { "name": "defeat", "trans": ["战胜， 挫败"] },
    { "name": "vacuum", "trans": ["用吸尘器清扫", "真空；真空吸尘器"] },
    { "name": "encourage", "trans": ["鼓励， 怂恿； 促进， 助长， 激发"] },
    { "name": "bubble", "trans": ["冒泡； 沸腾", "泡"] },
    { "name": "era", "trans": ["时代， 年代， 纪元"] },
    { "name": "yield", "trans": ["出产；让出，放弃；屈服，顺从；倒塌，垮掉", "产量"] },
    { "name": "electronic", "trans": ["电子的", "电子学； 电子设备"] },
    { "name": "vacation", "trans": ["假期， 休假"] },
    { "name": "outcome", "trans": ["结果"] },
    { "name": "elegant", "trans": ["优美的， 文雅的， 讲究的； 简练的， 简洁的"] },
    { "name": "tender", "trans": ["嫩的；脆弱的；疼痛的，一触即痛的；温柔的", "提出", "投标"] },
    { "name": "curriculum", "trans": ["课程， 全部课程"] },
    { "name": "meantime", "trans": ["其间， 同时"] },
    { "name": "powerful", "trans": ["强大的， 有力的， 有权的； 强壮的， 强健的"] },
    { "name": "slip", "trans": ["滑跤，滑落，溜；下降，跌落；悄悄放进", "疏漏， 差错"] },
    { "name": "species", "trans": ["种， 类"] },
    { "name": "anchor", "trans": ["抛锚， 停泊； 把…系住， 使固定； 担任主持人； 固定， 扎根", "锚；给人安全感的物"] },
    { "name": "preceding", "trans": ["在前的， 在先的， 前面的"] },
    { "name": "cite", "trans": ["引用， 引证； 传唤， 传讯； 表彰， 嘉奖"] },
    { "name": "logic", "trans": ["逻辑， 推理， 逻辑学"] },
    { "name": "acquaintance", "trans": ["认识， 了解； 熟人"] },
    { "name": "royal", "trans": ["王室的， 皇家的"] },
    { "name": "internship", "trans": ["实习生身份； 实习医师期"] },
    { "name": "shed", "trans": ["脱落，脱去；流出，流下；发出，散发", "棚， 小屋， 货棚"] },
    { "name": "eyesight", "trans": ["视力， 目力"] },
    { "name": "precision", "trans": ["精确， 精密"] },
    { "name": "glove", "trans": ["手套"] },
    { "name": "screen", "trans": ["屏幕，银幕；屏风；帘，纱窗", "掩蔽， 遮蔽； 放映， 播放； 审查， 甄别"] },
    { "name": "naked", "trans": ["裸体的； 无遮蔽的"] },
    { "name": "trial", "trans": ["试， 试验； 审判； 讨厌的人"] },
    { "name": "correspond", "trans": ["相符合， 相当； 通信"] },
    { "name": "spur", "trans": ["刺激物", "刺激， 鞭策， 激励"] },
    { "name": "perception", "trans": ["感知， 觉察； 认识， 观念， 看法"] },
    { "name": "rear", "trans": ["后部的，背后的", "后部，背面；后方", "抚养， 饲养； 种植"] },
    { "name": "amongst", "trans": ["在…之中"] },
    { "name": "undertake", "trans": ["从事； 承担； 同意； 保证"] },
    { "name": "insect", "trans": ["昆虫， 虫"] },
    { "name": "bureau", "trans": ["局， 司， 处， 社， 所"] },
    { "name": "moist", "trans": ["湿润的， 潮湿的"] },
    { "name": "cable", "trans": ["缆， 索， 电缆； 电报"] },
    { "name": "qualify", "trans": ["胜任， 具有资格， 合格； 限定"] },
    { "name": "intense", "trans": ["强烈的； 紧张的； 认真的； 热情的"] },
    { "name": "pregnant", "trans": ["怀孕的， 妊娠的"] },
    { "name": "border", "trans": ["边，边缘，边界", "给…加上边， 围； 邻接； 与…接壤"] },
    { "name": "estate", "trans": ["土地， 住宅区， 地产； 财产， 遗产； 庄园， 种植园"] },
    { "name": "applicable", "trans": ["能应用的； 合适的， 适当的"] },
    { "name": "admission", "trans": ["允许进入； 承认； 入场费， 入场券"] },
    { "name": "brass", "trans": ["黄铜， 黄铜器， 铜管乐器"] },
    { "name": "expense", "trans": ["花费， 消费； 费用， 开支； 业务费用"] },
    { "name": "rumour", "trans": ["谣传， 谣言"] },
    { "name": "contemporary", "trans": ["当代的；同时代的", "同代人， 当代人"] },
    { "name": "gear", "trans": ["齿轮，传动装置，排挡；用具，设备；衣服", "使适应， 使适合"] },
    { "name": "boundary", "trans": ["分界线， 边界"] },
    { "name": "rob", "trans": ["抢劫， 盗窃"] },
    { "name": "elderly", "trans": ["较老的，年长的", "到了晚年的人， 老年人"] },
    { "name": "financial", "trans": ["财政的， 金融的"] },
    { "name": "rod", "trans": ["杆， 棒"] },
    { "name": "dumb", "trans": ["哑的； 无言的； 说不出话的"] },
    { "name": "respective", "trans": ["各自的， 各个的， 分别的"] },
    { "name": "media", "trans": ["新闻媒介， 传播媒介"] },
    { "name": "favour", "trans": ["好感；赞同；恩惠", "赞同； 喜爱， 偏爱； 有利于"] },
    { "name": "pollute", "trans": ["弄脏， 污染； 腐蚀"] },
    { "name": "harden", "trans": ["变硬； 变得坚强"] },
    { "name": "eve", "trans": ["前夜， 前夕， 前一刻"] },
    { "name": "pronoun", "trans": ["代词"] },
    { "name": "define", "trans": ["给…下定义， 限定"] },
    { "name": "dictate", "trans": ["口授； 命令， 规定， 要求", "命令， 指挥"] },
    { "name": "construction", "trans": ["建造； 建筑物； 结构"] },
    { "name": "predict", "trans": ["预言， 预告， 预测"] },
    { "name": "dump", "trans": ["倾卸，倾倒；倾销", "垃圾场"] },
    { "name": "render", "trans": ["使得， 致使； 给予， 提供； 翻译"] },
    { "name": "universal", "trans": ["普遍的； 通用的； 全世界的"] },
    { "name": "chemist", "trans": ["化学家， 药剂师"] },
    { "name": "frontier", "trans": ["边境， 边疆； 新领域"] },
    { "name": "abroad", "trans": ["到国外； 在传播， 在流传"] },
    { "name": "radical", "trans": ["根本的，基本的；激进的，激进派的", "激进分子"] },
    { "name": "relieve", "trans": ["使轻松， 使宽慰， 缓解； 使得到调剂； 接替"] },
    { "name": "drawer", "trans": ["抽屉"] },
    { "name": "requirement", "trans": ["要求； 必要条件； 需要， 需要的东西"] },
    { "name": "transport", "trans": ["运输", "运输， 运输工具"] },
    { "name": "employment", "trans": ["工作； 雇用； 使用"] },
    { "name": "specific", "trans": ["特有的；具体的，明确的", "详情， 细节"] },
    { "name": "application", "trans": ["申请， 申请书； 施用， 涂抹； 应用， 实施； 实用性"] },
    { "name": "engage", "trans": ["使从事； 聘用； 吸引； 占用； 使订婚"] },
    { "name": "acre", "trans": ["英亩"] },
    { "name": "whisper", "trans": ["低声地讲", "低语， 耳语"] },
    { "name": "appetite", "trans": ["食欲， 胃口； 欲望"] },
    { "name": "semester", "trans": ["学期"] },
    { "name": "strategy", "trans": ["战略， 策略"] },
    { "name": "dull", "trans": ["乏味的， 单调的； 晦暗的， 阴沉的； 低沉的； 笨的； 钝的"] },
    { "name": "collapse", "trans": ["倒坍；塌下", "突然失败；突然瓦解"] },
    { "name": "clumsy", "trans": ["笨拙的， 愚笨的； 粗陋的； 不得体的"] },
    { "name": "territory", "trans": ["领土， 版图； 领域"] },
    { "name": "concession", "trans": ["让步， 妥协； 特许， 特许权； 承认， 认可"] },
    { "name": "accurate", "trans": ["准确的， 正确无误的"] },
    { "name": "maintain", "trans": ["维持； 维修， 保养； 主张； 赡养"] },
    { "name": "shift", "trans": ["移动，转移；改变，转变", "转换， 转变； 轮班"] },
    { "name": "echo", "trans": ["重复， 模仿； 发出回声", "回声；反响，共鸣"] },
    { "name": "erect", "trans": ["竖直的， 垂直的", "建造；使竖立"] },
    { "name": "bargain", "trans": ["讨价还价", "特价商品；协议，交易"] },
    { "name": "professional", "trans": ["职业的，专业的，专门的", "自由职业者， 专业人员"] },
    { "name": "elective", "trans": ["选举的，有选举权的；可任意选择的", "选修课程"] },
    { "name": "accusation", "trans": ["谴责； 【律】 指控"] },
    { "name": "protein", "trans": ["蛋白质"] },
    { "name": "annual", "trans": ["每年的，一年一次的", "年报， 年鉴； 一年生的植物"] },
    { "name": "exclusive", "trans": ["奢华的，高级的；独有的，独享的；排斥的，排他的；不包括…的，不把…计算在内的", "独家新闻"] },
    { "name": "jar", "trans": ["感到不快； 震动， 摇动", "罐子，坛子，广口瓶"] },
    { "name": "poverty", "trans": ["贫穷， 贫困"] },
    { "name": "resort", "trans": ["求助，凭借，诉诸", "求助， 凭借， 诉诸； 求助的对象， 采用的手段； 常去之地， 胜地"] },
    { "name": "goodness", "trans": ["天哪", "善良， 美德， 好意"] },
    { "name": "lightning", "trans": ["闪电， 电光"] },
    { "name": "jaw", "trans": ["颌， 颚"] },
    { "name": "margin", "trans": ["页边空白； 差数， 差额； 余地； 边缘"] },
    { "name": "remain", "trans": ["剩下，余留；保持；仍然是", "残余； 残骸； 遗迹"] },
    { "name": "amaze", "trans": ["使惊奇， 使惊愕"] },
    { "name": "refine", "trans": ["精炼， 精制， 提纯； 使优美， 使完善"] },
    { "name": "mount", "trans": ["登上；发起；安放；增加，加剧", "山， 峰"] },
    { "name": "demand", "trans": ["要求， 需要； 查问"] },
    { "name": "mystery", "trans": ["神秘； 神秘的人"] },
    { "name": "atomic", "trans": ["原子的， 原子能的， 原子武器的"] },
    { "name": "deposit", "trans": ["使沉淀；存放，储蓄", "定金， 押金； 存款； 矿藏"] },
    { "name": "centimetre", "trans": ["公分， 厘米"] },
    { "name": "telescope", "trans": ["望远镜"] },
    { "name": "rotten", "trans": ["腐烂的； 令人不愉快的； 糟糕的"] },
    { "name": "skillful", "trans": ["灵巧的， 娴熟的"] },
    { "name": "flash", "trans": ["闪， 闪烁； 飞驰", "闪光；闪光灯"] },
    { "name": "accuse", "trans": ["指责， 归咎于"] },
    { "name": "housing", "trans": ["房屋， 住宅； 住房建筑， 住房供给； 外壳， 外罩"] },
    { "name": "devil", "trans": ["魔鬼， 恶魔； 家伙， 人"] },
    { "name": "guarantee", "trans": ["保证；保证书；担保物", "保证， 担保"] },
    { "name": "recommend", "trans": ["推荐， 举荐； 劝告， 建议； 使成为可取； 使受欢迎"] },
    { "name": "acquire", "trans": ["取得， 获得， 学到"] },
    { "name": "fertile", "trans": ["肥沃的； 多产的； 丰富的"] },
    { "name": "automobile", "trans": ["汽车， 机动车"] },
    { "name": "notion", "trans": ["概念， 观念； 意图， 想法， 念头"] },
    { "name": "diversity", "trans": ["多样性； 差异； 不同点"] },
    { "name": "helicopter", "trans": ["直升机"] },
    { "name": "tractor", "trans": ["拖拉机， 牵引车"] },
    { "name": "patience", "trans": ["忍耐， 耐心"] },
    { "name": "grace", "trans": ["优美，优雅；风度，魅力；宽限，缓期", "使优美"] },
    { "name": "advertisement", "trans": ["广告， 公告， 登广告； 广告活动， 宣传"] },
    { "name": "ripe", "trans": ["熟的； 时机成熟的"] },
    { "name": "attribute", "trans": ["把…归因于", "属性"] },
    { "name": "mechanism", "trans": ["机械装置； 机制， 机理； 办法， 途径"] },
    { "name": "detection", "trans": ["察觉， 发觉； 侦查， 探测"] },
    { "name": "triumph", "trans": ["成功", "凯旋，胜利"] },
    { "name": "vice", "trans": ["罪恶； 恶习， 缺点； 虎钳"] },
    { "name": "horror", "trans": ["恐怖； 憎恶； 令人恐怖的事物"] },
    { "name": "poison", "trans": ["毒，毒药", "毒害"] },
    { "name": "thoughtful", "trans": ["沉思的； 体贴的"] },
    { "name": "gram", "trans": ["克"] },
    { "name": "empower", "trans": ["授权于； 使能够"] },
    { "name": "riot", "trans": ["聚众闹事", "暴乱，骚乱；极度丰富", "把浪费在放荡的生活上"] },
    { "name": "arouse", "trans": ["引起； 唤起， 唤醒"] },
    { "name": "nest", "trans": ["筑巢", "巢，窝，穴"] },
    { "name": "tour", "trans": ["旅行， 游历"] },
    { "name": "calm", "trans": ["静的，平静的", "平静， 镇静"] },
    { "name": "boring", "trans": ["令人厌烦的， 乏味的， 无聊的"] },
    {
      "name": "classic",
      "trans": ["最优秀的，典型的，标准的；传统式样的，典雅的", "文学名著， 经典作品， 杰作； 优秀的典范； 古典文学， 古典语文研究"]
    },
    { "name": "ownership", "trans": ["所有， 所有制"] },
    { "name": "metric", "trans": ["公制的， 米制的"] },
    { "name": "absolute", "trans": ["十足的， 地道的； 绝对的， 完全的； 不受任何限制的"] },
    { "name": "ash", "trans": ["灰， 灰末； 骨灰"] },
    { "name": "describe", "trans": ["形容； 描写； 画出"] },
    { "name": "suck", "trans": ["吸， 吮， 啜； 吸收"] },
    { "name": "grab", "trans": ["抓取，攫取；赶紧做；抓住；抓，夺", "抓， 夺"] },
    { "name": "rub", "trans": ["擦， 摩擦"] },
    { "name": "presently", "trans": ["不久， 一会儿； 现在， 目前"] },
    { "name": "wealthy", "trans": ["富有的， 富裕的"] },
    { "name": "rug", "trans": ["地毯"] },
    { "name": "jazz", "trans": ["爵士音乐， 爵士舞曲"] },
    { "name": "dusk", "trans": ["薄暮， 黄昏； 幽暗"] },
    { "name": "administration", "trans": ["管理； 管理部门， 行政机关； 实行， 执行"] },
    { "name": "occasional", "trans": ["偶尔的， 间或发生的"] },
    { "name": "boast", "trans": ["自夸；自豪", "自夸，吹嘘；以拥有…而自豪", "自吹自擂， 自夸的话"] },
    { "name": "operator", "trans": ["操作人员； 话务员， 报务员"] },
    { "name": "debate", "trans": ["争论， 辩论"] },
    { "name": "spacecraft", "trans": ["航天器， 宇宙飞船"] },
    { "name": "furniture", "trans": ["家具"] },
    { "name": "segment", "trans": ["部分， 片段； 瓣"] },
    { "name": "stripe", "trans": ["条纹"] },
    { "name": "jet", "trans": ["乘喷气式飞机", "喷气式飞机；喷嘴；喷射"] },
    { "name": "helpful", "trans": ["给予帮助的； 有用的"] },
    { "name": "statistic", "trans": ["统计数值， 统计资料； 统计学"] },
    { "name": "attractive", "trans": ["有吸引力的， 引起注意的"] },
    { "name": "superb", "trans": ["极好的； 高质量的"] },
    { "name": "mold", "trans": ["霉，霉菌；模型，铸模；性格", "用模子制作， 塑造； 使形成， 影响…的形成"] },
    { "name": "engineering", "trans": ["工程， 工程学"] },
    { "name": "significant", "trans": ["相当数量的； 重要的， 意义重大的， 意味深长的"] },
    { "name": "bold", "trans": ["勇敢的； 鲁莽的； 粗体的， 黑体的； 醒目的"] },
    { "name": "bleed", "trans": ["出血， 流血； 泌脂", "勒索…的钱"] },
    { "name": "delicious", "trans": ["美味的； 芬芳的， 怡人的； 有趣的"] },
    { "name": "catalogue", "trans": ["目录", "为…编目录，把…列入目录中"] },
    { "name": "whatever", "trans": ["[用于否定句中以加强语气]任何； 无论什么"] },
    { "name": "decrease", "trans": ["减少", "减少"] },
    { "name": "volunteer", "trans": ["自愿提供； 自愿， 志愿", "志愿者；志愿兵"] },
    { "name": "museum", "trans": ["博物馆"] },
    { "name": "ignorance", "trans": ["无知， 愚昧"] },
    { "name": "senate", "trans": ["参议院， 上院"] },
    { "name": "trumpet", "trans": ["喇叭，小号", "大声宣告， 鼓吹"] },
    { "name": "bolt", "trans": ["螺栓，插销", "闩门"] },
    { "name": "string", "trans": ["连成一串； 排成一行前进", "线，细绳；一串，一行", "缚，扎，挂；串起，穿；使排成一列；伸展，拉直"] },
    { "name": "import", "trans": ["输入， 进口", "输入， 进口； 进口商品； 意义， 重要性"] },
    { "name": "occupy", "trans": ["占领， 占， 占有"] },
    { "name": "submit", "trans": ["屈从， 听从， 服从", "呈送，提交；主张，建议；使服从，使降服"] },
    { "name": "mood", "trans": ["心情， 情绪； 语气"] },
    { "name": "boom", "trans": ["激增， 繁荣， 迅速发展； 发出隆隆声", "激增，繁荣，迅速发展；隆隆声，嗡嗡声"] },
    { "name": "absence", "trans": ["缺席， 不在； 缺席的时间， 外出期； 缺乏， 不存在"] },
    { "name": "attract", "trans": ["吸引， 引起…注意"] },
    { "name": "edition", "trans": ["版， 版本， 版次"] },
    { "name": "simplicity", "trans": ["简单， 简易； 朴素"] },
    { "name": "cautious", "trans": ["十分小心的， 谨慎的"] },
    { "name": "disappear", "trans": ["不见， 失踪， 消失"] },
    { "name": "summit", "trans": ["最高点， 峰顶； 最高级会议"] },
    { "name": "recession", "trans": ["衰退， 衰退期"] },
    { "name": "conclusion", "trans": ["结论， 推论； 结尾； 缔结， 议定"] },
    { "name": "typist", "trans": ["打字员"] },
    { "name": "durable", "trans": ["耐久的， 耐用的"] },
    { "name": "negative", "trans": ["否定的，消极的；负的；阴性的", "负片， 底片； 负数"] },
    { "name": "impose", "trans": ["利用， 占便宜； 欺骗", "把…强加于；征"] },
    { "name": "ally", "trans": ["结盟， 联合", "同盟国， 同盟者； 支持者"] },
    { "name": "statement", "trans": ["陈述， 声明； 结算单， 报表"] },
    { "name": "hence", "trans": ["因此， 所以； 今后"] },
    { "name": "memorial", "trans": ["纪念的，悼念的", "纪念碑， 纪念堂， 纪念仪式"] },
    { "name": "factor", "trans": ["因素； 因子； 系数"] },
    { "name": "boot", "trans": ["靴子， 长筒靴； 行李箱； 解雇"] },
    { "name": "pinch", "trans": ["捏，拧", "撮， 微量"] },
    { "name": "delivery", "trans": ["投递， 交付； 分娩； 讲话方式； 投递的邮件"] },
    { "name": "rack", "trans": ["挂架，搁架", "使痛苦， 折磨； 尽力使用"] },
    { "name": "election", "trans": ["选举； 选择权； 当选"] },
    { "name": "conquer", "trans": ["征服， 战胜； 破除， 克服"] },
    { "name": "learned", "trans": ["有学问的； 学术上的"] },
    { "name": "substance", "trans": ["物质； 实质； 大意， 要旨； 根据， 理由"] },
    { "name": "frown", "trans": ["皱眉， 蹙额"] },
    { "name": "bond", "trans": ["黏合， 结合", "联结，联系；公债；契约，合同"] },
    { "name": "target", "trans": ["目标", "把…作为目标； 瞄准"] },
    { "name": "wax", "trans": ["蜡，蜂蜡", "给…上蜡"] },
    { "name": "grind", "trans": ["磨， 磨快"] },
    { "name": "urban", "trans": ["城市的， 都市的； 住在都市的"] },
    { "name": "furthermore", "trans": ["而且， 此外"] },
    { "name": "guidance", "trans": ["引导， 指导， 领导"] },
    { "name": "risk", "trans": ["危险，风险；引起危险的事物", "冒…的危险， 使遭受危险"] },
    { "name": "flame", "trans": ["火焰， 光辉； 热情"] },
    { "name": "container", "trans": ["容器； 集装箱"] },
    { "name": "leader", "trans": ["领袖， 领导人， 首领"] },
    { "name": "delicate", "trans": ["纤细的， 易碎的； 微妙的； 精美的"] },
    { "name": "discard", "trans": ["丢弃， 抛弃， 遗弃"] },
    { "name": "fancy", "trans": ["昂贵的； 别致的", "想象力，设想；爱好", "喜欢；想象，猜想"] },
    { "name": "rebel", "trans": ["反叛， 造反； 反对， 不服从", "反叛分子， 反对者"] },
    { "name": "bounce", "trans": ["弹起，反弹；颠簸", "弹， 反弹"] },
    { "name": "usage", "trans": ["使用； 对待； 惯用法"] },
    { "name": "tissue", "trans": ["组织； 薄绢， 薄纸， 手巾纸"] },
    { "name": "experimental", "trans": ["实验的， 试验的"] },
    { "name": "loosen", "trans": ["解开； 放松， 松弛"] },
    { "name": "rent", "trans": ["租借，租用；出租，出借", "租金； 出租"] },
    { "name": "nearby", "trans": ["在附近", "附近的"] },
    { "name": "carpenter", "trans": ["木工， 木匠"] },
    { "name": "cart", "trans": ["运货马车"] },
    { "name": "modest", "trans": ["谦虚的； 适中的； 羞怯的"] },
    { "name": "cast", "trans": ["投，扔，抛；浇铸", "演员表， 全体演员； 石膏绷带； 铸型， 铸件； 投， 抛"] },
    { "name": "anxious", "trans": ["忧虑的， 令人焦急的； 渴望的"] },
    { "name": "hatred", "trans": ["憎恶， 憎恨， 仇恨"] },
    { "name": "crush", "trans": ["压碎， 碾碎； 摧毁， 压垮"] },
    { "name": "largely", "trans": ["大部分， 大量地"] },
    { "name": "slice", "trans": ["薄片，切片；部分", "切， 削"] },
    { "name": "frost", "trans": ["结霜", "冰冻，严寒；霜"] },
    { "name": "electron", "trans": ["电子"] },
    { "name": "incline", "trans": ["倾斜； 弯； 倾向于", "斜坡"] },
    { "name": "truly", "trans": ["真正地； 忠实地"] },
    { "name": "cash", "trans": ["现金，现款", "把…兑现"] },
    { "name": "counsel", "trans": ["律师，法律顾问；忠告，劝告", "劝告， 提议"] },
    { "name": "item", "trans": ["条， 条款， 一条"] },
    { "name": "gene", "trans": ["基因"] },
    { "name": "liver", "trans": ["肝"] },
    { "name": "suicide", "trans": ["自杀"] },
    { "name": "violet", "trans": ["紫色的", "紫罗兰"] },
    { "name": "hollow", "trans": ["空的， 空洞的； 沉闷的； 虚伪的"] },
    { "name": "trunk", "trans": ["树干； 大衣箱， 皮箱； 汽车后备箱； 象鼻"] },
    { "name": "saving", "trans": ["节省， 节约； 储蓄金， 存款"] },
    { "name": "rely", "trans": ["依靠， 依赖； 信赖， 指望"] },
    { "name": "hospitalize", "trans": ["就医"] },
    { "name": "slide", "trans": ["滑；悄悄地移动", "滑动； 滑道， 滑面； 幻灯片"] },
    { "name": "utilize", "trans": ["利用"] },
    { "name": "sticky", "trans": ["黏性的， 胶黏的；  湿热的"] },
    { "name": "prevail", "trans": ["流行， 盛行"] },
    { "name": "waist", "trans": ["腰， 腰部"] },
    { "name": "excessive", "trans": ["过多的， 极度的"] },
    { "name": "flesh", "trans": ["肉， 肌肉， 肉体"] },
    { "name": "jungle", "trans": ["丛林， 密林， 莽丛； 乱七八糟的一堆"] },
    { "name": "portion", "trans": ["一部分，一份", "分配， 把…分给"] },
    { "name": "settle", "trans": ["安排， 安放； 调停； 支付， 核算； 安家； 飞落， 停留； 安定"] },
    { "name": "pattern", "trans": ["型，式样，模型", "仿制， 使照…样子"] },
    { "name": "harsh", "trans": ["严厉的； 粗糙的"] },
    { "name": "connexion", "trans": ["connection的英式拼法"] },
    { "name": "vehicle", "trans": ["车辆， 机动车； 传播媒介， 工具， 手段"] },
    { "name": "scrape", "trans": ["刮，擦", "刮， 擦； 刮擦声"] },
    { "name": "hardship", "trans": ["艰难， 困苦"] },
    { "name": "confuse", "trans": ["使困惑， 把…弄糊涂； 混淆， 把…混同； 混乱， 搞乱"] },
    { "name": "cushion", "trans": ["垫子， 坐垫， 靠垫"] },
    { "name": "scold", "trans": ["责骂， 训斥"] },
    { "name": "glorious", "trans": ["光荣的； 壮丽的； 令人愉快的"] },
    { "name": "civilize", "trans": ["使文明， 使开化， 教育"] },
    { "name": "physician", "trans": ["内科医生"] },
    { "name": "architecture", "trans": ["建筑学； 建筑式样， 建筑风格"] },
    { "name": "stress", "trans": ["压力；强调；重要性；应力；重音", "强调， 着重； 重读"] },
    { "name": "grip", "trans": ["握紧，抓牢；吸引住", "紧握"] },
    { "name": "explore", "trans": ["探险， 探索； 仔细查阅， 探究"] },
    { "name": "display", "trans": ["陈列， 展览， 显示"] },
    { "name": "electricity", "trans": ["电"] },
    { "name": "climate", "trans": ["气候； 风土， 地带； 风气， 气氛"] },
    { "name": "conductor", "trans": ["售票员， 列车长； 指挥； 导体"] },
    { "name": "bore", "trans": ["使厌烦；钻，挖", "令人讨厌的人"] },
    { "name": "crust", "trans": ["面包皮， 硬外皮； 外壳， 地壳"] },
    { "name": "disorder", "trans": ["混乱， 杂乱， 骚乱"] },
    { "name": "criticize", "trans": ["批评； 评论； 非难"] },
    { "name": "organization", "trans": ["组织， 团体， 机构"] },
    { "name": "rage", "trans": ["狂吹； 汹涌； 激烈进行； 发怒， 发火", "狂怒，盛怒；风靡一时的事物，时尚"] },
    { "name": "width", "trans": ["宽阔， 广阔； 宽度"] },
    { "name": "dairy", "trans": ["乳制品的", "牛奶场；乳制品"] },
    { "name": "board", "trans": ["搭伙； 膳宿", "板，牌子；木板，纸板；委员会，董事会；伙食", "上"] },
    { "name": "economic", "trans": ["经济的，经济学的", "经济学； 经济状况"] },
    { "name": "stuff", "trans": ["材料，东西", "装， 填， 塞； 让…吃饱"] },
    { "name": "arrest", "trans": ["逮捕，拘留；停止，阻止；吸引", "逮捕， 拘留， 扣留"] },
    { "name": "widow", "trans": ["寡妇"] },
    { "name": "distinction", "trans": ["差别， 不同， 区分"] },
    { "name": "mature", "trans": ["成熟的；成年人的；深思熟虑的；到期的，应支付的", "使成熟"] },
    { "name": "navy", "trans": ["海军"] },
    { "name": "fee", "trans": ["费； 酬金， 赏金"] },
    { "name": "section", "trans": ["部分， 章节； 部门， 科； 截面， 剖面"] },
    { "name": "influence", "trans": ["影响，作用；影响力，势力；产生影响力的人", "影响"] },
    { "name": "whip", "trans": ["鞭笞；猛地移动；搅打成糊状", "鞭子"] },
    { "name": "protocol", "trans": ["礼仪， 礼节； 草案， 议定书"] },
    { "name": "whale", "trans": ["鲸"] },
    { "name": "provoke", "trans": ["对…挑衅， 激怒； 激起， 引起"] },
    { "name": "threaten", "trans": ["威胁， 恐吓； 预示快要来临， 是…的征兆； 构成威胁； 可能发生"] },
    { "name": "talent", "trans": ["天才， 才能； 人才"] },
    { "name": "percentage", "trans": ["百分比， 百分率"] },
    { "name": "negotiate", "trans": ["洽谈， 协商； 顺利通过， 成功越过； 协商， 谈判"] },
    { "name": "strain", "trans": ["扭伤， 拉伤； 尽力； 拉紧", "拉紧；过劳；极度紧张；张力；扭伤，拉伤；旋律；品种，家系；气质，个性特点"] },
    { "name": "hunt", "trans": ["打猎； 搜寻； 驱逐"] },
    { "name": "violin", "trans": ["小提琴"] },
    { "name": "remonstrate", "trans": ["抗议"] },
    { "name": "package", "trans": ["包裹，包装；一揽子交易", "把…打包； 包装"] },
    { "name": "visible", "trans": ["可见的， 看得见的， 有形的"] },
    { "name": "crisis", "trans": ["危机， 存亡之际； 关键阶段"] },
    { "name": "rail", "trans": ["责骂， 抱怨", "栏杆，横杆；铁轨，轨道；铁路"] },
    { "name": "survival", "trans": ["幸存； 幸存者， 残存物"] },
    { "name": "germ", "trans": ["微生物， 细菌； 幼芽"] },
    { "name": "inner", "trans": ["内部的； 内心的"] },
    { "name": "market", "trans": ["市场； 股市； 行情， 销路"] },
    { "name": "keen", "trans": ["热心的； 激烈的； 敏锐的， 敏捷的"] },
    { "name": "glimpse", "trans": ["看一眼，一瞥", "瞥见", "一瞥， 一看"] },
    { "name": "detective", "trans": ["侦探， 私人侦探"] },
    { "name": "transfer", "trans": ["搬， 转移； 调动， 转学； 转让， 过户； 乘车， 转乘"] },
    { "name": "footstep", "trans": ["脚步， 脚步声， 足迹"] },
    { "name": "veteran", "trans": ["老兵， 老手"] },
    { "name": "indifferent", "trans": ["冷漠的， 不积极的； 一般的， 平平的"] },
    { "name": "outside", "trans": ["外部的", "在…的外面； 向…的外面； 除…以外", "外部，外表"] },
    { "name": "nerve", "trans": ["神经； 勇敢， 胆量"] },
    { "name": "affection", "trans": ["感情； 爱， 爱慕"] },
    { "name": "eagle", "trans": ["鹰"] },
    { "name": "cruise", "trans": ["航游，巡航；缓慢巡行", "航游， 游弋"] },
    { "name": "elementary", "trans": ["基本的， 初级的"] },
    { "name": "topic", "trans": ["题目， 论题， 话题"] },
    { "name": "solemn", "trans": ["庄严的， 隆重的； 严肃的"] },
    { "name": "perceive", "trans": ["感知， 感觉， 察觉； 认识到， 意识到； 理解"] },
    { "name": "omit", "trans": ["省略； 遗漏"] },
    { "name": "embarrass", "trans": ["使窘迫， 使为难"] },
    { "name": "option", "trans": ["选择， 选择权， 选择自由； 选择的事物或人， 选课"] },
    { "name": "erroneous", "trans": ["错误的， 不正确的"] },
    { "name": "raid", "trans": ["袭击； 突入查抄， 突入搜捕； 劫掠"] },
    { "name": "politics", "trans": ["政治， 政治学； 政纲， 政见"] },
    { "name": "acute", "trans": ["严重的； 急性的； 灵敏的， 敏锐的； 精明的"] },
    { "name": "limitation", "trans": ["限制， 限度； 局限"] },
    { "name": "sponsor", "trans": ["发起者", "发起； 资助， 赞助； 支持"] },
    { "name": "kindergarten", "trans": ["幼儿园"] },
    { "name": "unexpected", "trans": ["想不到的， 意外的"] },
    { "name": "crucial", "trans": ["至关重要的， 决定性的"] },
    { "name": "contribute", "trans": ["捐献， 捐款； 投稿"] },
    { "name": "faith", "trans": ["信任； 信心； 信仰， 信条； 忠诚"] },
    { "name": "generator", "trans": ["发电机； 发生器"] },
    { "name": "insert", "trans": ["插入， 嵌入； 登载"] },
    { "name": "remark", "trans": ["说；评论说", "话语； 谈论， 评论"] },
    { "name": "forbid", "trans": ["禁止， 不许， 阻止"] },
    { "name": "microscope", "trans": ["显微镜"] },
    { "name": "necessarily", "trans": ["必要地； 必然"] },
    { "name": "highlight", "trans": ["强调，突出，使显著", "最精彩的部分； 最重要的事件"] },
    { "name": "candidate", "trans": ["候选人； 投考者， 申请求职者"] },
    { "name": "billion", "trans": ["十亿"] },
    {
      "name": "parallel",
      "trans": ["平行的；类似的；并列的，并联的", "可相比拟的事物；相似处；平行线，平行面；纬线", "与…平行； 与…相似， 与…相当， 比得上"]
    },
    { "name": "reject", "trans": ["拒绝； 拒纳， 退回", "次品"] },
    { "name": "patient", "trans": ["忍耐的，有耐心的", "病人"] },
    { "name": "cashier", "trans": ["出纳"] },
    { "name": "sheer", "trans": ["垂直地，陡峭地", "急转向， 偏离", "完全的，十足的；陡峭的，垂直的；极薄的，透明的"] },
    { "name": "miracle", "trans": ["奇迹， 令人惊奇的人"] },
    { "name": "profit", "trans": ["受益", "益处；利润，收益", "有益于，有利于"] },
    { "name": "original", "trans": ["最初的；新颖的；原版的", "原件， 原作"] },
    { "name": "wit", "trans": ["风趣； 妙语； 智力， 才智， 智能"] },
    { "name": "handful", "trans": ["一把， 少数， 一小撮"] },
    { "name": "assignment", "trans": ["任务， 指定的作业； 分配， 指派"] },
    { "name": "nylon", "trans": ["尼龙"] },
    { "name": "yawn", "trans": ["打呵欠", "呵欠"] },
    { "name": "electric", "trans": ["电的， 电动的"] },
    { "name": "terminal", "trans": ["晚期的，不治的；末端的，终点的，极限的", "终点； 终端； 接线端"] },
    { "name": "kingdom", "trans": ["王国； 领域； 界"] },
    { "name": "differ", "trans": ["不同， 相异； 发生分歧"] },
    { "name": "various", "trans": ["各种各样的， 不同的"] },
    { "name": "latter", "trans": ["后者的； 后一半的"] },
    { "name": "depression", "trans": ["抑郁， 沮丧； 不景气， 萧条； 洼地， 凹陷"] },
    { "name": "reduction", "trans": ["减少， 缩小； 下降， 降低"] },
    { "name": "extent", "trans": ["广度； 范围； 程度"] },
    { "name": "equivalent", "trans": ["相等的，等量的", "相等物， 等价物"] },
    { "name": "gaol", "trans": ["监禁", "监狱"] },
    { "name": "conversely", "trans": ["相反"] },
    { "name": "sauce", "trans": ["调味汁， 作料"] },
    { "name": "ban", "trans": ["取缔，查禁；禁止", "禁止， 禁令"] },
    { "name": "fascinate", "trans": ["有吸引力， 迷人", "迷住，强烈吸引"] },
    { "name": "suffer", "trans": ["遭受， 忍受， 容许； 受痛苦， 受损"] },
    { "name": "diplomatic", "trans": ["外交的， 从事外交的； 策略的， 有手腕的"] },
    { "name": "bat", "trans": ["蝙蝠； 球棒， 球拍"] },
    { "name": "neighbourhood", "trans": ["四邻； 附近； 接近"] },
    { "name": "draft", "trans": ["草稿；汇票；征兵；通风", "起草； 征募"] },
    { "name": "complex", "trans": ["由许多部分组成的， 复合的； 复杂的， 难懂的"] },
    { "name": "rank", "trans": ["给…评定等级； 列入， 占特定等级", "军衔，职衔；地位，社会阶层；排，行列"] },
    { "name": "assumption", "trans": ["假定， 臆断； 担任， 承担"] },
    { "name": "pension", "trans": ["养老金，抚恤金", "发给…养老金"] },
    { "name": "giant", "trans": ["巨大的", "巨人，巨物；才智超群的人"] },
    { "name": "fluid", "trans": ["流动的", "流体，液体"] },
    { "name": "bay", "trans": ["湾； 分隔间"] },
    { "name": "outlet", "trans": ["出口， 出路； 发泄途径"] },
    { "name": "neglect", "trans": ["疏于照料；疏忽", "疏忽"] },
    { "name": "addition", "trans": ["加， 加法； 附加物"] },
    { "name": "gang", "trans": ["聚集， 结成一伙", "一帮，一伙"] },
    { "name": "shrink", "trans": ["起皱； 收缩； 退缩， 畏缩"] },
    { "name": "ancestor", "trans": ["祖宗， 祖先； 原型； 先驱"] },
    { "name": "textile", "trans": ["纺织的", "纺织品；纺织业"] },
    { "name": "former", "trans": ["在前的", "前者"] },
    { "name": "panic", "trans": ["恐慌， 惊慌失措", "恐慌，惊慌，慌乱"] },
    { "name": "extend", "trans": ["延长， 扩大； 提供， 给予； 伸展， 达到"] },
    { "name": "fold", "trans": ["折叠，合拢", "褶， 折叠的部分"] },
    { "name": "plastic", "trans": ["塑料的；可塑的", "塑料， 塑料制品； 信用卡"] },
    { "name": "globe", "trans": ["地球， 世界； 地球仪； 球体"] },
    { "name": "chemical", "trans": ["化学的", "化学制品"] },
    { "name": "interpretation", "trans": ["解释； 口译； 艺术处理"] },
    { "name": "ax", "trans": ["斧子"] },
    { "name": "intermediate", "trans": ["中间的； 中级的"] },
    { "name": "folk", "trans": ["人们； 家属， 亲属； 大伙儿， 各位"] },
    { "name": "consideration", "trans": ["考虑， 思考， 要考虑的事； 体贴， 关心"] },
    { "name": "competitive", "trans": ["竞争的， 比赛的； 好竞争的， 求胜心切的； 有竞争力的"] },
    { "name": "enquiry", "trans": ["询问"] },
    { "name": "applause", "trans": ["鼓掌， 掌声"] },
    { "name": "frank", "trans": ["坦白的， 直率的"] },
    { "name": "abuse", "trans": ["滥用； 虐待， 伤害； 辱骂， 毁谤", "滥用； 虐待， 伤害； 辱骂， 毁谤"] },
    { "name": "prove", "trans": ["证实， 证明； 结果是"] },
    { "name": "scheme", "trans": ["密谋， 策划", "计划，方案；阴谋"] },
    { "name": "affect", "trans": ["影响； 感动"] },
    { "name": "deaf", "trans": ["聋的； 不愿听的"] },
    { "name": "admire", "trans": ["钦佩， 羡慕； 赞赏， 称赞， 夸奖"] },
    { "name": "consequently", "trans": ["因此， 因而， 所以"] },
    { "name": "virus", "trans": ["病毒； 病毒性疾病， 病毒病"] },
    { "name": "drain", "trans": ["排去，放水", "耗竭； 排水沟， 排水管"] },
    { "name": "isolate", "trans": ["使隔离， 使孤立"] },
    { "name": "sailor", "trans": ["水手， 海员"] },
    { "name": "amuse", "trans": ["逗…乐， 给…娱乐"] },
    { "name": "inward", "trans": ["向内", "里面的；内心的"] },
    { "name": "responsibility", "trans": ["责任， 责任心； 职责， 义务"] },
    { "name": "enquire", "trans": ["询问"] },
    { "name": "civil", "trans": ["公民的； 文职的； 民用的； 民事的， 民法的； 文明的"] },
    { "name": "popularity", "trans": ["普及， 流行； 声望"] },
    { "name": "subsequent", "trans": ["随后的， 后来的"] },
    { "name": "indicate", "trans": ["标示， 表示， 表明"] },
    { "name": "scarcely", "trans": ["几乎不， 简直不； 决不； 刚刚， 才"] },
    { "name": "panel", "trans": ["专门小组； 面， 板； 控制板， 仪表盘"] },
    { "name": "outstanding", "trans": ["突出的， 杰出的； 未解决的； 未偿付的"] },
    { "name": "charge", "trans": ["索价；控告；充电，充满；向前冲", "费用； 管理； 控告， 指责； 电荷， 充电"] },
    { "name": "sew", "trans": ["缝制， 缝纫"] },
    { "name": "oval", "trans": ["椭圆形的", "椭圆形"] },
    { "name": "column", "trans": ["柱， 支柱， 圆柱； 纵队； 栏， 专栏"] },
    { "name": "procedure", "trans": ["程序， 手续， 步骤"] },
    { "name": "sample", "trans": ["样品，试样，样本", "从…抽样； 品尝， 体验"] },
    { "name": "integrate", "trans": ["成为一体， 合并"] },
    { "name": "survivor", "trans": ["生还者； 残存物"] },
    { "name": "applicant", "trans": ["申请人"] },
    { "name": "tropical", "trans": ["热带的； 炎热的"] },
    { "name": "partner", "trans": ["做…的搭档", "伙伴，合伙人，搭档，配偶"] },
    { "name": "plunge", "trans": ["纵身投入， 猛冲； 猛跌"] },
    { "name": "diagnose", "trans": ["诊断"] },
    { "name": "somewhat", "trans": ["有点儿", "一点儿"] },
    { "name": "earnest", "trans": ["认真的， 诚恳的"] },
    { "name": "spider", "trans": ["蜘蛛"] },
    { "name": "interview", "trans": ["接见， 会见； 面谈， 面试； 采访"] },
    { "name": "essential", "trans": ["必要的；本质的", "要素； 必需品"] },
    { "name": "clarify", "trans": ["澄清， 阐明"] },
    { "name": "furnace", "trans": ["炉子， 熔炉， 鼓风炉"] },
    { "name": "ditch", "trans": ["沟， 沟渠， 渠道"] },
    { "name": "deck", "trans": ["甲板， 舱面； 层面"] },
    { "name": "scare", "trans": ["吓， 使害怕； 受惊吓， 感到害怕", "惊恐，恐慌"] },
    { "name": "thunder", "trans": ["打雷， 轰隆响； 大声喊， 吼", "雷，雷声；擂鼓般的响声，轰隆声"] },
    { "name": "observe", "trans": ["注意到， 观察； 评说； 遵守"] },
    { "name": "humorous", "trans": ["幽默的， 诙谐的"] },
    { "name": "furnish", "trans": ["供应， 提供； 装备"] },
    { "name": "bet", "trans": ["打赌；敢说，确信", "打赌； 赌金， 赌注"] },
    { "name": "contact", "trans": ["接触；联系，交往", "与…接触， 与…取得联系"] },
    { "name": "rare", "trans": ["稀有的， 冷僻的； 珍奇的； 出类拔萃的； 稀薄的； 煎得嫩的"] },
    { "name": "discharge", "trans": ["释放， 排出； 卸货", "释放， 放电"] },
    { "name": "exclude", "trans": ["把…排除在外"] },
    { "name": "scary", "trans": ["引起惊慌的"] },
    { "name": "criticism", "trans": ["批评， 批判； 评论， 评论文章"] },
    { "name": "utmost", "trans": ["最远的", "极限"] },
    { "name": "image", "trans": ["像； 形象； 映像； 形象的描述"] },
    { "name": "consultancy", "trans": ["咨询公司"] },
    { "name": "ribbon", "trans": ["缎带， 丝带； 色带"] },
    { "name": "garbage", "trans": ["垃圾， 废物； 废话； 无用的资料"] },
    { "name": "complain", "trans": ["抱怨， 诉苦； 控告， 投诉"] },
    { "name": "mainland", "trans": ["大陆"] },
    { "name": "homogeneous", "trans": ["同种类的， 同性质的， 有相同特征的"] },
    { "name": "anxiety", "trans": ["焦虑， 忧虑； 渴望， 热望"] },
    { "name": "temptation", "trans": ["诱惑， 引诱"] },
    { "name": "adjust", "trans": ["调整， 调节， 校正"] },
    { "name": "popularize", "trans": ["普及"] },
    { "name": "burst", "trans": ["爆裂，爆炸；挤满；突然打开；突然发作", "使爆炸；突然打开", "爆炸"] },
    { "name": "vigorous", "trans": ["朝气蓬勃的； 有力的， 用力的"] },
    { "name": "debt", "trans": ["债， 债务， 欠债"] },
    { "name": "refrigerator", "trans": ["冰箱， 冷藏库"] },
    { "name": "necessity", "trans": ["必需品； 必要性， 需要"] },
    { "name": "fog", "trans": ["雾， 烟雾， 尘雾"] },
    { "name": "frame", "trans": ["框架，框子，构架", "给…镶框； 陷害， 诬告； 制定； 表达"] },
    { "name": "lucky", "trans": ["幸运的， 侥幸的； 吉利的"] },
    { "name": "origin", "trans": ["起源， 起因； 出身， 血统"] },
    { "name": "industrialize", "trans": ["工业化"] },
    { "name": "clash", "trans": ["发生冲突；不协调；砰地相撞，发出刺耳的撞击声", "冲突； 不协调； 刺耳的撞击声"] },
    { "name": "compel", "trans": ["强迫， 迫使屈服"] },
    { "name": "capable", "trans": ["有能力的， 有才能的"] },
    { "name": "depress", "trans": ["使沮丧； 使不景气； 削弱， 抑制"] },
    { "name": "parade", "trans": ["游行， 列队前进； 招摇而行", "游行；检阅"] },
    { "name": "burden", "trans": ["重担，精神负担", "加重压于， 烦扰； 负担， 装载"] },
    { "name": "precaution", "trans": ["预防； 防备， 警惕"] },
    { "name": "lemon", "trans": ["柠檬； 柠檬树； 柠檬黄， 淡黄色"] },
    { "name": "personality", "trans": ["人格， 个性； 人物， 名人"] },
    { "name": "plot", "trans": ["密谋， 计划； 绘制…的平面图， 在图上标绘…的位置", "故事情节；计划，密谋；小块土地"] },
    { "name": "sanction", "trans": ["批准，认可", "批准， 认可； 约束因素， 约束力；  国际制裁"] },
    { "name": "rate", "trans": ["被评价； 被列入特定级别", "速度，速率；比率；价格，费用；等级", "评估；给…定级，把…列为；值得，应得"] },
    { "name": "alert", "trans": ["警觉的，留神的，注意的", "使认识到，使意识到", "警戒， 戒备； 警报"] },
    { "name": "await", "trans": ["等候； 期待； 将降临到…身上"] },
    { "name": "sin", "trans": ["犯戒律， 犯过失", "罪，罪孽"] },
    { "name": "client", "trans": ["委托人， 当事人， 顾客"] },
    { "name": "prosperity", "trans": ["繁荣， 兴旺"] },
    { "name": "divide", "trans": ["分，分开；分配，分享；除；产生分歧", "分歧； 分界线， 分水岭"] },
    { "name": "contrary", "trans": ["相反的", "相反"] },
    { "name": "lump", "trans": ["结块； 将…归并在一起", "块，肿块"] },
    { "name": "digital", "trans": ["数字的， 数位的"] },
    { "name": "brilliant", "trans": ["光辉的； 卓越的"] },
    { "name": "historic", "trans": ["历史上著名的， 具有重大历史意义的"] },
    { "name": "oven", "trans": ["炉， 烤箱"] },
    { "name": "bound", "trans": ["跳跃；弹回，反跃", "一定的；有义务的", "跳跃；弹回，反跃；成为…的界限，给…划界", "跳跃； 界限， 限制"] },
    { "name": "counter", "trans": ["柜台； 计数器； 筹码"] },
    { "name": "rhythm", "trans": ["韵律， 节奏"] },
    { "name": "stroke", "trans": ["中风；一举，一次努力；划桨，划水；击，敲；报时的钟声；笔画，一笔；抚摸", "抚摸"] },
    { "name": "breed", "trans": ["繁殖； 养育， 培育； 酿成， 产生", "品种"] },
    { "name": "management", "trans": ["管理； 处理； 管理部门； 管理人员"] },
    { "name": "publish", "trans": ["公布， 发表； 出版， 刊印"] },
    { "name": "realm", "trans": ["界， 领域， 范围； 王国， 国度"] },
    { "name": "correspondent", "trans": ["通讯员， 记者"] },
    { "name": "avoid", "trans": ["避免， 躲开； 撤销"] },
    { "name": "renew", "trans": ["重新开始， 继续； 更新； 恢复； 延长有效期"] },
    { "name": "bid", "trans": ["企图，努力；喊价，出价，投标", "喊价， 投标， 出； 祝， 表示； 命令"] },
    { "name": "prompt", "trans": ["敏捷的，及时的", "促使，推动；提示", "提示台词， 提示"] },
    { "name": "astonish", "trans": ["使惊讶， 使吃惊"] },
    { "name": "comprehensive", "trans": ["广泛的， 综合的； 理解的"] },
    { "name": "tunnel", "trans": ["挖， 开", "隧道，坑道，地道"] },
    { "name": "withdraw", "trans": ["收回， 撤回； 撤退"] },
    { "name": "assign", "trans": ["指派， 分配， 布置； 指定"] },
    { "name": "elaborate", "trans": ["复杂的； 精心制作的", "详述， 详细制订"] },
    { "name": "feather", "trans": ["羽毛， 翎毛"] },
    { "name": "corridor", "trans": ["走廊， 回廊， 通路"] },
    { "name": "decade", "trans": ["十年， 十年期"] },
    { "name": "expert", "trans": ["熟练的", "专家"] },
    { "name": "select", "trans": ["精选的， 挑选出来的； 优等的， 第一流的", "选择，挑选"] },
    { "name": "fulfill", "trans": ["履行， 实现， 完成； 满足， 使满意"] },
    { "name": "crowd", "trans": ["聚集； 挤满； 挤； 推", "群，一批"] },
    { "name": "congress", "trans": ["代表大会； [C-]国会， 议会"] },
    { "name": "advanced", "trans": ["先进的； 高级的； 年迈的； 后阶段的"] },
    { "name": "mechanical", "trans": ["机械的， 机械制造的； 机械学的， 力学的； 呆板的"] },
    { "name": "recommendation", "trans": ["推荐， 推荐信； 建议； 优点， 可取之处"] },
    { "name": "welfare", "trans": ["幸福； 福利， 福利救济"] },
    { "name": "flourish", "trans": ["繁荣，茂盛，兴旺", "挥动"] },
    { "name": "output", "trans": ["产量；输出；输出功率", "输出"] },
    { "name": "fatigue", "trans": ["疲劳", "疲劳，劳累"] },
    { "name": "striking", "trans": ["显著的， 突出的； 惹人注目的， 容貌出众的"] },
    { "name": "vessel", "trans": ["容器； 船； 飞船； 管"] },
    { "name": "majority", "trans": ["多数， 大多数"] },
    { "name": "drag", "trans": ["拖，拉；迫使，硬拉；拖着脚步走", "累赘； 一吸， 一抽"] },
    { "name": "crown", "trans": ["王冠， 冕"] },
    { "name": "likely", "trans": ["可能", "可能的；适合的"] },
    { "name": "inquire", "trans": ["打听， 询问； 调查"] },
    { "name": "gymnasium", "trans": ["体育馆， 健身房"] },
    { "name": "issue", "trans": ["发行， 分发， 发出", "问题；发行，分发"] },
    { "name": "vain", "trans": ["徒劳的； 自负的"] },
    { "name": "lower", "trans": ["较低的；下面的", "放下， 降低"] },
    { "name": "index", "trans": ["索引； 指数， 指标"] },
    { "name": "fry", "trans": ["油煎， 油炸， 油炒"] },
    { "name": "youngster", "trans": ["青年， 年轻人， 孩子"] },
    { "name": "odd", "trans": ["奇特的； 临时的； 奇数的； 单只的； 剩余的， 挂零的"] },
    { "name": "lung", "trans": ["肺"] },
    { "name": "conservation", "trans": ["保存； 保护； 守恒"] },
    { "name": "distraction", "trans": ["分心的事； 心烦意乱； 精神错乱； 娱乐， 消遣"] },
    { "name": "supplement", "trans": ["增补， 补充； 增刊， 副刊", "增补， 补充"] },
    { "name": "contrast", "trans": ["形成对比， 对比之下显出区别", "对比， 对照； 反差", "对比，对照"] },
    { "name": "paragraph", "trans": ["段， 节"] },
    { "name": "judgement", "trans": ["意见； 审判； 判断"] },
    { "name": "proportion", "trans": ["比例； 部分， 份儿； 均衡， 相称"] },
    { "name": "widen", "trans": ["加宽， 变宽"] },
    { "name": "crystal", "trans": ["水晶， 结晶体， 晶粒"] },
    { "name": "occurrence", "trans": ["发生， 出现； 发生的事件"] },
    { "name": "inflation", "trans": ["通货膨胀； 膨胀"] },
    { "name": "thinking", "trans": ["深思的， 有理性的", "想法，意见，见解"] },
    { "name": "cycle", "trans": ["循环（周期）", "骑自行车"] },
    { "name": "disposal", "trans": ["丢掉， 销毁； 处理； 排列， 布置"] },
    { "name": "settlement", "trans": ["解决； 协议； 居留地"] },
    { "name": "delegate", "trans": ["代表， 代表团成员", "委派…为代表； 授 ， 把…委托给"] },
    { "name": "literature", "trans": ["文学， 文学作品； 文献， 图书资料"] },
    { "name": "sphere", "trans": ["球， 球体； 范围"] },
    { "name": "inquiry", "trans": ["询问， 打听； 调查"] },
    { "name": "prejudice", "trans": ["偏见，成见", "使有偏见； 对…不利， 损害"] },
    { "name": "dissolve", "trans": ["溶解； 解散； 消失， 减弱； 结束"] },
    { "name": "digest", "trans": ["消化； 领会", "文摘； 摘要"] },
    { "name": "angle", "trans": ["角， 角度； 观点， 立场"] },
    { "name": "immigrant", "trans": ["移民， 侨民"] },
    { "name": "tradition", "trans": ["传统， 惯例"] },
    { "name": "ability", "trans": ["能力， 本领； 才能， 才智"] },
    { "name": "urgent", "trans": ["紧急的， 急迫的"] },
    { "name": "belief", "trans": ["信任， 相信； 信念"] },
    { "name": "bundle", "trans": ["捆，包，束；包袱", "收集， 归拢； 把…塞入"] },
    { "name": "suggestion", "trans": ["建议， 意见； 细微的迹象； 暗示"] },
    { "name": "systematic", "trans": ["有系统的， 有计划的"] },
    { "name": "cupboard", "trans": ["柜橱；  碗碟橱， 食橱"] },
    { "name": "generally", "trans": ["一般地， 通常地； 普遍地"] },
    { "name": "bacon", "trans": ["咸肉"] },
    { "name": "likewise", "trans": ["同样地； 也， 又"] },
    { "name": "slender", "trans": ["细长的， 苗条的； 微薄的， 不足的"] },
    { "name": "troublesome", "trans": ["令人烦恼的， 麻烦的"] },
    { "name": "instruction", "trans": ["命令， 指示； 用法说明； 教学， 教导"] },
    { "name": "oral", "trans": ["口头的； 口的"] },
    { "name": "married", "trans": ["已婚的； 婚姻的"] },
    { "name": "transaction", "trans": ["办理， 处理； 交易， 业务； 会报， 学报"] },
    { "name": "conscious", "trans": ["意识到的， 自觉的； 神志清醒的； 有意的， 存心的"] },
    { "name": "fur", "trans": ["软毛， 毛皮， 裘皮； 毛皮衣服"] },
    { "name": "auto", "trans": ["汽车"] },
    { "name": "sigh", "trans": ["叹气， 叹息", "叹息"] },
    { "name": "constant", "trans": ["经常的，不断的，连续发生的；永恒的；忠实的，忠诚的", "常数， 恒量"] },
    { "name": "pants", "trans": ["长裤， 便裤； 内裤"] },
    { "name": "mislead", "trans": ["给…错误印象， 使误解； 把…带错路； 使误入歧途"] },
    { "name": "split", "trans": ["分裂，分离；被撕裂，裂开；分担，分享", "裂口"] },
    { "name": "orchestra", "trans": ["管弦乐队"] },
    { "name": "publication", "trans": ["出版， 发行； 公布， 发表"] },
    { "name": "sufficient", "trans": ["足够的， 充分的"] },
    { "name": "claim", "trans": ["声称，主张；对…提出要求，索取；以…为其成果；需要，值得", "要求， 认领， 索赔； 声称； 断言"] },
    { "name": "antique", "trans": ["古时的， 过时的", "古物，古董"] },
    { "name": "sow", "trans": ["播， 播种"] },
    { "name": "widespread", "trans": ["分布广的， 普遍的"] },
    { "name": "petroleum", "trans": ["石油"] },
    { "name": "communication", "trans": ["通讯， 交流， 交际； 通信工具， 交通联系"] },
    { "name": "directly", "trans": ["直接地； 正好地， 截然； 立即"] },
    { "name": "tolerance", "trans": ["忍受， 容忍， 忍耐力； 公差"] },
    { "name": "kneel", "trans": ["跪， 跪下， 跪着"] },
    { "name": "porter", "trans": ["搬运工人； 看门人， 大楼管理员"] },
    { "name": "fasten", "trans": ["扎牢， 扣住"] },
    { "name": "contest", "trans": ["竞赛， 争夺", "争夺， 与…竞争； 对…提出质疑， 辩驳"] },
    { "name": "author", "trans": ["著作家， 作者"] },
    { "name": "dirt", "trans": ["灰尘， 土； 污物， 污垢"] },
    { "name": "astrophysics", "trans": ["天体物理学"] },
    { "name": "greenhouse", "trans": ["温室， 暖房"] },
    { "name": "preserve", "trans": ["保护， 维持； 保存； 腌制"] },
    { "name": "plus", "trans": ["表示加的；正的", "加，加上", "加号， 正号"] },
    { "name": "establish", "trans": ["建立， 设立； 确立； 证实"] },
    { "name": "expansion", "trans": ["扩大， 扩充， 扩张， 膨胀"] },
    { "name": "relevant", "trans": ["有关的， 切题的"] },
    { "name": "entry", "trans": ["入口处； 登记； 进入； 参赛者名单； 条目"] },
    { "name": "license", "trans": ["许可，执照", "准许"] },
    { "name": "synthetic", "trans": ["综合的， 合成的； 虚假的"] },
    { "name": "headquarters", "trans": ["司令部， 总部"] },
    { "name": "expand", "trans": ["扩大， 使膨胀"] },
    { "name": "gaze", "trans": ["凝视， 盯， 注视"] },
    { "name": "essay", "trans": ["短文， 散文， 小品文"] },
    { "name": "survey", "trans": ["俯瞰； 检查； 测量", "调查， 勘察； 测量， 勘测； 全面审视， 概括论述"] },
    { "name": "plug", "trans": ["插头，插座；塞子，栓", "把…塞住， 用…塞住"] },
    { "name": "bunch", "trans": ["集中， 挤在一起； 形成一束", "群，伙；束，串，捆", "使成一束"] },
    { "name": "thermometer", "trans": ["温度计， 寒暑表"] },
    { "name": "tense", "trans": ["拉紧的；紧张的", "拉紧， 绷紧", "时态"] },
    { "name": "postpone", "trans": ["延迟， 延期"] },
    { "name": "bride", "trans": ["新娘"] },
    { "name": "favourable", "trans": ["有利的， 赞成的； 顺利的"] },
    { "name": "hammer", "trans": ["锤击", "锤，榔头"] },
    { "name": "candy", "trans": ["糖果"] },
    { "name": "seal", "trans": ["封铅，封条；印，图章；海豹", "封"] },
    { "name": "mental", "trans": ["心理的， 精神的， 思想上的； 精神病的； 智力的"] },
    { "name": "decent", "trans": ["像样的， 过得去的， 体面的； 宽厚的， 大方的； 正派的； 合乎礼仪的， 得体的"] },
    { "name": "storage", "trans": ["贮藏； 贮藏量"] },
    { "name": "investigate", "trans": ["调查， 研究"] },
    { "name": "racial", "trans": ["种族的， 人种的"] },
    { "name": "switch", "trans": ["转换， 改变", "开关，电闸；转换，改变"] },
    { "name": "niece", "trans": ["侄女， 外甥女"] },
    { "name": "considerable", "trans": ["相当大的； 重要的"] },
    { "name": "auxiliary", "trans": ["辅助的， 附属的； 后备的"] },
    { "name": "heal", "trans": ["愈合， 痊愈， 恢复健康", "使愈合，治愈，使康复；调停，消除"] },
    { "name": "reserve", "trans": ["保留，留存；预订", "储备； 保留； 拘谨， 矜持； 替补队员， 后备部队； 自然保护区"] },
    { "name": "somehow", "trans": ["由于某种原因， 不知怎么的； 以某种方式"] },
    { "name": "dive", "trans": ["跳水； 潜水；  俯冲"] },
    { "name": "brick", "trans": ["砖， 砖块， 砖状物"] },
    { "name": "sympathy", "trans": ["同情； 一致， 支持， 赞同"] },
    { "name": "heap", "trans": ["堆， 大量"] },
    { "name": "consumer", "trans": ["消费者， 用户， 消耗者"] },
    { "name": "rescue", "trans": ["营救， 救援"] },
    { "name": "cripple", "trans": ["跛子；残疾人", "使跛； 使残疾"] },
    { "name": "highly", "trans": ["高度地， 极， 非常赞许地"] },
    { "name": "brief", "trans": ["简短的，短暂的", "向…介绍基本情况，做…的提要", "概要， 摘要"] },
    { "name": "keyboard", "trans": ["键盘"] },
    { "name": "initiative", "trans": ["主动性； 首创精神； 主动的行动， 倡议； 主动权"] },
    { "name": "recover", "trans": ["重新获得， 挽回； 恢复"] },
    { "name": "determine", "trans": ["决意", "决定；查明；使下决心"] },
    { "name": "nature", "trans": ["大自然； 本性； 性质"] },
    { "name": "social", "trans": ["社会的； 交际的， 社交的"] },
    { "name": "medication", "trans": ["药物治疗； 药物"] },
    { "name": "drill", "trans": ["钻， 打； 训练", "钻头；操练，训练"] },
    { "name": "intelligent", "trans": ["聪明的， 理智的"] },
    { "name": "whilst", "trans": ["当…的时候"] },
    { "name": "clerk", "trans": ["店员， 办事员， 职员"] },
    { "name": "lobby", "trans": ["向进行游说", "大厅；休息室；院外活动集团"] },
    { "name": "acknowledge", "trans": ["承认， 承认…的权威； 告知收到， 确认； 对…表示感谢， 报偿"] },
    { "name": "equip", "trans": ["装备， 配备； 使有准备"] },
    { "name": "radiation", "trans": ["放射物， 辐射能； 辐射"] },
    { "name": "Christian", "trans": ["基督教的", "基督教徒，信徒"] },
    { "name": "pulse", "trans": ["搏动， 跳动", "脉搏；脉冲"] },
    { "name": "luxury", "trans": ["奢侈； 奢侈品"] },
    { "name": "spiritual", "trans": ["精神的， 心灵的； 宗教的"] },
    { "name": "worthwhile", "trans": ["值得花时间的， 值得做的"] },
    { "name": "mould", "trans": ["霉，霉菌；模型，铸模；性格，气质", "用模子做， 浇铸； 使形成， 把…铸造成"] },
    { "name": "increasingly", "trans": ["日益， 越来越多地"] },
    { "name": "elbow", "trans": ["肘，肘部", "用肘部， 用肘挤"] },
    { "name": "salad", "trans": ["色拉， 凉拌菜"] },
    { "name": "strategic", "trans": ["对全局有重要意义的， 关键的； 战略的"] },
    { "name": "vary", "trans": ["改变； 多样化"] },
    { "name": "readily", "trans": ["乐意地， 欣然地； 容易地； 很快地， 立即"] },
    { "name": "stoop", "trans": ["俯身，弯腰；堕落，自贬", "弯腰， 曲背"] },
    { "name": "upper", "trans": ["上面的， 地位较高的"] },
    { "name": "rocket", "trans": ["迅速上升， 猛涨", "火箭"] },
    { "name": "splendid", "trans": ["壮丽的； 极好的"] },
    { "name": "county", "trans": ["郡， 县"] },
    { "name": "respondent", "trans": ["回答者； 响应者； 被告"] },
    { "name": "episode", "trans": ["一个事件； 插曲， 片段； 连续剧的一集"] },
    { "name": "convenience", "trans": ["方便； 便利设施"] },
    { "name": "determination", "trans": ["决心， 决定； 确定"] },
    { "name": "discipline", "trans": ["纪律；训练；惩罚；学科", "训练； 惩罚， 处罚"] },
    { "name": "behalf", "trans": ["利益"] },
    { "name": "evidently", "trans": ["明显地， 显然"] },
    { "name": "objective", "trans": ["客观的， 无偏见的", "目标，目的"] },
    { "name": "injure", "trans": ["伤害， 损害， 使遭受损伤"] },
    { "name": "victimize", "trans": ["使受害， 使牺牲"] },
    { "name": "exert", "trans": ["尽； 运用"] },
    { "name": "province", "trans": ["省； 领域， 范围"] },
    { "name": "scandal", "trans": ["丑事， 丑闻； 流言蜚语； 反感， 愤慨"] },
    { "name": "horrible", "trans": ["令人恐惧的， 可怕的； 骇人听闻的； 极讨厌的， 使人不愉快的； 糟透的"] },
    { "name": "estimate", "trans": ["估计； 评价", "估计； 评价， 看法"] },
    { "name": "strengthen", "trans": ["加强， 巩固"] },
    { "name": "drip", "trans": ["滴下，漏水", "水滴； 滴水声"] },
    { "name": "injury", "trans": ["损害， 伤害； 受伤处"] },
    { "name": "exhibit", "trans": ["显示；陈列，展览", "展览品"] },
    { "name": "brand", "trans": ["品牌；烙印", "铭刻， 打烙印于； 加污名于， 谴责"] },
    { "name": "mushroom", "trans": ["迅速成长", "蘑菇"] },
    { "name": "alcohol", "trans": ["酒精， 乙醇"] },
    { "name": "choke", "trans": ["使窒息； 塞满， 塞住"] },
    { "name": "proposal", "trans": ["提议， 建议； 求婚"] },
    { "name": "inevitable", "trans": ["不可避免的， 必然的"] },
    { "name": "deny", "trans": ["否定， 否认； 拒绝…的要求"] },
    { "name": "miserable", "trans": ["痛苦的； 悲惨的； 令人难受的"] },
    { "name": "recreation", "trans": ["娱乐活动， 消遣"] },
    { "name": "subtract", "trans": ["减， 减去， 去掉"] },
    { "name": "allowance", "trans": ["津贴， 补贴； 零用钱"] },
    { "name": "portable", "trans": ["便于携带的， 手提式的"] },
    { "name": "ancient", "trans": ["古代的， 古老的； 年老的， 看上去很老的"] },
    { "name": "glory", "trans": ["光荣， 荣誉的事； 美丽"] },
    { "name": "secondary", "trans": ["次要的， 第二的； 中等的； 辅助的， 从属的"] },
    { "name": "mission", "trans": ["使命， 任务； 使团"] },
    { "name": "attorney", "trans": ["律师， 代理人"] },
    { "name": "wander", "trans": ["漫游， 闲逛， 漫步； 偏离正道； 走神， 恍惚"] },
    { "name": "adopt", "trans": ["收养； 采用， 采取； 正式通过， 批准"] },
    { "name": "oppose", "trans": ["反对； 反抗"] },
    { "name": "singular", "trans": ["单数的； 非凡的， 奇特的； 独一无二的"] },
    { "name": "device", "trans": ["器械， 装置， 设备； 手段， 策略"] },
    { "name": "mainframe", "trans": ["主机， 大型机"] },
    { "name": "motor", "trans": ["发动机， 电动车"] },
    { "name": "minus", "trans": ["负的", "减", "负数；减号"] },
    { "name": "conservative", "trans": ["保守的，守旧的；不时兴的，传统的", "保守的人"] },
    { "name": "access", "trans": ["接近；通道，入口；接近的机会", "存取"] },
    { "name": "conference", "trans": ["会议， 讨论会； 讨论， 商谈"] },
    { "name": "activity", "trans": ["活动； 活力； 行动"] },
    { "name": "primitive", "trans": ["原始的，早期的；简单的；粗糙的", "原人； 原始事物"] },
    { "name": "advisable", "trans": ["明智的， 可取的"] },
    { "name": "dormitory", "trans": ["集体寝室， 宿舍"] },
    { "name": "overcome", "trans": ["战胜， 克服； 压倒， 使受不了"] },
    { "name": "cooperate", "trans": ["合作， 协作； 配合"] },
    { "name": "cabin", "trans": ["小屋； 船舱， 机舱"] },
    { "name": "sum", "trans": ["共计", "总数；金额；算术"] },
    { "name": "current", "trans": ["当前的， 通用的； 流行的， 流传的"] },
    { "name": "heel", "trans": ["脚后跟， 踵； 后跟"] },
    { "name": "variety", "trans": ["多样化； 种类； 变种"] },
    { "name": "disturb", "trans": ["打扰， 扰乱； 弄乱； 使不安"] },
    { "name": "copper", "trans": ["铜； 铜币， 铜制器"] },
    { "name": "persist", "trans": ["坚持， 持续"] },
    { "name": "audio", "trans": ["听觉的， 声音的"] },
    { "name": "civilian", "trans": ["平民， 百姓"] },
    { "name": "pump", "trans": ["泵", "抽， 泵送， 打气"] },
    { "name": "pierce", "trans": ["刺穿， 穿孔于"] },
    { "name": "teenager", "trans": ["青少年"] },
    { "name": "apart", "trans": ["成距离，相间隔；分开，除去", "分离的， 分隔的"] },
    { "name": "calendar", "trans": ["日历， 历书， 历法"] },
    { "name": "offensive", "trans": ["冒犯的，无礼的；进攻的，攻击性的", "进攻， 攻势"] },
    { "name": "cartoon", "trans": ["卡通画， 幽默画； 动画片， 卡通片"] },
    { "name": "speculate", "trans": ["推测， 推断； 投机， 做投机买卖"] },
    { "name": "launch", "trans": ["发射，投射，发动；使下水", "发射； 下水"] },
    { "name": "amid", "trans": ["在…中间， 在…之中， 被…围绕"] },
    { "name": "beloved", "trans": ["所钟爱的， 所爱戴的"] },
    { "name": "single", "trans": ["单一的；独身的；单程的", "单程票；"] },
    { "name": "confirm", "trans": ["证实， 肯定； 确认； 批准"] },
    { "name": "cement", "trans": ["水泥，胶泥", "黏结； 巩固， 使团结"] },
    { "name": "subway", "trans": ["地道； 地铁"] },
    { "name": "gallon", "trans": ["加仑"] },
    { "name": "acquaint", "trans": ["使认识， 使了解"] },
    { "name": "appoint", "trans": ["任命， 委任； 约定， 指定"] },
    { "name": "elastic", "trans": ["有弹性的， 灵活的", "松紧带"] },
    { "name": "assist", "trans": ["援助， 帮助， 协助"] },
    { "name": "vast", "trans": ["巨大的， 大量的； 浩瀚的"] },
    { "name": "intervene", "trans": ["干涉， 调停， 干预； 插入， 介入； 干扰， 打扰"] },
    { "name": "undergraduate", "trans": ["大学本科生"] },
    { "name": "symbol", "trans": ["象征； 符号， 标志"] },
    { "name": "commercial", "trans": ["商业的， 商务的； 商品化的， 商业性的"] },
    { "name": "joint", "trans": ["连接的； 共同的", "接头，接缝；关节"] },
    { "name": "reasonable", "trans": ["通情达理的， 讲道理的； 合理的； 公道的； 尚好的， 过得去的"] },
    { "name": "available", "trans": ["现成可使用的， 通用的； 可取的； 联系的； 可得到的"] },
    { "name": "confine", "trans": ["限制， 使局限； 使不外出， 禁闭"] },
    { "name": "advocate", "trans": ["拥护， 提倡， 主张", "拥护者， 提倡者； 辩护者， 律师"] },
    { "name": "frequency", "trans": ["屡次； 次数， 频率"] },
    { "name": "horizontal", "trans": ["地平的， 水平的"] },
    { "name": "luggage", "trans": ["行李"] },
    { "name": "lick", "trans": ["舔，舔吃；打败，克服；轻拍，吞卷", "舔； 少量， 少许"] },
    { "name": "missile", "trans": ["导弹， 飞弹， 投射物"] },
    { "name": "dynamic", "trans": ["有活力的；动力的；不断变化的", "动力； 动力学"] },
    { "name": "satisfactory", "trans": ["令人满意的"] },
    { "name": "military", "trans": ["军事的，军用的", "军队， 武装力量"] },
    { "name": "reluctant", "trans": ["不情愿的， 勉强的"] },
    { "name": "generate", "trans": ["发生， 引起； 生殖"] },
    { "name": "unusual", "trans": ["不平常的， 少有的； 独特的， 与众不同的"] },
    { "name": "sector", "trans": ["部门， 部分； 防御地段， 防区； 扇形"] },
    { "name": "passion", "trans": ["激情， 热情； 酷爱"] },
    { "name": "extreme", "trans": ["极度的；末端的", "极端， 过分"] },
    { "name": "coil", "trans": ["卷，圈；线圈", "卷"] },
    { "name": "ensure", "trans": ["保证； 保护"] },
    { "name": "coordinate", "trans": ["同等的， 并列的", "协调， 调节", "坐标"] },
    { "name": "organism", "trans": ["生物， 有机体； 机体， 有机组织"] },
    { "name": "athlete", "trans": ["运动员， 体育家"] },
    { "name": "epidemic", "trans": ["流行性的；传染的", "流行病； 传播"] },
    { "name": "suppose", "trans": ["猜想， 假定， 让； [常用于被动语态]期望， 认为应该"] },
    { "name": "upright", "trans": ["挺直着， 竖立着", "垂直的；正直的，诚实的"] },
    { "name": "remarkable", "trans": ["值得注意的， 引人注目的； 异常的， 非凡的"] },
    { "name": "brake", "trans": ["制动； 刹住， 用闸使放慢速度", "闸，刹车"] },
    { "name": "tube", "trans": ["管， 电子管， 显像管"] },
    { "name": "naval", "trans": ["海军的"] },
    { "name": "failure", "trans": ["失败， 失败的人； 失灵， 故障； 不履行"] },
    { "name": "accountancy", "trans": ["会计工作； 会计学"] },
    { "name": "forge", "trans": ["打制， 锻造； 伪造"] },
    { "name": "carpet", "trans": ["地毯"] },
    { "name": "solve", "trans": ["解答； 解释； 解决"] },
    { "name": "hint", "trans": ["暗示， 示意", "暗示，示意；细微的迹象；建议"] },
    { "name": "knot", "trans": ["打结", "结；节疤；节", "把…打成结"] },
    { "name": "demonstrate", "trans": ["说明； 论证； 表露"] },
    { "name": "region", "trans": ["地区， 地带， 区域； 范围， 幅度"] },
    { "name": "support", "trans": ["支撑；支持；供养；证实", "支持， 支撑物， 支持者"] },
    { "name": "yearly", "trans": ["一年一度地", "每年的"] },
    { "name": "deceive", "trans": ["欺骗， 蒙蔽， 行骗"] },
    { "name": "saucer", "trans": ["茶托， 碟子"] },
    { "name": "hire", "trans": ["租用； 雇用"] },
    { "name": "kid", "trans": ["戏弄， 开玩笑", "小孩；年轻人"] },
    { "name": "donkey", "trans": ["驴； 笨蛋"] },
    { "name": "destination", "trans": ["目的地， 终点， 目标"] },
    { "name": "vertical", "trans": ["垂直的， 竖式的"] },
    { "name": "learning", "trans": ["学习； 学问， 知识"] },
    { "name": "monument", "trans": ["纪念碑， 纪念馆； 历史遗迹"] },
    { "name": "misconception", "trans": ["误解"] },
    { "name": "damp", "trans": ["潮湿的，微湿的", "潮湿，湿气", "使潮湿； 使沮丧； 抑制"] },
    { "name": "vivid", "trans": ["鲜艳的； 生动的， 栩栩如生的"] },
    { "name": "honey", "trans": ["蜜， 蜂蜜； 甜， 甜蜜； [常用于称呼] 宝贝儿"] },
    { "name": "screw", "trans": ["螺丝", "固定， 拧紧"] },
    { "name": "gap", "trans": ["缺口； 间隔； 差距； 缺陷"] },
    { "name": "missing", "trans": ["缺掉的， 失踪的"] },
    { "name": "emphasize", "trans": ["强调， 着重"] },
    { "name": "virtue", "trans": ["善， 美德； 优点， 长处"] },
    { "name": "normal", "trans": ["正常的， 平常的； 正规的， 规范的"] },
    { "name": "socialist", "trans": ["社会主义者"] },
    { "name": "gradual", "trans": ["逐渐的， 渐进的； 坡度平缓的"] },
    { "name": "figure", "trans": ["出现； 合乎情理； 计算； 认为， 猜想", "数字；人物；算术；体形，体态；轮廓；画像，塑像；图，图形"] },
    { "name": "ore", "trans": ["矿， 矿石"] },
    { "name": "slight", "trans": ["轻微的，微小的", "侮慢；轻视，冷落"] },
    { "name": "previous", "trans": ["先的， 前的； 以前的"] },
    { "name": "transmit", "trans": ["传送， 传递； 传染； 播送， 发射"] },
    { "name": "socialism", "trans": ["社会主义"] },
    { "name": "consumption", "trans": ["消耗， 消费"] },
    { "name": "argue", "trans": ["争辩， 争论", "争论，争辩，辩论；主张；说服"] },
    { "name": "technology", "trans": ["工艺学； 工艺， 技术"] },
    { "name": "weaken", "trans": ["削弱， 变弱"] },
    { "name": "voltage", "trans": ["电压"] },
    { "name": "damn", "trans": ["该死，见鬼〔表示很生气或失望〕", "非常，很", "该死的〔表示对某人或某事物生气〕"] },
    { "name": "superior", "trans": ["上级的，较高的；优越的；有优越感的，高傲的", "上级， 长官"] },
    { "name": "location", "trans": ["位置， 场所； 外景拍摄地"] },
    { "name": "compensation", "trans": ["补偿， 赔偿"] },
    { "name": "bankrupt", "trans": ["破产的"] },
    { "name": "punctual", "trans": ["严守时刻的， 准时的"] },
    { "name": "advertise", "trans": ["为…做广告， 宣传； 公告； 登广告"] },
    { "name": "compromise", "trans": ["妥协； 危及； 放弃", "妥协，和解，折中办法"] },
    { "name": "sack", "trans": ["麻袋，包；解雇；洗劫，劫掠", "解雇； 洗劫， 劫掠"] },
    { "name": "software", "trans": ["软件"] },
    { "name": "seminar", "trans": ["研究班， 研讨会"] },
    { "name": "comparative", "trans": ["比较的， 相对的"] },
    { "name": "competition", "trans": ["竞争， 比赛"] },
    { "name": "fruitful", "trans": ["多产的， 肥沃的"] },
    { "name": "react", "trans": ["反应， 作出反应"] },
    { "name": "dragon", "trans": ["龙"] },
    { "name": "hesitant", "trans": ["犹豫的； 吞吞吐吐的"] },
    { "name": "beyond", "trans": ["在更远处， 再往后", "在…的那边，远于；迟于；越出"] },
    { "name": "hell", "trans": ["地狱； 极大的痛苦"] },
    { "name": "feedback", "trans": ["回授， 反馈， 反应"] },
    { "name": "hazard", "trans": ["危险；公害", "尝试着做； 冒…风险"] },
    { "name": "justify", "trans": ["证明…是正当的"] },
    { "name": "voluntary", "trans": ["自愿的， 志愿的"] },
    { "name": "connection", "trans": ["联系， 关系； 连接， 衔接； 连贯性； 熟人， 关系"] },
    { "name": "proof", "trans": ["耐…的， 能防…的", "证据，证明；校样，样张"] },
    { "name": "timber", "trans": ["木材， 原木； 大木料， 栋木"] },
    { "name": "roar", "trans": ["吼叫，怒号，咆哮；轰鸣；大声喊出，大声表示", "呐喊声， 咆哮声， 吼叫声； 轰鸣"] },
    { "name": "presence", "trans": ["出席， 到场； 存在； 仪表， 仪态"] },
    { "name": "phase", "trans": ["阶段； 方面； 相， 相位"] },
    { "name": "surrounding", "trans": ["附近的；四周的"] },
    { "name": "efficiency", "trans": ["效率； 功效， 效能"] },
    { "name": "overhead", "trans": ["在头顶上", "在头顶上的，架空的", "经常费用， 管理费用"] },
    { "name": "contract", "trans": ["缩小； 订合同； 感染， 染上", "契约， 合同"] },
    { "name": "conclude", "trans": ["推断出， 推论出； 结束， 终了； 缔结， 议定"] },
    { "name": "comprehension", "trans": ["理解， 理解力， 领悟； 理解力测验"] },
    { "name": "beggar", "trans": ["乞丐", "使贫穷"] },
    { "name": "leather", "trans": ["皮革， 皮革制品"] },
    { "name": "comb", "trans": ["梳子；鸡冠，冠状物", "梳理； 在…搜寻； 彻底搜查"] },
    { "name": "innovative", "trans": ["创新的， 革新的"] },
    { "name": "insight", "trans": ["洞察力； 洞悉； 深刻见解"] },
    { "name": "fabric", "trans": ["织物， 纺织品； 结构"] },
    { "name": "revolutionary", "trans": ["革命的，革新的", "革命者"] },
    { "name": "following", "trans": ["接着的，下列的", "一批追随者"] },
    { "name": "exact", "trans": ["确切的，精确的", "强求， 索取"] },
    { "name": "indoor", "trans": ["在室内， 在户内", "室内的"] },
    { "name": "force", "trans": ["强迫；用力推动，用力打开", "军队， 兵力； 暴力， 武力； 力， 力气； 影响力， 效力"] },
    { "name": "centigrade", "trans": ["百分度的； 摄氏的"] },
    { "name": "sexual", "trans": ["性的， 两性的； 性别的"] },
    { "name": "sympathetic", "trans": ["同情的； 和谐的； 赞同的， 支持的； 合意的"] },
    { "name": "freight", "trans": ["货运；货物；运费", "运送"] },
    { "name": "range", "trans": ["变动； 漫游， 四处搜索； 使排列成行", "一系列；范围；射程，距离；脉；射击场"] },
    { "name": "distribution", "trans": ["分发， 分配； 分布"] },
    { "name": "tyre", "trans": ["轮胎， 车胎"] },
    { "name": "derive", "trans": ["取得； 起源"] },
    { "name": "capacity", "trans": ["容量； 能力， 才能； 能量； 身份， 地位"] },
    { "name": "cope", "trans": ["应付， 处理"] },
    { "name": "overseas", "trans": ["在海外", "在海外的"] },
    { "name": "impress", "trans": ["给人印象，引人注目", "给…深刻印象；印，压印", "印记； 特征"] },
    { "name": "confess", "trans": ["供认， 坦白； 承认"] },
    { "name": "leak", "trans": ["漏；泄露", "漏洞"] },
    { "name": "ghost", "trans": ["鬼， 灵魂， 鬼魂"] },
    { "name": "feature", "trans": ["起重要作用", "特征，特色；面貌；特写，专题节目；故事片", "突出；由…主演"] },
    { "name": "lean", "trans": ["倾斜；屈身；靠", "瘦的； 贫瘠的"] },
    { "name": "flat", "trans": ["平直地； 直截了当地", "平的；固定的；漏气的；平淡的；沉闷的；浅的", "一套房间，单元住宅"] },
    { "name": "recall", "trans": ["回忆； 召回， 叫回； 收回， 撤销"] },
    { "name": "leap", "trans": ["跳，跃", "跳跃； 骤变"] },
    { "name": "waterproof", "trans": ["不透水的， 防水的"] },
    { "name": "barber", "trans": ["理发师"] },
    { "name": "precise", "trans": ["精确的， 准确的； 严谨的"] },
    { "name": "sensible", "trans": ["明智的； 合情理的； 有知觉的"] },
    { "name": "objection", "trans": ["反对， 异议； 反对的理由"] },
    { "name": "dispute", "trans": ["争论， 争执； 对…表示异议", "争论"] },
    { "name": "precious", "trans": ["珍贵的， 宝贵的"] },
    { "name": "observation", "trans": ["注意， 观察； 言论， 评论； 观察资料"] },
    { "name": "tremble", "trans": ["发抖，哆嗦；摇动", "颤抖； 摇晃， 摇动"] },
    { "name": "suspect", "trans": ["可疑的， 不信任的", "疑有；推测；对…表示怀疑", "嫌疑犯，可疑分子"] },
    { "name": "apologize", "trans": ["道歉， 谢罪， 认错"] },
    { "name": "inhabitant", "trans": ["居民， 住户"] },
    { "name": "fraction", "trans": ["小部分； 片断； 分数"] },
    { "name": "filter", "trans": ["过滤，透；走漏", "滤纸， 过滤嘴"] },
    { "name": "sunlight", "trans": ["日光， 阳光"] },
    { "name": "site", "trans": ["地点，场所", "使坐落在； 设置"] },
    { "name": "emotion", "trans": ["情感， 感情； 激动"] },
    { "name": "massive", "trans": ["大的， 大而重的； 大块的； 大量的， 大规模的"] },
    { "name": "cease", "trans": ["停止， 终止"] },
    { "name": "assess", "trans": ["对估价； 评价， 评论"] },
    { "name": "asset", "trans": ["资产， 财产； 有价值的特性或技能， 优点"] },
    { "name": "owe", "trans": ["欠； 应把…归功于； 感激， 感恩"] },
    { "name": "scarce", "trans": ["缺乏的， 不足的； 稀少的， 罕见的"] },
    { "name": "minimum", "trans": ["最低的， 最小的", "最低限度；最小量"] },
    { "name": "queue", "trans": ["排队等候", "长队，行列"] },
    { "name": "mathematical", "trans": ["数学的"] },
    { "name": "apology", "trans": ["道歉， 认错， 谢罪"] },
    { "name": "magic", "trans": ["有魔力的； 魔术的", "魔法，魅力"] },
    { "name": "argument", "trans": ["争论， 辩论； 理由； 说理， 论证"] },
    { "name": "reveal", "trans": ["揭露， 泄露； 展现， 显示"] },
    { "name": "data", "trans": ["资料，数据"] },
    { "name": "theoretical", "trans": ["理论的"] },
    { "name": "adequate", "trans": ["足够的； 可以胜任的"] },
    { "name": "utter", "trans": ["完全的，彻底的", "发出， 说， 讲"] },
    { "name": "onion", "trans": ["洋葱； 洋葱类植物"] },
    { "name": "vibrate", "trans": ["颤动"] },
    { "name": "drum", "trans": ["有节奏地敲击", "鼓，鼓状物；圆桶"] },
    { "name": "sausage", "trans": ["香肠， 腊肠"] },
    { "name": "hopeful", "trans": ["有希望的"] },
    { "name": "tremendous", "trans": ["极大的， 非常的； 精彩的， 了不起的"] },
    { "name": "condense", "trans": ["冷凝， 凝结； 浓缩， 压缩， 简缩"] },
    { "name": "barrier", "trans": ["栅栏； 检票口； 屏障； 障碍， 隔阂"] },
    { "name": "realistic", "trans": ["现实的； 实际可行的； 现实主义的； 逼真的"] },
    { "name": "justice", "trans": ["正义， 公正； 司法"] },
    { "name": "create", "trans": ["创造； 引起， 产生"] },
    { "name": "criminal", "trans": ["犯罪的， 刑事的", "犯人，罪犯，刑事犯"] },
    { "name": "tag", "trans": ["尾随", "附加语；标签", "给…加上标签"] },
    { "name": "notebook", "trans": ["笔记本"] },
    { "name": "curious", "trans": ["好奇的； 奇怪的"] },
    { "name": "indirect", "trans": ["间接的， 婉转的"] },
    { "name": "individual", "trans": ["个别的；独特的", "个人， 个体"] },
    { "name": "resource", "trans": ["资源； 财力； 应付办法， 谋略"] },
    { "name": "ugly", "trans": ["丑陋的； 可怕的"] },
    { "name": "nuisance", "trans": ["讨厌的东西"] },
    { "name": "tax", "trans": ["税，税款；负担", "对…征税； 使负担重， 使费尽力气"] },
    { "name": "earthquake", "trans": ["地震， 大震荡"] },
    { "name": "excitement", "trans": ["刺激， 激动， 兴奋； 令人兴奋的事， 刺激的因素"] },
    { "name": "nightmare", "trans": ["噩梦； 可怕的事物， 无法摆脱的恐惧"] },
    { "name": "cord", "trans": ["细绳， 粗线， 索； 灯芯绒裤"] },
    { "name": "ending", "trans": ["结尾， 结局； 死亡"] },
    { "name": "core", "trans": ["果实的心； 核心， 要点"] },
    { "name": "council", "trans": ["理事会， 委员会"] },
    { "name": "enforce", "trans": ["实施， 执行； 强制， 强迫， 迫使"] },
    { "name": "embassy", "trans": ["大使馆； 大使馆全体成员"] },
    { "name": "departure", "trans": ["离开， 起程， 出发； 背离， 违背"] },
    { "name": "dash", "trans": ["猛冲，击碎", "使猛撞，飞奔；溅", "猛冲； 破折号"] },
    { "name": "concrete", "trans": ["实在的， 具体的", "混凝土；具体物"] },
    { "name": "penalty", "trans": ["处罚， 惩罚； 罚金"] },
    { "name": "sociology", "trans": ["社会学"] },
    { "name": "link", "trans": ["连接，联系", "环节， 联系， 纽带"] },
    { "name": "flee", "trans": ["逃走， 逃掉， 逃离； 避开， 逃避"] },
    { "name": "distinct", "trans": ["与其他不同的； 清楚的， 明显的"] },
    { "name": "scale", "trans": ["大小，规模；等级，级别；天平，磅秤；比例；刻度，标度；鳞", "攀登， 爬越"] },
    { "name": "recovery", "trans": ["恢复， 痊愈； 追回， 寻回， 收复"] },
    { "name": "hedge", "trans": ["用篱笆围； 避免直接回答", "篱笆，树篱；障碍物"] },
    { "name": "tune", "trans": ["调子；和谐", "调整， 调节； 为调音"] },
    { "name": "weep", "trans": ["哭泣， 流泪； 渗出"] },
    { "name": "aware", "trans": ["知道的， 意识到的"] },
    { "name": "drama", "trans": ["戏剧， 剧本； 戏剧性事件"] },
    { "name": "security", "trans": ["安全， 保障； 抵押品； 证券"] },
    { "name": "limp", "trans": ["软的；不强壮的", "〔因一腿受伤而〕跛行，一瘸一拐地走", "跛行"] },
    { "name": "award", "trans": ["奖，奖品；判定", "授予， 给予； 判给， 裁定"] },
    { "name": "organize", "trans": ["组织， 把…编组； 使有条理"] },
    { "name": "marriage", "trans": ["结婚， 婚姻； 婚礼"] },
    { "name": "stove", "trans": ["炉， 火炉， 电炉"] },
    { "name": "alarm", "trans": ["惊恐；忧虑；警报", "使惊恐； 使担心"] },
    { "name": "weed", "trans": ["除草", "杂草，野草"] },
    { "name": "continual", "trans": ["连续的； 频频的"] },
    { "name": "herd", "trans": ["兽群，牧群", "放牧"] },
    { "name": "limb", "trans": ["肢， 臂， 腿； 树枝"] },
    { "name": "balloon", "trans": ["气球， 玩具气球"] },
    { "name": "motion", "trans": ["打手势， 示意", "运动；手势，眼色，动作；提议"] },
    { "name": "limited", "trans": ["有限的"] },
    { "name": "idle", "trans": ["空闲的，懒散的；无用的", "虚度， 无所事事"] },
    { "name": "confidence", "trans": ["信任， 信赖； 信心， 自信"] },
    { "name": "arbitrary", "trans": ["随心所欲的， 专断的"] },
    { "name": "fearful", "trans": ["害怕的， 可怕的； 不安的， 忧虑的"] },
    { "name": "accent", "trans": ["口音， 腔调； 重音"] },
    { "name": "passive", "trans": ["被动的， 消极的"] },
    { "name": "fertilizer", "trans": ["肥料"] },
    { "name": "lorry", "trans": ["运货汽车， 卡车"] },
    { "name": "carrier", "trans": ["运输工具， 运载工具； 带菌者； 载重架， 置物架"] },
    { "name": "fragment", "trans": ["成碎片", "碎片，破片，碎块"] },
    { "name": "corresponding", "trans": ["相应的， 符合的"] },
    { "name": "response", "trans": ["回答， 答复； 反应， 响应"] },
    { "name": "treaty", "trans": ["条约， 协议， 协定"] },
    { "name": "responsive", "trans": ["响应的； 敏感的， 易受影响的"] },
    { "name": "ounce", "trans": ["盎司"] },
    { "name": "challenge", "trans": ["挑战；邀请比赛；艰巨的任务；怀疑，质问", "反对， 公然反抗； 向…挑战； 对…质疑"] },
    { "name": "emit", "trans": ["散发； 发射； 发表"] },
    { "name": "category", "trans": ["种类， 类， 类别"] },
    { "name": "rival", "trans": ["竞争的，对抗的", "竞争对手，敌手；可与匹敌的人", "与 …竞争； 与…匹敌， 比得上"] },
    { "name": "intend", "trans": ["想要， 打算； 意思是； 打算使"] },
    { "name": "snap", "trans": ["咔嚓折断，啪地绷断；吧嗒一声；猛咬；厉声说话，怒声责骂", "仓促的， 突然的", "吧嗒声；快照"] }
  ]
  